webix.protoUI(
	{
		name: "streamsgrid",
		$init: function() {
			this._cols = 1;
			this.attachEvent("onAfterRender", () => this._updateView());
			this.$view.className += " webix_streamsgrid";
			this.$ready.push(this._after_init_call);
		},
		defaults: {
			maxItemsHorizontally: 12, // max visible items count in the horizonal orientation
			maxItemsVertically: 6, // max visible items count in the vertical orientation
			maxGridColumnsVertically: 2, // max count of grid cols in the vertical orientation
		},
		_after_init_call() {
			this._updatePager();
		},
		_updateView() {
			const skip = this._updatePager();
			if (skip) return;

			const count = this._getVisibleCount();

			const horizontal = this.$width >= this.$height;
			const round = horizontal ? Math.ceil : Math.round;
			let cols = round(Math.sqrt(count));

			if (!horizontal) {
				cols = Math.min(cols, this.config.maxGridColumnsVertically);
			}

			const last = count % cols;
			const miss = cols - last;

			if (miss) {
				this._centerLastItems(count, last, miss, horizontal);
			}
			this._updateGridColumns(cols, horizontal);
		},
		_getVisibleCount() {
			const n = this.data.count();
			const max = this.getPager().config.size;
			const page = this.getPage() + 1;
			const rest = n % max;
			return page * max > n ? rest : max;
		},
		_updatePager() {
			const all = this.data.count();
			const pager = this.getPager();

			const horizontal = this.$width >= this.$height;
			const max = horizontal
				? this.config.maxItemsHorizontally
				: this.config.maxItemsVertically;

			if (pager.config.size != max) {
				pager.define("size", max);
				this.refresh();
				return true;
			}

			if (all > max) {
				pager.show();
			} else {
				pager.hide();
			}

			return false;
		},
		_centerLastItems(count, last, miss, horizontal) {
			if (horizontal && count > this.config.maxItemsHorizontally) {
				return;
			}

			const nodes = this.$view.childNodes;
			if (nodes.length != count) return;

			const step = Math.floor((miss * 100) / 2);
			for (let i = last; i > 0; i--) {
				// center last items
				nodes[count - i].style.position = "relative";
				nodes[count - i].style.left = `${step}%`;
			}
		},
		_updateGridColumns(cols) {
			if (this._cols != cols) {
				this.$view.style.gridTemplateColumns = `repeat(${cols}, 1fr)`;
				this._cols = cols;
			}
		},
		type: {
			template(obj) {
				return obj.value;
			},
			templateStart: webix.template(
				"<div webix_l_id='#id#' class='webix_streamsgrid_item'>"
			),
			templateEnd: webix.template("</div>"),
		},
	},
	webix.ui.proto
);
