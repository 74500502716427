import { checkedIcon, notCheckedIcon } from "./icons";

webix.ui.datafilter.umMasterCheckox = webix.extend(
	{
		refresh: function(master, node, config) {
			node.onclick = function() {
				config.checked = !config.checked;
				let elem = node.querySelector(".webix_icon");
				elem.className =
					"webix_icon wxi-checkbox-" + (config.checked ? "marked" : "blank");
				master.data.each(obj => (obj[config.columnId] = config.checked));
				master.refresh();
				master.callEvent("onCustomSave", []);
			};
		},
		render: function(master, config) {
			return config.checked ? checkedIcon : notCheckedIcon;
		},
	},
	webix.ui.datafilter.masterCheckbox
);
