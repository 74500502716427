import { JetView } from "webix-jet";

import SideBar from "../sidebar";

export default class SideMenu extends JetView {
	config() {
		return {
			view: "sidemenu",
			css: "webix_chat_sidemenu",
			animate: false,
			width: 320,
			position: "left",
			state: state => {
				state.left = this.Parent.left;
				(state.top = this.Parent.top), (state.height = this.Parent.height);
			},
			body: SideBar,
		};
	}
	init() {
		this.on(this.app, "top:navigation", () => {
			this.getRoot().hide();
		});

		this.on(this.app, "view:resize", () => {
			if (this.getRoot().isVisible()) this.Show(true);
		});
	}
	/**
	 * Shows sidebar with chats in the compact mode
	 * @param {Object} box - DOMRect object
	 */
	Show(updatePos) {
		if (!updatePos && this.getRoot().isVisible()) return;

		this.Parent = this.app.getRoot().$view.getBoundingClientRect();
		this.getRoot().show();
	}
}
