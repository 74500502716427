import { JetApp, plugins, EmptyRouter } from "webix-jet";
import { initJetWin } from "@xbs/jet-helpers";
import { createState, link } from "jet-restate";

import views from "./export_views";
import en from "locales/en";

// services
import Local from "./models/Local";
import Backend from "./models/Backend";
import Operations from "./models/Operations";
import Upload from "./models/Upload";
import Helpers from "./models/Helpers";
import Emojis from "./models/Emojis";
import VoiceMessages from "./models/VoiceMessages";

export class App extends JetApp {
	constructor(config) {
		const state = createState({
			chatId: config.chatId || 0,
			chatType: null,
			userId: null,
			search: "",
			callStatus: 0,
			callId: 0,
			callUsers: [],
			callChatId: 0,
			callInitiator: 0,
			callIsGroup: false,
			callName: "",
			callAvatar: "",
		});
		const params = { state };
		if (config.compaсt) params.forceCompact = config.compact;
		if (config.reactions && typeof !config.emojis) config.emojis = true;
		if (typeof config.calls === "boolean")
			config.calls = { enabled: config.calls };
		else if (!config.calls) config.calls = { enabled: false };

		const defaults = {
			router: EmptyRouter,
			version: VERSION,
			debug: DEBUG,
			start: "/top",
			mode: "full",
			compactWidth: 650,
			params: { state, forceCompact: config.compact },
			grouping: true,
			files: false,
		};

		super({ ...defaults, ...config });

		// better error reporting
		if (this.config.debug) {
			webix.Promise = window.Promise;
		}

		const dynamic = obj =>
			this.config.override ? this.config.override.get(obj) || obj : obj;

		this.setService("local", new (dynamic(Local))(this));
		this.setService("backend", new (dynamic(Backend))(this));
		this.setService("operations", new (dynamic(Operations))(this));
		this.setService("upload", new (dynamic(Upload))(this));
		this.setService("helpers", new (dynamic(Helpers))(this));
		this.setService("emojis", new (dynamic(Emojis))(this));
		this.setService("voiceMessages", new (dynamic(VoiceMessages))(this));

		initJetWin(this);
		this.use(
			plugins.Locale,
			this.config.locale || {
				lang: "en",
				webix: {
					en: "en-US",
				},
			}
		);
	}

	render(node) {
		const back = this.getService("backend");
		back
			.ready()
			.then(() => back.callInfo())
			.then(info => {
				if (info && info.id) {
					const state = this.config.params.state;
					state.$batch({
						callId: info.id,
						callUsers: info.users,
						callStatus: info.status,
						callInitiator: info.initiator || false,
						callIsGroup: info.group || false,
						callChatId: info.chat || 0,
						callName: info.name || "",
						callAvatar: info.avatar || "",
						callStart: info.start || false,
					});
				}
				super.render(node);
			});
	}

	require(type, name) {
		if (type === "jet-views") return views[name];
		else if (type === "jet-locales") return locales[name];

		return null;
	}

	// for external users
	getState() {
		return this.config.params.state;
	}
}

webix.protoUI(
	{
		name: "chat",
		app: App,
		getState() {
			return this.$app.getState();
		},
		getService(name) {
			return this.$app.getService(name);
		},
		$init() {
			this.$view.className += " webix_chat";

			const state = this.$app.getState();
			for (let key in state) {
				link(state, this.config, key);
			}
		},
	},
	webix.ui.jetapp
);

// re-export for customization
const services = { Local, Backend, Operations, Upload, Helpers, Emojis };
const locales = { en };
export { views, locales, services };
