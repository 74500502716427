// https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Signaling_and_video_calling

function reportError(e) {
	console.error(e);
}

function defer() {
	let resolve;
	const promise = new Promise(res => {
		resolve = res;
	});
	promise.resolve = resolve;
	return promise;
}

export default function rtc(
	sendSignal,
	rtcConfig,
	handler,
	locale,
	node,
	role
) {
	rtcConfig = rtcConfig || {
		iceServers: [
			{
				urls: "stun:turn.webix.com:5349",
			},
			{
				urls: "turn:turn.webix.com:5349",
				username: "demo",
				credential: "redro43a",
			},
		],
	};

	let conn = new RTCPeerConnection(rtcConfig);
	const ready = defer();

	const trigger = obj => {
		if (obj) handler(obj);
		else handler({ action: "streams", conn });
	};

	// The local ICE layer calls your icecandidate event handler
	// when it needs you to transmit an ICE candidate to the other peer
	conn.onicecandidate = ev => {
		// accept candidate
		if (ev.candidate) {
			sendSignal("new-ice-candidate", ev.candidate);
		}
	};

	// handler is called by the local WebRTC layer
	// when a track is added to the connection
	conn.ontrack = () => {
		trigger();
	};

	// is called whenever the WebRTC infrastructure needs
	// you to start the session negotiation process anew.
	conn.onnegotiationneeded = () => {
		if (role == 4 && conn.iceConnectionState === "new") {
			// prevent an offer from being sent
			// from the user who accepts the call on the first initialization
			return;
		}
		conn
			.createOffer()
			.then(offer => conn.setLocalDescription(offer))
			.then(() => sendSignal("offer", conn.localDescription))
			.catch(reportError);
	};

	conn.oniceconnectionstatechange = () => {
		switch (conn.iceConnectionState) {
			case "closed":
			case "failed":
				end(true);
				break;
		}
	};

	conn.onicegatheringstatechange = () => {
		switch (conn.signalingState) {
			case "closed":
				end(true);
				break;
		}
	};

	const handleGetUserMediaError = (e, type) => {
		//get user media after call end
		if (!conn) return;

		switch (e.name) {
			case "NotFoundError":
				webix.alert({
					container: node,
					text:
						locale("Could not find your") +
						" " +
						locale(type == "audio" ? "microphone" : "camera"),
				});
				break;
			case "SecurityError":
			case "PermissionDeniedError":
				end(true);
				break;
			default:
				webix.alert({
					container: node,
					text:
						locale("Error opening your") +
						" " +
						locale(type == "audio" ? "microphone" : "camera"),
				});
				break;
		}
	};

	const start = (mode, emode, reset) => {
		if (reset) sendSignal("reset", "");
	};

	const restart = () => {
		conn.restartIce();
	};

	const onOffer = msg => {
		var desc = new RTCSessionDescription(msg);
		if (conn.getSenders().length === 0) {
			conn
				.setRemoteDescription(desc)
				.then(() => conn.createAnswer())
				.then(answer => conn.setLocalDescription(answer))
				.then(() => {
					ready.resolve();
					sendSignal("answer", conn.localDescription);
				})
				.catch(reportError);
		} else {
			conn
				.setRemoteDescription(desc)
				.then(() => conn.createAnswer())
				.then(answer => conn.setLocalDescription(answer))
				.then(() => {
					sendSignal("answer", conn.localDescription);
				})
				.catch(reportError);
		}
	};

	const onAnswer = msg => {
		var desc = new RTCSessionDescription(msg);
		return conn
			.setRemoteDescription(desc)
			.then(() => {
				ready.resolve();
			})
			.catch(reportError);
	};

	const onCandidate = msg => {
		ready
			.then(() => {
				var candidate = new RTCIceCandidate(msg);
				return conn.addIceCandidate(candidate);
			})
			.catch(reportError);
	};

	const end = final => {
		if (conn) {
			conn.ontrack = null;
			conn.onremovetrack = null;
			conn.onremovestream = null;
			conn.onicecandidate = null;
			conn.oniceconnectionstatechange = null;
			conn.onsignalingstatechange = null;
			conn.onicegatheringstatechange = null;
			conn.onnegotiationneeded = null;
			conn.close();
			conn = null;
		}

		trigger({ action: "end", final });
	};

	const status = () => {
		return conn && conn.remoteDescription ? 1 : 0;
	};

	const enable = (kind, mode) => {
		const promise = webix.promise.defer();

		let tracks = conn
			.getSenders()
			.map(a => a.track || {})
			.reduce((p, c) => p.concat(c), []);

		tracks = tracks.filter(a => a.kind === kind);

		if (mode && !tracks.length) {
			trigger({ action: "upgrade", kind, promise });
		} else {
			tracks.forEach(a => (a.enabled = mode));
			promise.resolve();
		}

		return promise;
	};

	const mediaValues = {
		audio: {
			echoCancellation: true,
			noiseSuppression: true,
		},
		video: {
			facingMode: "user",
		},
	};

	const upgrade = (type, promise) => {
		return navigator.mediaDevices
			.getUserMedia({ [type]: mediaValues[type] })
			.then(local => {
				local.getTracks().forEach(track => conn.addTrack(track, local));
				trigger();
				promise.resolve();
			})
			.catch(e => {
				promise.reject();
				handleGetUserMediaError(e, type);
			});
	};

	return {
		start,
		end,
		restart,
		status,
		enable,
		onOffer,
		onAnswer,
		upgrade,
		onCandidate,
	};
}
