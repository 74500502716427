import { JetView } from "webix-jet";

export default class ListView extends JetView {
	config() {
		return {
			view: "list",
			localId: "list",
			select: true,
			borderless: true,
			css: "webix_chat_list",
			type: {
				height: 60,
				template: (data, common) => {
					return this.ListTemplate(data, common);
				},
				text: a => a.replace(/<[^>]*>/g, ""),
			},
		};
	}

	/**
	 * Inits a list
	 * @param {Object} data - collection of chats/users
	 * @param {Function} filter - filtering function
	 */
	InitSelf(data, filter) {
		const list = this.$$("list");
		list.data.attachEvent("onSyncApply", () => {
			this.ApplySearchValue();
			this.SyncHandler();
		});
		list.sync(data, filter || null);
		list.attachEvent("onAfterSelect", id => {
			this.ShowChat(id);
		});

		this.on(this.app.getState().$changes, "search", v => {
			this.Find(v);
		});
	}

	SyncHandler() {}

	ShowChat() {}

	/**
	 * Retrieves the name of a specified chat
	 * @param {Object} item - chat object
	 * @returns {string} chat name
	 */
	ListTemplate(item) {
		return item.name;
	}

	/**
	 * Escapes characters in text from search box
	 * @param {string} text - text from the search box
	 * @returns {string} a string with the escaped chars
	 */
	EscapeRegExp(text) {
		return text.replace(/[[\]{}()*+?.\\^$|]/g, "\\$&");
	}

	/**
	 * Applies value from the search box
	 */
	ApplySearchValue() {
		const searchValue = this.app.getState().search;
		if (searchValue) this.Find(searchValue);
	}

	/**
	 * Filters table by input text and highlights occurrences
	 * @param {string} text - value from the search box
	 */
	Find(text) {
		let list = this.$$("list");
		this.EscapedSearchText = this.EscapeRegExp(text || "");

		if (text) {
			text = text.toLowerCase();
			list.filter(data => this.SearchCompare(text, data));
		} else list.filter();
	}

	/**
	 * Compares chat names with the value from the search box
	 * @param {string} value - value from the search box
	 * @param {Object} item - chat object
	 * @returns {boolean} true for each item that matches the value
	 */
	SearchCompare(value, item) {
		return item.name.toLowerCase().indexOf(value) > -1;
	}

	/**
	 * Selects a specified chat
	 * @param {number} id - chat ID
	 */
	Select(id) {
		const list = this.$$("list");
		if (id != list.getSelectedId() && list.getItem(id)) list.select(id);
	}
}
