export default class Operations {
	constructor(_app) {
		this._app = _app;
		this._local = this._app.getService("local");
		this._back = this._app.getService("backend");
	}

	/**
	 * Creates a direct chat with a specified user
	 * @param {number} userId - user ID
	 * @returns {number} chat ID
	 */
	addChat(userId) {
		return this._back.addChat(userId).then(chat => {
			this._local.chats().add(chat, 0);
			return chat.id;
		});
	}

	/**
	 * Adds new message to a specified chat
	 * @param {number} cid - chat ID
	 * @param {number} origin - message ID
	 * @param {string} text - message text
	 * @returns {Object} an object of a newly created message
	 */
	addMessage(cid, origin, text) {
		const chats = this._local.chats();
		chats.updateItem(cid, {
			message: this._getMessageText(text),
			message_type: 0,
			date: new Date(),
		});
		if (chats.getIndexById(cid)) chats.moveTop(cid);
		return this._back.addMessage(cid, text, origin);
	}

	/**
	 * Removes a specified message
	 * @param {number} id - message ID
	 * @returns an error or null (if removal was successful)
	 */
	removeMessage(id) {
		return this._back.removeMessage(id);
	}

	/**
	 * Updates a specified message
	 * @param {number} id - message ID
	 * @param {string} text - new message text
	 * @returns {Object} an object with the updated message data
	 */
	updateMessage(id, text) {
		return this._back.updateMessage(id, text);
	}
	/**
	 * Clips the message text to 80 characters
	 * @param {string} text - origin message text
	 * @returns {string} origin text if it does not exceed 80 chars, otherwise - part of the text (first 80 characters)
	 */
	_getMessageText(text) {
		let limit = 80;
		if (this._app.config.emojis) {
			const arr = text.match(this._app.getService("emojis").emojiRegExp);
			if (arr)
				arr.forEach(str => {
					limit += str.length;
				});
		}
		return text.substr(0, Math.min(text.length, limit));
	}
	/**
	 * Resets counter of the unread messages in a specified chat
	 * @param {number} cid - chat ID
	 */
	resetCounter(cid) {
		this._back.resetCounter(cid);

		const chats = this._local.chats();
		if (chats.exists(cid)) {
			chats.updateItem(cid, { unread_count: 0 });
		}
	}

	/**
	 * Creates a new group chat
	 * @param {string} name - chat name
	 * @param {string} avatar - URL to the chat avatar
	 * @param {Array} users - an array with IDs of users involved
	 * @returns {number} chat ID
	 */
	addGroupChat(name, avatar, users) {
		return this._back.addGroupChat(name, avatar, users).then(chat => {
			this._local.chats().add(chat, 0);
			return chat.id;
		});
	}

	/**
	 * Updates a specified chat
	 * @param {number} chatId - chat ID
	 * @param {string} name - chat name
	 * @param {string} avatar - URL to the chat avatar
	 * @returns {Object} an object with the updated chat data
	 */
	updateChat(chatId, name, avatar) {
		return this._back.updateChat(chatId, name, avatar).then(info => {
			this._local.chats().updateItem(chatId, info);
		});
	}

	/**
	 * Removes current user from a specified chat
	 * @param {number} chatId - chat ID
	 * @returns an error or null (if removal was successful)
	 */
	leaveChat(chatId) {
		return this._back.leave(chatId, this._app.config.user).then(() => {
			this._app.callEvent("leaveChat", [chatId]);
			this._local.chats().remove(chatId);
		});
	}

	/**
	 * Add users to a specified chat
	 * @param {number} chatId - chat ID
	 * @param {Array} users - an array containing ID of the users participating
	 * @returns an error or null (if adding was successful)
	 */
	setUsers(chatId, users) {
		return this._back.setUsers(chatId, users).then(info => {
			const chat = this._local.chats();
			if (info.id == chatId) chat.updateItem(chatId, info);
			else {
				chat.add(info, 0);
				this._app.callEvent("showChat", [chat, info.id]);
			}
		});
	}

	addReaction(msgId, reaction) {
		return this._back.addReaction(msgId, reaction);
	}

	removeReaction(msgId, reaction) {
		return this._back.removeReaction(msgId, reaction);
	}
}
