import { JetView } from "webix-jet";

export default class ChatInfo extends JetView {
	config() {
		const _ = this.app.getService("locale")._;
		const state = this.getParam("state", true);
		const helpers = this.app.getService("helpers");

		this.ShowAll = false;
		this.MemberListMaxCount = 3;
		this.CanAddUsers = state.group;

		const chatAvatar = {
			localId: "avatar",
			css: "webix_chat_form_avatar",
			width: 130,
			height: 130,
			borderless: true,
			template: data => {
				return helpers.formAvatar(data, _("Avatar"));
			},
			data: { avatar: state.avatar },
		};

		const membersList = {
			view: "list",
			localId: "members",
			css: "webix_chat_list",
			borderless: true,
			autoheight: true,
			type: {
				height: 60,
				template: user => this.ListTemplate(user),
				text: a => a.replace(/<[^>]*>/g, ""),
			},
			onClick: {
				webix_chat_list_item_delete: (e, id) =>
					this.DeleteMemberClickHandler(id),
			},
		};

		const addMembersButton = {
			view: "button",
			type: "icon",
			width: 150,
			height: Math.max(webix.skin.$active.inputHeight, 38),
			icon: "wxi-plus",
			label: _("Add members"),
			click: () => (state.cursor = 3),
		};

		const leaveChatButton = {
			view: "button",
			css: "webix_chat_delete_button",
			label: _("Leave chat"),
			width: 130,

			click: () => this.LeaveChat(),
		};

		const form = [
			{
				type: "form",
				cols: [{}, chatAvatar, {}],
			},
			{
				type: "form",

				rows: [
					{
						cols: [
							{
								view: "label",
								label: _("Members") + " (" + state.users.length + ")",
							},
							addMembersButton,
						],
					},
					membersList,
					{
						localId: "showAllButton",
						hidden: true,
						autoheight: true,
						borderless: true,
						view: "template",
						template: `<span class="webix_chat_show_all_btn">${_(
							"Show all members"
						)}</span>`,
						css: "webix_chat_show_all_tmpl",
						onClick: {
							webix_chat_show_all_btn: () => this.ShowAllMembers(state.users),
						},
						inputWidth: 150,
					},
				],
			},
			{},
		];

		if (state.group)
			form.push({
				type: "form",
				padding: { top: 0 },
				cols: [leaveChatButton, {}],
			});

		return {
			view: "form",
			type: "clean",
			scroll: true,
			borderless: true,
			elements: form,
		};
	}
	init() {
		const state = this.getParam("state", true);
		if (this.$$("members")) this.InitMembersList(state.users);
		this.on(state.$changes, "users", v => this.Filter(v));
	}

	/**
	 * Initiates chat members list
	 * @param {Array<number>} users - an array with IDs of chat members
	 */
	InitMembersList(users) {
		const local = this.app.getService("local");
		const members = this.$$("members");
		members.data.attachEvent("onSyncApply", () => {
			members.data.sort((a, b) => this.Sort(a, b), "asc");
			this.Filter(users);
		});
		members.sync(local.users());
	}

	/**
	 * Builds up an HTML template for chat members
	 * @param {Object} user - user data
	 * @returns {string} a string with an HTML markup for chat members
	 */
	ListTemplate(user) {
		const _ = this.app.getService("locale")._;
		let youText =
			user.id == this.app.config.user
				? " (<span class='webix_chat_list_you_text'>" + _("You") + "</span>)"
				: "";
		return (
			this.app.getService("helpers").listAvatar(user) +
			user.name +
			youText +
			this.DeleteButtonTemplate(user)
		);
	}

	/**
	 * Builds up an HTML template for the "Delete" button
	 * @param {Object} user - user data
	 * @returns {string} a string with an HTML markup for the "Delete" button in members list
	 */
	DeleteButtonTemplate(user) {
		let html = "";
		if (user.id != this.app.config.user && this.CanAddUsers) {
			html =
				"<button type='button' class='webix_icon_button webix_chat_list_item_delete'>" +
				"<span class='webix_icon wxi-close'></span>" +
				"</button>";
		}
		return html;
	}

	/**
	 * Sorts the list of chat members by their names
	 * @param {Object} a - user data
	 * @param {Object} b - user data
	 * @returns {number} - 1 to place "a" user first, -1 to place "b" user first
	 */
	Sort(a, b) {
		if (b.id == this.app.config.user) return 1;
		if (a.id == this.app.config.user) return -1;
		return a.name > b.name ? 1 : -1;
	}

	/**
	 * Shows all the members users
	 * @param {Array<number>} users - IDs of the chat members
	 */
	ShowAllMembers(users) {
		this.ShowAll = true;
		this.Filter(users);
		this.$$("showAllButton").hide();
	}

	/**
	 * Filters the list of chat members (to show only a part of thereof initially)
	 * @param {Array<number>} users - IDs of the chat members
	 */
	Filter(users) {
		this.MemberListCount = 0;
		this.$$("members").data.filter(user => {
			const isDirect = user.direct_id == user.id;
			const groupMember = users && users.indexOf(user.id) > -1;
			let result = isDirect || groupMember;
			if (result && !this.ShowAll) {
				this.MemberListCount++;
			}
			return result && this.MemberListCount <= this.MemberListMaxCount;
		});
		if (this.MemberListCount > this.MemberListMaxCount)
			this.$$("showAllButton").show();
	}

	/**
	 * Removes a specified user from the current chat
	 * @param {number} id - ID of the user to delete
	 */
	DeleteMemberClickHandler(id) {
		const _ = this.app.getService("locale")._;
		const ops = this.app.getService("operations");

		webix
			.confirm({
				container: this.app.getRoot().$view,
				title: _("Delete member"),
				ok: _("Delete"),
				cancel: _("Cancel"),
				text: _("Are you sure to delete this member?"),
			})
			.then(() => {
				const state = this.getParam("state", true);
				state.users = state.users.filter(a => a != id);
				ops.setUsers(state.chat, state.users);
			});
	}

	/**
	 * Removes the current user from the current chat
	 */
	LeaveChat() {
		const _ = this.app.getService("locale")._;
		webix
			.confirm({
				container: this.app.getRoot().$view,
				title: _("Leave chat"),
				ok: _("Leave"),
				cancel: _("Cancel"),
				text: _("Are you sure to leave this chat?"),
			})
			.then(() => {
				const ops = this.app.getService("operations");
				const state = this.getParam("state", true);
				ops.leaveChat(state.chat);
				// force wizard closing
				state.cursor = 0;
			});
	}
}
