import "../../helpers/streamsgrid";

import BaseCallPanel from "./base";
import phone from "../../libs/phone";
import LivekitClient from "../../libs/livekit";

export default class PersonalCallPanel extends BaseCallPanel {
	/**
	 * Returns a call config
	 * @param {Object} users - users collection
	 * @returns {Object} configuration for the personal call
	 */
	GetCallConfig(users) {
		return [
			{
				animate: false,
				borderless: true,
				cells: [this.GetAudioConfig(users), this.GetVideoConfig()],
			},
			{
				borderless: true,
				css: "webix_chat_video webix_chat_audio",
				minHeight: 96,
				gravity: 0.0001,
				template: `
					<div style="position: relative; width: 100%; height: 100%;">
						<div class="webix_chat_video_remote">
							<audio autoplay></audio>
							<video autoplay playsinline></video>
						</div>
						<div class="webix_chat_video_local">
							<video autoplay playsinline></video>
						</div>
						${this.Helpers.avatar(
							users.getItem(this.app.config.user),
							"webix_chat_video_local_avatar"
						)}
						<span 
							id="micro-indicator" 
							class="webix_chat_group_call_micro chi-audio-off" 
							style="position: absolute; right: 12px; bottom: 12px; font-size: 20px; display: none"
							>
						</span>
					</div>
				`,
				localId: "connection",
			},
		];
	}

	/**
	 * Initializes call config
	 */
	InitCallConfig() {
		this.Started = false;
		const video = this.$$("connection").$view;
		this.localVideo = video.querySelector(".webix_chat_video_local");
		this.localAvatar = video.querySelector(".webix_chat_video_local_avatar");
		this.remoteVideo = video.querySelector(".webix_chat_video_remote");
		this.remoteAudio = video.querySelector("#micro-indicator");

		this.on(this.LocalState.$changes, "remoteVideo", () => this.ShowVideo());
		this.on(this.LocalState.$changes, "remoteAudio", v =>
			this.UpdateMicrophoneIcon(v)
		);

		this.on(this.LocalState.$changes, "connecting", v => {
			v
				? this.$$("connecting-label").show()
				: this.$$("connecting-label").hide();
			this.ShowMicrophoneIcon(!v);
		});

		this.on(this.Back.pubsub(), "signal", ({ msg, type }) => {
			// do nothing, until user confirms that they want to join the call
			if (this.AfterRefresh) return;

			if (msg) msg = JSON.parse(msg);
			if (this.Phone && this.Phone.events) this.Phone.events(type, msg);
		});
	}

	/**
	 * Notifies call participant that the user has been disconnected
	 */
	OnWindowUnloadHandler() {
		if (!this.liveKitEnabled) this.SendSignal("break-connection", {});
	}

	/**
	 *
	 * @param {boolean} isInitiator - shows whether the current user is the call initiator
	 * @param {boolean} isAfterRefresh - shows whether a call is resumed after a page refresh
	 */
	InitPhone(isInitiator, isAfterRefresh) {
		const alist = this.$$("connection").$view.querySelectorAll("audio");
		const vlist = this.$$("connection").$view.querySelectorAll("video");
		const nodes = {
			local: {
				audio: {
					/*contains local audio stream, to stop it at the end of the call*/
				},
				video: vlist[1],
			},
			remote: { audio: alist[0], video: vlist[0] },
			active: {
				remote: {
					video: v => (this.LocalState.remoteVideo = v),
					audio: v => (this.LocalState.remoteAudio = v),
					enable: (common, userId, kind, value) => common[kind](!!value),
					connecting: v => (this.LocalState.connecting = v),
				},
			},
			end: () => this.EndCall(),
		};
		const ready = this.liveKitEnabled
			? this.InitLivekit(nodes)
			: this.InitP2P(nodes, isInitiator, isAfterRefresh);

		return ready.then(() => {
			this.Started = true;
			this.ShowMicrophoneIcon(true);
		});
	}

	/**
	 * Initializes service for the personal call connection (peer-to-peer)
	 * @param {object} nodes - defines media configuration
	 * @param {boolean} isInitiator - shows whether the current user is the call initiator
	 * @param {boolean} isAfterRefresh - shows whether a call is resumed after a page refresh
	 */
	InitP2P(nodes, isInitiator, isAfterRefresh) {
		this.Phone = phone(
			this.SendSignal.bind(this),
			this.app.config.rtcConfig || null,
			nodes,
			isInitiator ? (isAfterRefresh ? 3 : 1) : isAfterRefresh ? 2 : 4,
			{ video: this.LocalState.video, audio: this.LocalState.audio },
			this.app.getService("locale")._,
			this.app.getRoot().$view
		);

		return this.webix.promise.resolve();
	}

	/**
	 * Initializes livekit service for personal call connection
	 * @param {object} nodes - defines media configuration
	 */
	InitLivekit(helpers) {
		helpers.container = this.app.getRoot().$view;
		helpers.locale = this.app.getService("locale")._;
		helpers.end = () => this.EndCall();

		return this.Back.joinToken(this.State.callId).then(joinToken => {
			this.Phone = new LivekitClient(this.app.config.user, false, helpers);
			return this.Phone.ready.then(() => {
				this.Phone.refreshUserMedia(this.app.config.user, helpers.local, true);

				const onUserAdded = ([id]) => {
					this.Phone.refreshUserMedia(id, helpers.remote);
					this.LocalState.connecting = false;
				};
				const onUserRemoved = () => {
					this.LocalState.remoteVideo = false;
					this.LocalState.connecting = true;
				};

				const onUserMediaEnable = ([id, , kind, value, local]) => {
					if (!id || local) return;
					if (kind == "video") this.LocalState.remoteVideo = value;
					if (kind == "audio") this.LocalState.remoteAudio = value;
				};

				this.Phone.on("onUserAdded", onUserAdded);
				this.Phone.on("onUserRemoved", onUserRemoved);
				this.Phone.on("onUserMediaEnable", onUserMediaEnable);

				return this.Phone.connect(
					this.app.config.calls.livekitConfig,
					joinToken
				);
			});
		});
	}

	/**
	 * Returns an audio config
	 * @param {Object} users - users collection
	 * @returns {Object} configuration for the audio call
	 */
	GetAudioConfig(users) {
		const _ = this.app.getService("locale")._;
		return {
			localId: "audio",
			type: "clean",
			rows: [
				{
					css: "webix_chat_audio_time",
					localId: "audio-timer",
					batch: "active",
					height: 56,
					template: () => {
						return `${_("Active call")} (${this.Helpers.normalizeTime(
							this.time
						)})`;
					},
				},
				{
					gravity: 0.0001,
					css: "webix_chat_audio",
				},
				{
					height: 270,
					css: "webix_chat_audio",
					template: () => {
						const user = users.getItem(this.callWith);
						const avatar = this.Helpers.avatar(
							user,
							`webix_chat_call_avatar webix_chat_avatar${
								user.avatar ? " webix_chat_avatar_img" : ""
							}`
						);
						return (
							avatar + "<p class='webix_chat_call_name'>" + user.name + "</p>"
						);
					},
				},
				...this.GetStatesConfig(),
				{
					gravity: 0.0001,
					css: "webix_chat_audio",
				},
			],
		};
	}

	/**
	 * Returns a video config
	 * @param {Object} users - users collection
	 * @returns {Object} configuration for the video call
	 */
	GetVideoConfig() {
		return {
			localId: "video",
			type: "clean",
			rows: [
				{
					css: "webix_chat_video_time",
					height: 56,
					type: "clean",
					cols: [
						{
							localId: "video-timer",
							template: () => {
								return this.Helpers.normalizeTime(this.time);
							},
						},
						{
							view: "icon",
							localId: "fullscreen-icon",
							icon: "chi-fullscreen",
							click: () => {
								this.SetFullscreen(!this.fullscreen);
							},
						},
						{ width: 6 },
					],
				},
			],
		};
	}

	/**
	 * Sends a signal to back
	 * @param {string} type - signal type ("reset", "offer", "answer", etc.)
	 * @param {Object} payload - RTCIceCandidate object
	 * @returns {Object} an object containing the signal data
	 */
	SendSignal(type, payload) {
		// rtc handler can trigger callback when view already destroyed
		if (this.app) {
			if (typeof payload !== "string") payload = JSON.stringify(payload);
			return this.Back.signalCall(type, payload);
		}
	}

	/**
	 * Toggles video block
	 */
	CheckVideoVisibility() {
		const remote = this.LocalState.remoteVideo;
		const local = this.LocalState.video;

		let visibility = ["none", "none", "none"];
		if (remote) {
			if (local) visibility = ["none", "block", "block"];
			else visibility = ["block", "none", "block"];
		} else if (local) visibility = ["none", "block", "none"];

		[this.localAvatar, this.localVideo, this.remoteVideo].forEach((node, i) => {
			node.style.display = visibility[i];
		});
	}

	/**
	 * Shows video if the functionality is available
	 */
	ShowVideo() {
		if (
			this.State.callStatus == this.callStatuses["active"] &&
			!this.AfterRefresh
		) {
			const remote = this.LocalState.remoteVideo;
			const audio = !this.LocalState.video && !remote;
			if (audio || !remote) {
				this.SetFullscreen(false);
				this.ControlVisibility("audio", "active", true);
			} else if (!this.fullscreen) this.ControlVisibility("video", "", true);

			this.CheckVideoVisibility();
		}
	}

	/**
	 * Toggles microphone icon
	 * @param {boolean} value - toggle value
	 */
	UpdateMicrophoneIcon(value) {
		if (!this.remoteAudio) return;
		this.remoteAudio.className = "webix_chat_group_call_micro ";
		this.remoteAudio.className += value ? "chi-audio" : "chi-audio-off";
	}

	/**
	 * Shows microphone icon if the visibility value is true, hides otherwise
	 * @param {boolean} value - visibility value
	 */
	ShowMicrophoneIcon(value) {
		if (!this.remoteAudio || !this.Started) return;
		this.remoteAudio.style.display = value ? "inline-block" : "none";
	}
}
