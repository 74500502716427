export default class VoiceMessages {
	constructor(app) {
		this.Helpers = app.getService("helpers");
	}
	PlayVoice(url, duration, node, from, pos, time) {
		if (!this.voices) this.voices = {};

		let voice = this.voices[from];

		if (this.lastVoice && this.lastVoice != from) this.ExitVoice();

		if (!voice) {
			voice = this.voices[from] = {
				audio: typeof url == "string" ? new Audio(url) : url,
				duration,
			};

			this.SetVoiceNodes(node, voice);

			voice.audio.addEventListener("playing", () => {
				voice.progress.style.transition =
					"width " + (duration - voice.audio.currentTime) * 1000 + "ms";
				voice.progress.style.width = "100%";
			});

			voice.audio.addEventListener("pause", () => {
				if (voice.audio.currentTime != voice.audio.duration)
					voice.progress.style.width = window.getComputedStyle(
						voice.progress
					).width;
				voice.icon.classList.replace("chi-pause", "chi-play");
			});

			voice.audio.addEventListener("timeupdate", () => {
				let time = this.Helpers.normalizeTime(
					Math.ceil(voice.audio.currentTime)
				);
				voice.time.innerText = time;
			});
		}

		if (
			!time &&
			(voice.audio.currentTime === 0 || voice.audio.currentTime == duration)
		)
			time = 0;

		if (!webix.isUndefined(time)) {
			voice.progress.style.transition = "none";
			voice.audio.currentTime = time;
			voice.progress.style.width = (pos || 0) + "px";
		}

		this.lastVoice = from;
		voice.icon.classList.replace("chi-play", "chi-pause");
		voice.audio.play();
	}
	SetVoiceNodes(node, voice) {
		webix.extend(
			voice,
			{
				icon: node.querySelector(".webix_chat_voice_play"),
				progress: node.querySelector(".webix_chat_voice_progress"),
				time: node.querySelector(".webix_chat_voice_time"),
			},
			true
		);
	}
	ExitVoice() {
		if (this.voices && this.lastVoice) {
			const prevVoice = this.voices[this.lastVoice];
			if (prevVoice) {
				prevVoice.progress.style.width = "100%";
				prevVoice.progress.style.transition = "none";
				prevVoice.audio.currentTime = prevVoice.duration;
				prevVoice.audio.pause();
			}
		}
	}
	SetVoiceTime(url, duration, node, from, ev) {
		const rect = node
			.querySelector(".webix_chat_progress")
			.getBoundingClientRect();
		const pos = ev.x - rect.x;
		const percent = pos / rect.width;
		this.PlayVoice(url, duration, node, from, pos, duration * percent);
	}
	PauseVoice(id) {
		this.voices[id].audio.pause();
	}
	RefreshVoiceItem(list, id) {
		if (this.voices && this.voices[id] && this.lastVoice == id) {
			this.ExitVoice();
			webix.delay(() =>
				this.SetVoiceNodes(list.getItemNode(id), this.voices[id])
			);
		}
	}
	CancelNewVoice() {
		if (this.voices && this.voices["$newVoice"]) {
			this.voices["$newVoice"].audio.pause();
			delete this.voices["$newVoice"];
		}
	}
	ClearVoice(id) {
		this.ExitVoice();
		delete this.lastVoice;
		if (id === true) delete this.voices;
		else delete this.voices[id];
	}
}
