let Room, RoomEvent, ParticipantEvent, DisconnectReason;

class CallUser {
	constructor(id, participant, audio, isLocal) {
		this.id = id;
		this.participant = participant;
		this.audio = audio || null;
		this.media = { audio: this.audio };
		this.isLocal = isLocal;
	}

	refreshParticipant(participant) {
		if (participant) {
			this.detach();
			this.participant = participant;
			this.refreshMedia(this.media);
		}
	}

	refreshMedia(src) {
		if (src) {
			this.media.audio = this.audio || src.audio;
			this.media.video = src.video;
			this.media.avatar = src.avatar;
			this.media.micro = src.micro;
			this.media.container = src.container;
		}

		this.attach();
	}

	getTrack(kind) {
		if (!checkKind(kind) || !this.participant) return;
		const map = this.participant[kind + "Tracks"];
		if (!map.size) return null;

		const publication = map.entries().next().value;
		if (publication && publication.length > 0) {
			return publication[1].track;
		}
		return null;
	}

	isAudioEnabled() {
		const track = this.getTrack("audio");
		return track && track.isMuted === false;
	}

	isVideoEnabled() {
		const track = this.getTrack("video");
		return track && track.isMuted === false;
	}

	isMediaEnabled(kind) {
		if (!checkKind(kind)) {
			return null;
		}
		return kind == "audio" ? this.isAudioEnabled() : this.isVideoEnabled();
	}

	attach(kind) {
		let audio = !kind || kind == "audio";
		let video = !kind || kind == "video";

		if (audio && !this.isLocal) {
			const track = this.getTrack("audio");
			const el = this.media["audio"];

			if (track && el) {
				if (!el.srcObject) {
					el.srcObject = new MediaStream();
				}

				const mediaStreamTrack = track.mediaStream.getAudioTracks()[0];
				if (
					mediaStreamTrack &&
					!el.srcObject.getTracks().some(t => t.id == mediaStreamTrack.id)
				) {
					const originalStream = el.srcObject;

					mediaStreamTrack.userId = this.id;
					const newStream = new MediaStream([
						...originalStream.getTracks(),
						mediaStreamTrack,
					]);
					el.srcObject = newStream;
				}
			}
		}

		if (video) {
			const track = this.getTrack("video");
			const el = this.media["video"];
			if (track && el) {
				track.detach();
				track.attach(el);
			}
		}
	}

	detach(kind) {
		const keys = kind ? [kind] : ["audio", "video"];
		for (let key of keys) {
			const track = this.getTrack(key);
			if (track) {
				track.detach();
			}
		}
	}
}

export default class LivekitClient {
	constructor(userId, isGroup, helpers, callAudio) {
		this.users = new Map();
		this.userId = userId;
		this.isGroupCall = isGroup;
		this.waitConnection = null;
		this.helpers = helpers;
		this._events = {};
		this._local = new CallUser(userId, null, callAudio, true);
		this._room = null;
		this._ended = false;
		this._connectionCanceled = false;

		// if the audio element defined then each user audio will be overwritten by it
		this.callAudio = callAudio;

		this.helpers.showAlert = msg => {
			webix.alert({
				container: this.helpers.container,
				text: msg,
			});
		};

		this.ready = webix
			.require(
				webix.env.cdn + "/extras/livekit-client/1.6.4/livekit-client.umd.js"
			)
			.then(() => {
				const wl = window.LivekitClient;
				Room = wl.Room;
				RoomEvent = wl.RoomEvent;
				ParticipantEvent = wl.ParticipantEvent;
				DisconnectReason = wl.DisconnectReason;
			});
	}

	/**
	 * Connects to the room specified by the join token
	 * @param {object} config - livekit config
	 * @param {string} token - join token
	 * @returns {Promise} - 'resolve' if the local participant connected successfully, reject otherwise
	 */
	connect(config, token) {
		if (this._room != null) {
			this.end();
			this._room = null;
		}

		this._room = new Room({
			adaptiveStream: true,
			dynacast: true,
		});

		this._room
			.on(RoomEvent.LocalTrackPublished, p => this.onLocalTrackPublished(p))
			.on(RoomEvent.LocalTrackUnpublished, p => this.onLocalTrackUnpublished(p))
			.on(RoomEvent.ParticipantConnected, p => this.onParticipantConnected(p))
			.on(RoomEvent.ParticipantDisconnected, p =>
				this.onParticipantDisconnected(p)
			)
			.on(RoomEvent.Disconnected, r => this.onDisconnected(r));

		this.waitConection = this._room
			.connect(config.host, token)
			.then(() => {
				if (this._connectionCanceled) {
					this._room.disconnect("connection canceled");
					return webix.promise.reject("room connection canceled");
				}

				this._room.participants.forEach(participant => {
					// subscribe already connected remote participants
					this.onParticipantConnected(participant);
				});

				this._local.refreshParticipant(this._room.localParticipant);

				this._room.localParticipant.on(ParticipantEvent.IsSpeakingChanged, v =>
					this.callEvent("onUserSpeakingChanged", [
						this.userId,
						this._local.media,
						v,
					])
				);

				return webix.promise.resolve();
			})
			.catch(err => {
				return webix.promise.reject(err);
			});

		return this.waitConection;
	}

	/**
	 * Refreshes user media such as audio, video elements
	 * @param {int} id - user id
	 * @param {object} data - meedia
	 */
	refreshUserMedia(id, data, local) {
		const user = local ? this._local : this.users.get(id);
		if (!user) {
			return;
		}

		user.refreshMedia(data);

		if (user.getTrack("audio")) {
			this.callEvent("onUserMediaEnable", [
				id,
				user.media,
				"audio",
				user.isAudioEnabled(),
				local,
			]);
		}

		if (user.getTrack("video")) {
			this.callEvent("onUserMediaEnable", [
				id,
				user.media,
				"video",
				user.isVideoEnabled(),
				local,
			]);
		}
	}

	/**
	 * Returns true if the room state equals 'connected'
	 * @returns {boolean} - true if the room state equals 'connected', false otherwise
	 */
	connected() {
		return this._room && this._room.state == "connected";
	}

	/**
	 * Attaches new event handler (only one handler can be attached at the same time)
	 * @param {string} kind - kind of output stream (audio|video)
	 * @param {boolean} value - value for the stream
	 */
	on(name, handler) {
		this._events[name] = handler;
	}

	/**
	 * Mutes/unmutes local media stream
	 * @param {string} kind - kind of the media stream (audio|video)
	 * @param {boolean} value - value for the media stream
	 * @returns {Promise} - 'resolve' if the stream was muted/unmuted successfully, reject otherwise
	 */
	enable(kind, value) {
		if (!checkKind(kind) || !this.connected()) return;

		const l = this._room.localParticipant;
		const enable =
			kind == "audio"
				? l.setMicrophoneEnabled.bind(l)
				: l.setCameraEnabled.bind(l);

		return enable(value)
			.catch(() => {
				this.handleGetUserMediaError(
					kind == "audio" ? l.microphoneError : l.cameraError,
					kind
				);
				value = false;
				return webix.promise.reject();
			})
			.finally(() => {
				this.callEvent("onUserMediaEnable", [
					this.userId,
					this._local.media,
					kind,
					value,
					true, // local user
				]);
			});
	}

	/**
	 * Ends the room connection
	 * If the room is not connected, then connection is canceled
	 */
	end() {
		if (this._ended || !this._room) return;
		this._ended = true;

		if (this._room.state === "connecting") {
			this._connectionCanceled = true;
		} else {
			this._room.disconnect();
		}
	}

	/**
	 * Returns livekit room instance
	 * @returns {Room}
	 */
	room() {
		return this._room;
	}

	/**
	 * Returns local user instance
	 * @returns {CallUser}
	 */
	localUser() {
		return this._local;
	}

	/**
	 * Clears attached events
	 */
	clearEvents() {
		if (this._ended) return;
		for (let name in this._events) {
			this._events[name] = null;
		}
	}

	/**
	 * Calls event handler
	 * @param {string} name - event name
	 * @param {any[]} args - event arguments
	 */
	callEvent(name, args) {
		if (this._ended) return;
		const e = this._events[name];
		if (e) e(args || []);
	}

	/**
	 * Handles GetUserMedia error
	 * @param {object} error - remote participant publication
	 * @param {string} kind - kind of media error (audio|video)
	 */
	handleGetUserMediaError(error, kind) {
		if (!this.connected()) return;

		switch (error.name) {
			case "NotFoundError":
				this.helpers.showAlert(
					this.helpers.locale("Could not find your") +
						" " +
						this.helpers.locale(kind == "audio" ? "microphone" : "camera")
				);
				break;
			case "SecurityError":
			case "PermissionDeniedError":
				if (this.helpers) {
					this.helpers.showAlert(this.helpers.locale("Internal call error"));
					this.helpers.end();
				}
				break;
			default:
				this.helpers.showAlert(
					this.helpers.locale("Error opening your") +
						" " +
						this.helpers.locale(kind == "audio" ? "microphone" : "camera")
				);
				break;
		}
	}

	/**
	 * Handles onLocalTrackPublished room event
	 * @param {object} publication - remote participant publication
	 */
	onLocalTrackPublished(publication) {
		if (publication.kind == "video") {
			const track = publication.track || this._local.getTrack(publication.kind);
			if (!track || !checkKind(track.kind)) {
				return;
			}

			this._local.attach(track.kind);
			this.callEvent("onUserMediaEnable", [
				this.userId,
				this._local.media,
				publication,
				true, // enabled/disabled
				true, // is local
			]);
		}
	}

	/**
	 * Handles onLocalTrackUnpublished room event
	 * @param {object} publication -  local participant publication
	 */
	onLocalTrackUnpublished(publication) {
		this._local.detach(publication.kind);
	}

	/**
	 * Handles onParticipantConnected room event
	 * @param {object} participant - remote participant
	 */
	onParticipantConnected(participant) {
		const id = parseInt(participant.identity);
		const user = new CallUser(id, participant, this.callAudio);

		const mediaEvent = (kind, value) => {
			this.callEvent("onUserMediaEnable", [id, user.media, kind, value]);
		};

		participant.tracks.forEach(publication => {
			const track = publication.track;
			if (!track || !checkKind(track.kind)) return;

			user.attach(track.kind);
		});

		participant.on(ParticipantEvent.TrackSubscribed, publication => {
			const track = publication.track || user.getTrack(publication.kind);
			if (!track || !checkKind(track.kind)) {
				return;
			}

			user.attach(track.kind);
			mediaEvent(publication.kind, user.isMediaEnabled(publication.kind));
		});

		participant.on(ParticipantEvent.TrackUnsibscribed, publication => {
			const track = publication.track;
			if (!track || !checkKind(track.kind)) return;

			user.detach(publication.kind);
			mediaEvent(track.kind, false);
		});

		participant.on(ParticipantEvent.TrackMuted, publication =>
			mediaEvent(publication.kind, false)
		);

		participant.on(ParticipantEvent.TrackUnmuted, publication =>
			mediaEvent(publication.kind, true)
		);

		participant.on(ParticipantEvent.IsSpeakingChanged, v =>
			this.callEvent("onUserSpeakingChanged", [user.id, user.media, v])
		);

		this.users.set(id, user);
		this.callEvent("onUserAdded", [user.id, user]);
	}

	/**
	 * Handles onParticipantDisconnected room event
	 * @param {object} participant - remote participant
	 */
	onParticipantDisconnected(participant) {
		const id = parseInt(participant.identity);
		const u = this.users.get(id);
		if (u) {
			// clear tracks
			u.detach();
			this.users.delete(id);
			this.callEvent("onUserRemoved", [id]);
		}
	}

	/**
	 * Handles onDisconnected room event
	 */
	onDisconnected(reason) {
		this.users.forEach((user, key) => {
			user.detach();
			this.users.delete(key);
		});

		this._local.detach();
		this._local = null;
		this.clearEvents();

		if (reason == DisconnectReason.ROOM_DELETED) {
			this.helpers.end();
		}
	}
}

function checkKind(kind) {
	return kind === "audio" || kind === "video";
}
