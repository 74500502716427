import { JetView } from "webix-jet";
import "../helpers/search";

export default class SideBar extends JetView {
	config() {
		const _ = this.app.getService("locale")._;
		const tabbar = {
			view: "tabbar",
			borderless: true,
			css: "webix_chat_tabbar",
			height: 52,
			bottomOffset: 0,
			//type: "icon",
			options: [
				{ value: _("Chats"), id: "chats" },
				{ value: _("Users"), id: "users" },
			],
			on: {
				onChange: v => this.ShowPanel(v),
			},
		};

		const rows = [
			tabbar,
			{
				css: "webix_chat_search",
				padding: 9,
				rows: [
					{
						view: "chat-search",
						localId: "search",
						height: Math.max(webix.skin.$active.inputHeight, 38),
						placeholder: _("Search"),
					},
				],
			},
			{
				$subview: true,
				name: "left",
			},
		];

		return {
			css: "webix_chat_sidebar",
			width: 320,
			type: "clean",
			rows,
		};
	}

	init() {
		this.State = this.getParam("state", true);
		this.ShowPanel("chats");

		this.$$("search").attachEvent("onTimedKeyPress", () => {
			this.State.search = this.$$("search").getValue();
		});
		this.$$("search").attachEvent("onChange", () => {
			this.State.search = this.$$("search").getValue();
		});
		this.on(this.State.$changes, "search", v => {
			if (v) this.$$("search").showCloseIcon();
			else {
				this.$$("search").setValue("");
				this.$$("search").hideCloseIcon();
			}
		});
	}

	/**
	 * Shows chats or users list depending on a specified value
	 * @param {string} v - "chats" or "users"
	 */
	ShowPanel(v) {
		this.State.search = "";
		this.show("./" + v, { target: "left" });
	}
}
