import FormBaseView from "../common/form";

export default class FormView extends FormBaseView {
	init() {
		this.on(this.app, "wizardSave", () => this.Save());

		return super.init();
	}

	/**
	 * Saves chat updates (name, title, etc.)
	 */
	Save() {
		const state = this.getParam("state", true);
		const ops = this.app.getService("operations");
		const form = this.$$("form").getValues();

		ops.updateChat(state.chat, form.name, form.avatar.avatar);
		// [NOT SURE] code doesn't wait server side confirmation
		state.$batch({
			name: form.name,
			avatar: form.avatar.avatar,
			cursor: 1,
		});
	}
}
