import FormBaseView from "../common/form";

export default class FormView extends FormBaseView {
	config() {
		const _ = this.app.getService("locale")._;
		const form = super.config(true);

		const nextButton = {
			view: "button",
			localId: "next",
			type: "icon",
			hotkey: "enter",
			icon: "chi-next",
			css: "webix_primary",
			label: `<span class="webix_chat_next_text">${_("People")}</span>`,
			width: 160,
			disabled: true,
			height: Math.max(webix.skin.$active.inputHeight, 38),
			align: "center",
			click: () => {
				this.ShowNextPage();
			},
		};

		form.elements.push({ align: "right", body: nextButton });
		return form;
	}

	/**
	 * Shows the list of users to add to a new chat (when users clicks "People ->")
	 */
	ShowNextPage() {
		let state = this.getParam("state", true);
		const form = this.$$("form").getValues();

		state.name = form.name;
		// forminput returns avatar: { avatar: key }
		state.avatar = form.avatar.avatar;
		state.cursor = state.cursor + 1;
	}

	/**
	 * Enables/Disables the next step in chat creation depending on whether the chat name field is filled
	 * @param {string} value - input value
	 */
	NameChangedHandler(value) {
		const next = this.$$("next");
		if (value) next.enable();
		else next.disable();
	}
}
