import { JetView } from "webix-jet";
import "../../../helpers/customcheckbox";

export default class PeopleView extends JetView {
	config() {
		const selectedList = {
			batch: "selected",
			view: "template",
			localId: "selected",
			scroll: "x",
			template: "",
			borderless: true,
		};

		const material =
			webix.skin.$name == "material" || webix.skin.$name == "mini";
		selectedList.height = 80 + (material ? 8 : 10) + webix.env.scrollSize; // item height + paddings + scroll

		const table = {
			view: "datatable",
			localId: "table",
			css: "webix_chat_people_table",
			rowHeight: 60,
			headerRowHeight: Math.max(webix.skin.$active.barHeight - 2, 44),
			columns: this.GetColumns(),
			scheme: {
				$sort: {
					by: "name",
					dir: "asc",
				},
			},
			checkboxRefresh: true,
			on: {
				onItemClick: id => this.Toggle(id.row * 1),
				onCustomSave: () => this.UpdatePeopleList(),
			},
		};

		return {
			visibleBatch: "default",
			rows: [selectedList, table],
		};
	}

	init() {
		const users = this.app.getService("local").users();
		const table = this.$$("table");
		this.Users = [].concat(this.getParam("state", true).users);

		// to work with data copy
		this.UsersStore = new webix.DataCollection({
			data: [],
		});
		this.UsersStore.data.attachEvent("onSyncApply", () => {
			table.clearAll();
			table.parse(
				this.UsersStore.data
					.serialize()
					.map(({ id, name, avatar, status }) => {
						return { id, name, avatar, status };
					})
					.filter(a => a.id !== this.app.config.user)
			);
			table.sort((a, b) => this.SortUsers(a, b));
			this.LoadPeopleList();
		});

		this.UsersStore.sync(users);
	}
	destroy() {
		this.UsersStore.destructor();
		this.UsersStore = null;
	}

	/**
	 * Retrieves columns with selected users and full list of users to select
	 * @returns {Array} an array of column objects
	 */
	GetColumns() {
		const _ = this.app.getService("locale")._;
		const helpers = this.app.getService("helpers");
		return [
			{
				id: "selected",
				header: {
					content: "textFilter",
					placeholder: _("Search"),
					colspan: 2,
					prepare: a => a.toLowerCase(),
					compare: this.GetCompare(),
				},
				width: 45,
				cssFormat: (_, obj) => {
					if (obj.selected) {
						let isGroupUser = this.Users.indexOf(obj.id) >= 0;
						return "webix_chat_row_" + (isGroupUser ? "group_user" : "select");
					}
					return "";
				},
				template: obj => {
					return obj.selected ? helpers.checkedIcon : helpers.notCheckedIcon;
				},
			},
			{
				id: "name",
				header: "",
				template: user =>
					helpers.listAvatar(user, "webix_chat_people_avatar") + user.name,
				fillspace: 2,
				cssFormat: (_, obj) => {
					if (obj.selected) {
						let isGroupUser = this.Users.indexOf(obj.id) >= 0;
						return "webix_chat_row_" + (isGroupUser ? "group_user" : "select");
					}
					return "";
				},
			},
		];
	}

	/**
	 * Compares data from the search field with the user names
	 * @returns {boolean} true, if user name matches the entered data
	 */
	GetCompare() {
		return (value, searchValue, user) => {
			return user.name.toLowerCase().indexOf(searchValue) > -1;
		};
	}

	/**
	 *
	 * @param {Array} selected - contains objects of the selected users
	 * @returns {string} a string with an HTML markup for people list
	 */
	GetPeopleList(selected) {
		selected.sort((a, b) => this.SortUsers(a, b));
		return this.app
			.getService("helpers")
			.peopleList(selected, false, this.Users);
	}

	/**
	 * Sorts users list by id
	 * @param {Object} a - user data
	 * @param {Object} b - user data
	 * @returns {number} 1 to place "a" object first, -1 to place "b" object first
	 */
	SortUsers(a, b) {
		const ai = this.Users.indexOf(a.id);
		const bi = this.Users.indexOf(b.id);

		// previously added first
		if (ai >= 0 && bi < 0) return 1;
		if (bi >= 0 && ai < 0) return -1;
		return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
	}

	/**
	 * Toggles the user selection
	 * @param {number} id - user ID
	 */
	Toggle(id) {
		const table = this.$$("table");
		const item = table.getItem(id);
		table.updateItem(id, { selected: !item.selected });
		this.UpdatePeopleList();
	}

	/**
	 * Updates users lists on every selection toggle
	 */
	UpdatePeopleList() {
		let selected = this.GetSelected();
		this.SetBatch(selected.length ? "selected" : "default");
		let html = this.GetPeopleList(selected);
		this.$$("selected").setHTML(html);
		let state = this.getParam("state", true);
		state.users = this.GetSelectedIds();
	}

	/**
	 * Populates users list and builds a dedicated HTML markup
	 */
	LoadPeopleList() {
		let state = this.getParam("state", true);
		let ids = state.users.filter(a => a !== this.app.config.user);

		if (ids && ids.length) {
			let users = [];
			ids.forEach(id => {
				let item = this.$$("table").getItem(id);
				users.push(item);
				item.selected = true;
			});
			this.SetBatch("selected");
			let html = this.GetPeopleList(users);
			this.$$("selected").setHTML(html);
		}
	}

	/**
	 * Returns an array containing data objects of the selected users
	 * @returns {Array<Object>} contains objects of the selected users
	 */
	GetSelected() {
		let selected = [];
		this.$$("table").data.each(row => {
			if (row.selected) selected.push(row);
		});
		return selected;
	}

	/**
	 * Returns an array containing IDs of the selected users
	 * @returns {Array<number>} an array of ID of the selected users
	 */
	GetSelectedIds() {
		let selected = [];
		this.$$("table").data.each(row => {
			if (row.selected) selected.push(row.id);
		});
		return selected;
	}

	/**
	 * Sets a specified batch or "default"
	 * @param {string} batch - batch text
	 */
	SetBatch(batch) {
		if (batch) this.getRoot().showBatch(batch);
		else this.getRoot().showBatch("default");
	}
}
