import BaseWindow from "../common/window";

export default class NewChatWin extends BaseWindow {
	stages() {
		const _ = this.app.getService("locale")._;
		return [
			null,
			[
				"./chat.common.people",
				{
					label: `<span class="webix_chat_wizard_title2">${_(
						"Add members"
					)}</span>`,
					cols: ["close", "label", "save"],
				},
			],
		];
	}

	Save() {
		// view will be destroyed till the the op callback
		const app = this.app;
		const state = this.getParam("state");
		if (state.users.length) {
			app.getService("operations").setUsers(state.chat, state.users);
		}
	}
}
