import { Client } from "remote-client";

export default class Backend {
	constructor(app) {
		this.app = app;
		const remote = new Client({
			url: app.config.url,
			token: app.config.token,
		});

		this._ready = remote.load().then(back => {
			this._api = back.api;
			this._data = back.data;
			this._pubsub = {
				attachEvent: (name, handler) => back.on(name, handler),
				detachEvent: ev => back.detach(ev),
			};

			const id = (app.config.user = this._data.user);
			app.config.device = this._data.device;
			// mark current user as online
			this._data.users.forEach(a => {
				if (a.id === id) a.status = 2;
			});
		});

		this.callStatuses = {
			init: 1,
			accept: 2,
			active: 3,
			disconnect: 801,
			drop: 900,
			reject: 901,
			end: 902,
			ignore: 903,
			lost: 904,
			busy: 905,
		};

		this.callUserStatuses = {
			disconnected: 0,
			initiated: 1,
			connecting: 2,
			active: 3,
		};

		this.callMessages = {
			900: "start",
			901: "reject",
			902: "ignore",
			903: "busy",
		};

		this.callErrors = {
			"#ERR_01": "Call is active in another chat",
			"#ERR_02": "Already in the call",
			"#ERR_03": "Line is busy",
		};
	}

	ready() {
		return this._ready;
	}

	/**
	 * Loads users
	 * @returns {Promise} a promise that resolves with an array of users
	 */
	users() {
		return Promise.resolve(this._data.users);
	}
	/**
	 * Loads chats
	 * @returns {Promise} a promise that resolves with an array of chats
	 */
	chats() {
		return Promise.resolve(this._data.chats);
	}
	/**
	 * Loads call data
	 * @returns {Promise} a promise that resolves with a call object
	 */
	callInfo() {
		return Promise.resolve(this._data.call);
	}
	/**
	 * Creates a direct chat with a specified user
	 * @param {number} uid - user ID
	 * @returns {Promise} that resolves with an object containing data about the chat created (e.g. chat id, users involved, etc.)
	 */
	addChat(uid) {
		return this._api.chat.AddDirect(uid * 1);
	}
	/**
	 * Retrieves all messages of a specified chat
	 * @param {number} cid - chat ID
	 * @returns {Promise} that resolves with an array with message objects (text, user id, message type, etc.)
	 */
	messages(cid) {
		return this._api.message.GetAll(cid * 1).then(data => {
			data.forEach(a => (a.date = new Date(a.date)));
			return data;
		});
	}
	/**
	 * Add new message to a specified chat
	 * @param {number} cid - chat ID
	 * @param {string} text - message text
	 * @param {number} origin - message ID
	 * @returns {Promise} that resolves with an object with message data (id, user id, message type, etc.)
	 */
	addMessage(cid, text, origin) {
		return this._api.message.Add(text, cid * 1, origin.toString()).then(a => {
			a.date = new Date(a.date);
			return a;
		});
	}

	/**
	 * Removes a specified message
	 * @param {number} messageId - message ID
	 * @returns {Promise} that resolves with an error or null (if deletion was successful)
	 */
	removeMessage(messageId) {
		return this._api.message.Remove(messageId * 1);
	}
	/**
	 * Updates a specified message
	 * @param {number} messageId - message ID
	 * @param {string} text - new message text
	 * @returns {Promise} that resolves with an object with the updated message data
	 */
	updateMessage(messageId, text) {
		return this._api.message.Update(messageId * 1, text);
	}

	/**
	 * Resets counter of the unread messages of a specified chat
	 * @param {number} cid - chat ID
	 */
	resetCounter(cid) {
		this._api.message.ResetCounter(cid * 1);
	}

	/**
	 * Is called upon widget initialization
	 * @returns {Object} an object with methods for attaching and detaching events
	 */
	pubsub() {
		return this._pubsub;
	}

	/**
	 * Creates a group chat
	 * @param {string} name - chat name
	 * @param {string} avatar - URL to the chat avatar
	 * @param {Array} users - contains ID of the users involved
	 * @returns {Promise} that resolves with an object containing data of a newly created group chat
	 */
	addGroupChat(name, avatar, users) {
		return this._api.chat.AddGroup(name, avatar, users);
	}

	/**
	 * Updates a specified chat
	 * @param {number} id - chat ID
	 * @param {string} name - chat name
	 * @param {string} avatar - URL to the chat avatar
	 * @returns {Promise} that resolves with an object containing data of the updated group chat
	 */
	updateChat(id, name, avatar) {
		return this._api.chat.Update(id, name, avatar);
	}

	/**
	 * Removes a specified user from a specified chat
	 * @param {number} chatId - chat ID
	 * @param {number} userId  - user ID
	 * @returns {Promise} that resolves with an error or null (if deletion was successful)
	 */
	leave(chatId, userId) {
		return this._api.chat.Leave(chatId, userId);
	}

	/**
	 * Add new users to a specified chat
	 * @param {number} chatId - chat ID
	 * @param {Array} users - an array containing ID of the users participating
	 * @returns {Promise} that resolves with an object of the updated chat
	 */
	setUsers(chatId, users) {
		return this._api.chat.SetUsers(chatId, users);
	}

	/**
	 * Retrieves a URL for downloading avatar of a specified chat
	 * @param {number} id - chat ID
	 * @returns {string} a URL for downloading chat avatars
	 */
	avatarUploadUrl(id) {
		return this.app.config.url + `/chat/${id}/avatar`;
	}

	/**
	 * Retrieves a URL for downloading file in a specified chat
	 * @param {number} id - chat ID
	 * @returns {string} a URL for downloading an uploaded file
	 */
	fileUploadUrl(id) {
		return this.app.config.url + `/chat/${id}/file`;
	}

	/**
	 * Retrieves a URL for downloading voice message in a specified chat
	 * @param {number} id - chat ID
	 * @returns {string} a URL for downloading an uploaded file
	 */
	voiceUploadUrl(id) {
		return this.app.config.url + `/chat/${id}/voice`;
	}

	/**
	 * Initiates a call with a specified user
	 * @param {number} withUser - user ID
	 * @param {number} chatID - chat ID
	 * @returns {Promise} that resolves with an object containing call data
	 */
	startCall(withUser, chatID) {
		return this._api.call.Start(withUser, chatID);
	}

	/**
	 * Is called to get Join token for the call
	 * @param {number} callId - call ID
	 * @returns {Promise} that resolves with an object containing join token
	 */
	joinToken(callId) {
		return this._api.call.JoinToken(callId);
	}

	/**
	 * Is called to update the user status in the call
	 * @param {number} callId - call ID
	 * @param {number} status - user status
	 * @returns {Promise} that resolves with an object containing join token
	 */
	updateUserStatus(callId, status) {
		return this._api.call.SetUserStatus(callId, status);
	}

	/**
	 * Is called when call is accepted or canceled
	 * @param {number} id - call ID
	 * @param {number} value - call status code (1, 2, 900, etc.)
	 * @returns {Promise} that resolves with an object with the data updated
	 */
	updateCall(id, value) {
		return this._api.call.SetStatus(id, value);
	}
	/**
	 * Is called to send a signal to the server, when certain call operations are performed.
	 * @param {string} type - signal type ("answer", "active", etc.)
	 * @param {Object} payload - object containing the `operation: status` pairs ({audio: true})
	 * @returns {Promise} that resolves with an object containing the signal data
	 */
	signalCall(type, payload) {
		return this._api.call.Signal(type, payload);
	}

	addReaction(msgId, reaction) {
		return this._api.message.AddReaction(msgId, reaction).then(a => {
			a.date = new Date(a.date);
			return a;
		});
	}

	removeReaction(msgId, reaction) {
		return this._api.message.RemoveReaction(msgId, reaction).then(a => {
			a.date = new Date(a.date);
			return a;
		});
	}
	basicEmojis() {
		return Promise.resolve(["+1", "-1", "smile", "frowning", "tada", "heart"]);
	}
}
