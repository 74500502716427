import rtc from "./rtc";

function setStream(nodes, type, value) {
	const track = value.track;

	if (!track) return;

	const kind = track.kind;
	const tag = nodes[type][kind];

	const stream = new MediaStream();
	stream.addTrack(track);

	tag.srcObject = stream;
}

export default function phone(
	sendSignal,
	rtcConfig,
	nodes,
	role,
	mode,
	locale,
	node
) {
	mode = webix.extend(mode || {}, {
		audio: true,
		video: false,
	});

	// local config
	const emode = {
		audio: true,
		video: false,
	};

	// remote config
	const remote = {
		audio: false,
		video: false,
	};

	const toggleRemote = (kind, value) => {
		const trigger = nodes.active["remote"]
			? nodes.active["remote"][kind]
			: null;
		if (trigger) trigger(value);
		remote[kind] = value;
	};

	let connecting = true;
	let baseConn;

	const handler = ({ action, conn, final, kind, promise }) => {
		if (action === "upgrade") {
			mode[kind] = true;
			baseConn.upgrade(kind, promise);
		} else if (action === "end") {
			if (nodes.remote.audio.srcObject)
				nodes.remote.audio.srcObject.getTracks().forEach(track => track.stop());
			if (nodes.local.audio.srcObject)
				nodes.local.audio.srcObject.getTracks().forEach(track => track.stop());
			if (nodes.remote.video.srcObject)
				nodes.remote.video.srcObject.getTracks().forEach(track => track.stop());
			if (nodes.local.video.srcObject)
				nodes.local.video.srcObject.getTracks().forEach(track => track.stop());

			nodes.remote.audio.removeAttribute("src");
			nodes.remote.audio.removeAttribute("srcObject");
			nodes.local.video.removeAttribute("src");
			nodes.local.video.removeAttribute("srcObject");
			nodes.remote.video.removeAttribute("src");
			nodes.remote.video.removeAttribute("srcObject");

			if (final) nodes.end();
		} else if (action === "streams") {
			conn.getSenders().forEach(a => setStream(nodes, "local", a));
			conn.getReceivers().forEach(a => setStream(nodes, "remote", a));
		}
	};

	const events = (type, msg) => {
		switch (type) {
			case "reset":
				baseConn.end();
				baseConn = init();
				break;
			case "offer":
				baseConn.onOffer(msg);
				break;
			case "answer":
				baseConn.onAnswer(msg);
				break;
			case "new-ice-candidate":
				baseConn.onCandidate(msg);
				break;
			case "await-offer":
				sendSignal("active", { ...emode });
				baseConn.restart();
				break;
			case "break-connection":
				toggleRemote("video", false);
				nodes.active.remote.connecting(true);
				connecting = true;
				break;
			case "active":
				if (connecting) {
					nodes.active.remote.connecting(false);
					connecting = false;
				}
				for (var key in msg) {
					toggleRemote(key, msg[key]);
				}
				break;
		}
	};

	const init = () => {
		sendSignal("active", { ...emode });
		return rtc(sendSignal, rtcConfig, handler, locale, node, role);
	};
	baseConn = init();

	const end = () => {
		if (baseConn) baseConn.end();
	};
	const enable = (key, value) => {
		const promise = baseConn.enable(key, value);

		promise
			.then(() => {
				emode[key] = value;
			})
			.catch(() => {
				emode[key] = mode[key] = false;
			})
			.finally(() => {
				sendSignal("active", { ...emode });
			});

		return promise;
	};

	switch (role) {
		case 1:
			baseConn.start(mode, emode);
			break;
		case 2:
			sendSignal("await-offer", "");
			break;
		case 3:
			baseConn.start(mode, emode, true);
			break;
	}

	return { events, enable, end };
}
