webix.protoUI(
	{
		name: "chat-comments",
		$cssName: "comments",
		$init(config) {
			if (config.ChatFormConfig) config.ChatFormConfig(config);
			if (config.ChatListConfig) config.ChatListConfig(config);
		},
	},
	webix.ui.comments
);
webix.protoUI(
	{
		name: "chat-comments-layout",
		$cssName: "layout",
		hide() {},
	},
	webix.ui.layout
);
webix.protoUI(
	{
		name: "chat-comments-text",
		$cssName: "texthighlight",
		define(a, b) {
			const h = a.height;
			if (h) {
				const c = this.config;
				if (h < 70 && h > c.inactiveHeight) a.height = c.inactiveHeight;
			}
			return webix.ui.texthighlight.prototype.define.call(this, a, b);
		},
	},
	webix.ui.texthighlight
);
