import { JetView } from "webix-jet";

export default class EmojiTemplate extends JetView {
	config() {
		const _ = (this._ = this.app.getService("locale")._);
		this.Emojis = this.app.getService("emojis");

		return {
			view: "popup",
			body: {
				margin: 0,
				rows: [
					{
						padding: { top: 5, bottom: 5, left: 10, right: 10 },
						visibleBatch: "categories",
						localId: "toolbar",
						rows: [
							{
								batch: "categories",
								cols: [
									{
										view: "icon",
										localId: "searchBtn",
										icon: "wxi-search",
									},
									{
										view: "list",
										borderless: true,
										localId: "categories",
										css: "webix_chat_emoji_categories_list",
										template: obj => this.CategoryMenuTemplate(obj),
										select: true,
										layout: "x",
										on: {
											onItemClick: (id, e) => {
												this.ShowCategory(id);
												webix.html.preventEvent(e);
											},
										},
										data: this.Emojis.categories(),
									},
								],
							},
							{
								batch: "search",
								margin: webix.skin.$active.layoutMargin.form,
								cols: [
									{
										view: "icon",
										localId: "backBtn",
										icon: "chi-back",
									},
									{ view: "text", localId: "search", placeholder: _("Search") },
								],
							},
						],
					},
					{
						view: "scrollview",
						height: 200,
						scroll: true,
						css: "webix_chat_layout_emoji",
						localId: "views",
						body: {
							visibleBatch: "categories",
							type: "clean",
							padding: {
								left: 10,
								right: 10,
							},
							rows: this.GetLists(),
						},
					},
					{
						localId: "preview",
						view: "template",
						borderless: true,
						hidden: webix.env.mobile,
						template: " ",
						height: 40,
					},
				],
			},
		};
	}
	init() {
		this.Preview = this.$$("preview");

		const search = this.$$("search");
		search.attachEvent("onTimedKeyPress", () => {
			this.Find(search.getValue());
		});
		this.ScrollHandler();
		// webix.event is used instead of "click" property to keep textarea focused
		this._searchClickEvent = webix.event(
			this.$$("searchBtn").$view,
			"click",
			e => {
				this.ShowSearchHeader();
				webix.html.preventEvent(e);
			}
		);
		this._backClickEvent = webix.event(this.$$("backBtn").$view, "click", e => {
			this.HideSearchHeader();
			webix.html.preventEvent(e);
		});
	}

	/**
	 * Prepares scroll for suggest with emojis
	 */
	ScrollHandler() {
		const views = this.$$("views")
			.getBody()
			.getChildViews();
		let sum = 0,
			heights = [];
		views.forEach(v => {
			if (!v.config.hidden) {
				sum += v.$height - 5;
				heights.push(sum);
			}
		});
		this._scrollEvent = webix.event(this.$$("views").$view, "scroll", () => {
			const y = this.$$("views").$view.scrollTop;
			let index;
			for (let i = 0; i < heights.length && typeof index == "undefined"; i++) {
				if (
					i == heights.length - 1 ||
					(y < heights[i] && (!i || y >= heights[i - 1]))
				)
					index = i;
			}

			this.$$("categories").select(this.Emojis.categories()[index].id);
		});
	}

	/**
	 * Builds rows with emojis in suggest list
	 * @returns {Array} an array of emoji rows
	 */
	GetLists() {
		const rows = [];
		const data = {};
		this.Emojis.emojis().data.each(emoji => {
			if (!data[emoji.category]) data[emoji.category] = [];
			data[emoji.category].push(emoji);
		});
		this.Emojis.categories().forEach(c => {
			rows.push(
				this.ListConfig(
					"category_" + c.id,
					c.id == "basic" ? this.Emojis.basicEmojis() : data[c.id],
					this._(c.name),
					"categories"
				)
			);
		});
		rows.push(
			this.ListConfig("searchResults", [], this._("Search results"), "search")
		);
		return rows;
	}

	/**
	 * Creates config for emoji category
	 * @param {string} id - category ID
	 * @param {Array} data - an array of emoji objects
	 * @param {string} title - category title
	 * @param {string} batch - batch for categories ("categories" or "search")
	 * @returns {Object} a config for emoji category
	 */
	ListConfig(id, data, title, batch) {
		const config = {
			type: "clean",
			batch,
			rows: [
				{
					template: title,
					css: "webix_emoji_category_label",
					autoheight: true,
				},
				{
					view: "dataview",
					localId: id,
					autoheight: true,
					xCount: 6,
					type: {
						css: "webix_chat_emojis_item",
						width: 40,
						height: 40,
					},
					on: {
						onItemClick: function(id) {
							this.$scope.app.callEvent("pasteEmoji", [id, this.getItem(id)]);
						},
						onMouseMove: function(id) {
							const item = this.getItem(id);
							this.$scope.ShowPreview(item);
						},
					},
					mouseEventDelay: 100,
					template: obj => this.ItemTemplate(obj),
					data,
				},
			],
		};

		if (!webix.env.mobile)
			config.rows[1].on.onMouseMove = function(id) {
				const item = this.getItem(id);
				this.$scope.ShowPreview(item);
			};

		return config;
	}

	/**
	 * Shows the suggest with emojis
	 * @param {Object} node - PointerEvent object
	 */
	Show(node) {
		this.HideSearchHeader();
		this.getRoot().show(node);
		const id = this.Emojis.categories()[0].id;
		this.$$("categories").select(id);
		this.ShowCategory(id);
	}

	/**
	 * Hides the suggest with emojis
	 */
	Hide() {
		this.getRoot().hide();
	}

	/**
	 * Creates an HTML template for emoji
	 * @param {Object} obj - emoji object
	 * @returns {string} an HTML template for emoji
	 */
	ItemTemplate(obj) {
		return (
			"<div class='webix_chat_emoji webix_chat_emoji_" +
			obj.unified +
			"'></div>"
		);
	}

	/**
	 * Shows emoji preview
	 * @param {number} item - emoji data
	 */
	ShowPreview(item) {
		this.Preview.setHTML(this.PreviewTemplate(item));
	}

	/**
	 * Creates a template for emoji preview (when user hovers over an emoji)
	 * @param {Object} obj - emoji object
	 * @returns {string} an HTML template for emoji preview
	 */
	PreviewTemplate(obj) {
		const search = this.$$("search");
		const value = search.isVisible() ? search.getValue() : "";
		let name = this.Emojis.findName(obj, value);
		if (!name) return "";
		name = this.Emojis.shortNameToTitle(name);
		return (
			"<div class='webix_chat_emoji_preview'>" +
			"<div class='webix_chat_emoji webix_chat_emoji_" +
			obj.unified +
			"'></div>" +
			"<div class='webix_chat_emoji_name'>" +
			name +
			"</div></div>"
		);
	}

	/**
	 * Looks for emojis names of which match a specified value
	 * @param {string} value - value from the search box in the emojis suggest
	 */
	Find(value) {
		if (value) {
			this.$$("views")
				.getBody()
				.showBatch("search");
			value = value.toLowerCase();
			const data = this.Emojis.emojis().data.find(item => {
				let result = false;
				for (let i = 0; i < item.names.length && !result; i++)
					result = item.names[i].includes(value);
				return result;
			});
			this.$$("searchResults").clearAll();
			this.$$("searchResults").parse(data);
		} else {
			this.$$("views")
				.getBody()
				.showBatch("categories");
		}
	}

	/**
	 * Shows the search box upon clicking the icon
	 */
	ShowSearchHeader() {
		this.$$("toolbar").showBatch("search");
		this.$$("search").setValue("");
		this.$$("search").focus();
	}

	/**
	 * Hides the search box
	 */
	HideSearchHeader() {
		this.Find();
		this.$$("views")
			.getBody()
			.showBatch("categories");
		this.$$("toolbar").showBatch("categories");
	}

	/**
	 * Creates a template for category title in the suggest
	 * @param {Object} obj - category object
	 * @returns {string} an HTML template for category title
	 */
	CategoryMenuTemplate(obj) {
		return `<span class='webix_icon ${obj.icon}'></span>`;
	}

	/**
	 * Shows a specified category in the suggest
	 * @param {number} categoryId - category ID
	 */
	ShowCategory(categoryId) {
		const index = this.Emojis.categories().findIndex(c => c.id == categoryId);
		const layoutId = this.$$("views")
			.getBody()
			.getChildViews()[index].config.id;
		this.$$("views").showView(layoutId);
	}
	destroy() {
		if (this._scrollEvent) webix.eventRemove(this._scrollEvent);
		webix.eventRemove(this._backClickEvent);
		webix.eventRemove(this._searchClickEvent);
	}
}
