import { JetView } from "webix-jet";

export default class Toolbar extends JetView {
	config() {
		const config = this.app.config;
		const _ = this.app.getService("locale")._;
		this.Compact = this.getParam("compact", true);
		const mode = config.mode;
		this.Limited = mode === "single" || mode === "limited";

		const width = Math.max(webix.skin.$active.inputHeight, 38);

		return {
			view: "toolbar",
			localId: "toolbar",
			visibleBatch: "active",
			css: "webix_chat_toolbar",
			padding: {
				right: 4,
				top: 3,
				bottom: 3,
			},
			elements: [
				{
					view: "icon",
					icon: "webix_icon chi-menu",
					click: () => this.app.callEvent("showSidebar", []),
					hidden: !this.Compact || mode === "single",
				},
				{
					view: "template",
					height: 46,
					localId: "chatTitle",
					css: "webix_chat_toolbar_tmpl",
					name: "chatTitle",
					borderless: true,
					template: data => this.TitleTemplate(data),
					onClick: {
						webix_chat_title: () => {
							this.ChatInfoHandler();
						},
					},
				},
				{
					view: "icon",
					batch: "active",
					icon: "chi-video",
					tooltip: _("Start call"),
					localId: "call",
					width,
					click: () => {
						this.StartCallHandler();
					},
				},
				{
					view: "icon",
					batch: "active",
					icon: "chi-account-plus",
					tooltip: _("Add members"),
					width,
					click: () => {
						this.NewMembersHandler();
					},
				},
				{
					view: "icon",
					batch: "active",
					icon: "wxi-dots",
					width,
					click: (id, ev) => {
						this.ToggleMenu(ev);
					},
				},
			],
		};
	}
	init() {
		const state = this.getParam("state", true);
		this.State = state;

		const chats = this.app.getService("local").chats();
		chats.data.attachEvent("onStoreUpdated", id => {
			if (!id || id == state.chatId) {
				this.Refresh();
			}
		});

		this.on(state.$changes, "*", () => {
			this.Refresh();
		});

		this.InitMenu();
	}

	/**
	 * Refreshes chats (e.g. when user navigates to a chat)
	 */
	Refresh() {
		const state = this.State;
		if (!this.getRoot()) return false;
		const local = this.app.getService("local");
		let obj;

		if (state.chatId) {
			const chats = local.chats();
			obj = chats.getItem(state.chatId);
		} else {
			const users = local.users();
			obj = users.getItem(state.userId);
		}

		obj = obj || {};

		this.$$("chatTitle").setValues(obj);

		const callsConfig = this.app.config.calls;
		if (
			callsConfig.enabled &&
			((callsConfig.groupCalls && !state.userId && state.chatId) ||
				state.userId)
		)
			this.$$("call").show();
		else this.$$("call").hide();

		const t = this.$$("toolbar");

		if (state.chatId && !this.Limited) t.showBatch("active");
		else t.showBatch("inactive");
	}

	/**
	 * Creates an HTML template for chat title
	 * @param {Object} data - chat object
	 * @returns {string} an HTML template fot chat title
	 */
	TitleTemplate(data) {
		const _ = this.app.getService("locale")._;
		const helpers = this.app.getService("helpers");
		let result = "";
		if (data.name) {
			result += helpers.listAvatar(data, "webix_chat_toolbar_avatar");
			if (data.users) {
				let members = data.users.length + " " + _("members");
				result += "<div class='webix_chat_title'>";
				if (data.direct_id)
					result += `<span class="webix_chat_messages_chat_name">${data.name}</span>`;
				else
					result +=
						`<div class="webix_chat_messages_groupchat_name">${data.name}</div>` +
						`<div class="webix_chat_messages_groupchat_members">${members}</div>`;
				result += "</div>";
			} else
				result += `<span class="webix_chat_messages_user_name">${data.name}</span>`;
		}
		return result;
	}

	/**
	 * Toggles the "Chat info" menu
	 * @param {Object} ev - PointerEvent object
	 */
	ToggleMenu(ev) {
		if (this.ToolbarMenu.isVisible()) this.ToolbarMenu.hide();
		else this.ToolbarMenu.show(ev.target, { pos: "left", y: 30, x: 10 });
	}

	/**
	 * Inits chat info menu
	 * @returns {Object} an instance of Webix contextmenu for chat info
	 */
	InitMenu() {
		this.ToolbarMenu = this.ui({
			view: "contextmenu",
			autowidth: true,
			point: false,
			data: this.GetMenuData(),
			on: {
				onItemClick: id => {
					if (this.ToolbarMenu.callEvent("onBeforeMenuAction", [id])) {
						if (id == "info") this.ChatInfoHandler();
					}
				},
			},
		});
		return this.ToolbarMenu;
	}

	/**
	 * Retrieves an array with chat menu options (objects)
	 * @returns {Array} an array with chat menu object
	 */
	GetMenuData() {
		const _ = this.app.getService("locale")._;
		return [{ id: "info", value: _("Chat info") }];
	}

	/**
	 * Checks wether the chat menu can be opened
	 */
	ChatInfoHandler() {
		if (this.State.chatId && !this.Limited)
			this.app.callEvent("chatInfo", [this.State.chatId]);
	}

	/**
	 * Fires an event to add new users to the current chat
	 */
	NewMembersHandler() {
		this.app.callEvent("newMembers", [this.State.chatId]);
	}

	/**
	 * Fires an event to start a call
	 */
	StartCallHandler() {
		if (!this.State.chatId) {
			return this.app
				.getService("operations")
				.addChat(this.State.userId)
				.then(chatId => {
					this.app.callEvent("showChat", ["user", this.State.userId]);
					this.app.callEvent("startCall", [this.State.userId, chatId]);
				});
		}

		this.app.callEvent("startCall", [
			this.State.userId || 0,
			this.State.chatId,
		]);
	}
}
