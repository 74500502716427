export default class UploadHandler {
	constructor(app) {
		this.app = app;
		this.initUploader();
		this.maxFileSize = 10000000;
	}

	/**
	 * Creates an instance of the Uploader view
	 */
	initUploader() {
		this.uploader = webix.ui({
			view: "uploader",
			apiOnly: true,
			// accept: "image/*",
			autosend: true,
			on: {
				onBeforeFileAdd: data => this.configUpload(data),
			},
		});
	}

	config(props) {
		webix.extend(this.uploader.config, props, true);
	}

	/**
	 *
	 * @param {number} chat - chat ID
	 * @param {string} mode	-	"avatar" or "file"
	 * @returns {Object} - the Uploader view
	 */
	getUploader(chat, mode) {
		this._chat = chat;
		this._mode = mode;
		return this.uploader;
	}

	/**
	 * Checks whether the size of the file being uploaded do not exceed the limit allowed
	 * @param {Object} file - File object (https://developer.mozilla.org/en-US/docs/Web/API/File)
	 * @returns {void|boolean} - if file size exceeds the limit, the method returns false
	 */
	configUpload(file) {
		const back = this.app.getService("backend");
		this.uploader.config.upload =
			this._mode == "file"
				? back.fileUploadUrl(this._chat)
				: back.avatarUploadUrl(this._chat);
		this.uploader.config.urlData = { token: this.app.config.token };
		if (file.size > this.maxFileSize) {
			this.app.callEvent("onSizeExceed", [file]);
			return false;
		}
	}

	/**
	 * Opens a file browsing dialog on a client machine
	 */
	fileDialog() {
		this.uploader.fileDialog();
	}
}
