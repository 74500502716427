import { JetView } from "webix-jet";

export default class Toolbar extends JetView {
	config() {
		return {
			view: "toolbar",
			height: Math.max(webix.skin.$active.toolbarHeight, 46),
			borderless: true,
			cols: [],
		};
	}

	init() {
		let state = this.getParam("state", true);
		this.on(state.$changes, "toolbar", () => {
			const cols = state.toolbar.cols.map(a => this.ButtonFactory(a, state));
			this.webix.ui(cols, this.getRoot());
		});
	}

	/**
	 * Defines a state object
	 * @typedef {Object} State
	 * @property {string} avatar - chat/user avatar
	 * @property {number} chat - chat ID
	 * @property {number} cursor -
	 * @property {boolean} group - true, if chat is a group one
	 * @property {string} name - chat title
	 * @property {Object} toolbar - an object containing a toolbar label and
	 * an array of toolbar cols
	 * @property {Array<number>} users - an array with user IDs
	 * @todo what is cursor?
	 */
	/**
	 * Returns a button config depending on the provided name (id)
	 * @param {string} id - button name
	 * @param {State} state - object with state data
	 * @returns {Object} button configuration
	 */
	ButtonFactory(id, state) {
		const _ = this.app.getService("locale")._;

		switch (id) {
			case "close":
				return {
					view: "icon",
					icon: "wxi-close",
					hotkey: "esc",
					width: Math.max(webix.skin.$active.inputHeight, 38),
					click: () => {
						state.cursor = 0;
					},
				};

			case "back":
				return {
					view: "icon",
					icon: "chi-back",
					width: Math.max(webix.skin.$active.inputHeight, 38),
					hotkey: "esc",
					click: () => {
						state.cursor = state.cursor - 1;
					},
				};

			case "start":
				return {
					view: "icon",
					icon: "chi-back",
					width: Math.max(webix.skin.$active.inputHeight, 38),
					hotkey: "esc",
					click: () => {
						this.app.callEvent("wizardStart", []);
					},
				};

			case "label":
				return {
					view: "label",
					css: "webix_chat_wizard_title",
					labelAlign: "center",
					label:
						typeof state.toolbar.label === "function"
							? state.toolbar.label()
							: state.toolbar.label,
				};

			case "edit":
				return {
					view: "icon",
					icon: "wxi-pencil",
					width: Math.max(webix.skin.$active.inputHeight, 38),
					batch: "m1",
					click: () => {
						state.cursor = state.cursor + 1;
					},
				};

			case "save":
				return {
					batch: "m2",
					view: "button",
					label: _("Save"),
					hotkey: "enter",
					width: 130,
					css: "webix_primary",
					click: () => {
						this.app.callEvent("wizardSave", []);
					},
				};
		}
	}
}
