import m0 from "./views/call/base";
import m1 from "./views/call/group";
import m2 from "./views/call/personal";
import m3 from "./views/chat/common/form";
import m4 from "./views/chat/common/people";
import m5 from "./views/chat/common/toolbar";
import m6 from "./views/chat/common/window";
import m7 from "./views/chat/info/form";
import m8 from "./views/chat/info/index.js";
import m9 from "./views/chat/info/info";
import m10 from "./views/chat/info/people";
import m11 from "./views/chat/members/index.js";
import m12 from "./views/chat/new/form";
import m13 from "./views/chat/new/index.js";
import m14 from "./views/chats";
import m15 from "./views/compact/sidemenu";
import m16 from "./views/list";
import m17 from "./views/messages/emojis";
import m18 from "./views/messages/emojisuggest";
import m19 from "./views/messages/index.js";
import m20 from "./views/messages/toolbar";
import m21 from "./views/sidebar";
import m22 from "./views/top";
import m23 from "./views/users";
import { JetView } from "webix-jet";

const views = { JetView };
views["call/base"] = m0;
views["call/group"] = m1;
views["call/personal"] = m2;
views["chat/common/form"] = m3;
views["chat/common/people"] = m4;
views["chat/common/toolbar"] = m5;
views["chat/common/window"] = m6;
views["chat/info/form"] = m7;
views["chat/info"] = m8;
views["chat/info/info"] = m9;
views["chat/info/people"] = m10;
views["chat/members"] = m11;
views["chat/new/form"] = m12;
views["chat/new"] = m13;
views["chats"] = m14;
views["compact/sidemenu"] = m15;
views["list"] = m16;
views["messages/emojis"] = m17;
views["messages/emojisuggest"] = m18;
views["messages"] = m19;
views["messages/toolbar"] = m20;
views["sidebar"] = m21;
views["top"] = m22;
views["users"] = m23;

export default views;
