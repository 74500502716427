export default class Local {
	constructor(app) {
		this.app = app;

		this._messages = {};
		this._subs = [];
	}

	/**
	 * Gets the users data
	 * @returns {Object|Promise} users collection directly or inside a promise
	 */
	users() {
		if (!this._users) {
			const back = this.app.getService("backend");
			this._users = new webix.DataCollection({});
			back.users().then(d => this._users.parse(d));

			this._subs.push(
				back.pubsub().attachEvent("users", u => {
					const { op, user_id, data } = u;
					let user;
					switch (op) {
						case "online": {
							user = this._users.getItem(user_id);
							if (user && user.status != data) {
								this._users.updateItem(user_id, { status: data });
							}
							const chat = this._chats.find(a => a.direct_id == user_id, true);
							if (chat)
								this._chats.updateItem(chat.id, { status: data ? 2 : 1 });
							break;
						}
					}
				})
			);
		}
		return this._users;
	}

	/**
	 * Gets the chats data
	 * @returns {Object|Promise} chats collection directly or inside a promise
	 */
	chats() {
		const state = this.app.getState();
		if (!this._chats) {
			this._chats = new webix.DataCollection({
				scheme: {
					$change: obj => {
						if (obj.date && typeof obj.date === "string")
							obj.date = new Date(obj.date);
						if (obj.direct_id) {
							const user = this.users().getItem(obj.direct_id);
							if (!obj.name) obj.name = "PM: " + user.name;
							if (!obj.avatar) {
								obj.avatar = user.avatar;
							}
							obj.status = user.status;
						}
					},
					$sort: {
						by: "date",
						dir: "desc",
						as: "date",
					},
				},
			});

			const back = this.app.getService("backend");
			Promise.all([back.chats(), this.users().waitData]).then(d => {
				this._chats.parse(d[0]);
			});

			this._subs.push(
				back.pubsub().attachEvent("chats", c => {
					const { op, chat_id, data } = c;
					let update;

					switch (op) {
						case "add":
						case "update":
							// user removed from the chat
							if (data.users.indexOf(this.app.config.user) == -1) {
								if (this._chats.exists(chat_id)) {
									this.app.callEvent("removeChatMember", [chat_id]);
									this._chats.remove(chat_id);
								}

								return;
							}

							// new chat created
							if (!this._chats.exists(chat_id)) this._chats.add(data, 0);
							else {
								// chat updated
								update = {
									name: data.name,
									avatar: data.avatar,
									users: data.users,
								};
								if (!data.direct_id) {
									update.direct_id = 0;
								}

								this._chats.updateItem(chat_id, update);
							}
							break;
						case "message":
							this._chats.updateItem(chat_id, {
								message: data.message,
								message_type: data.message_type,
								date: new Date(data.date),
							});
							break;
					}
				})
			);
			this._subs.push(
				back.pubsub().attachEvent("messages", c => {
					const { op, msg } = c;
					let chat;
					switch (op) {
						case "add":
						case "append": {
							const isAdd = op === "add";
							chat = this._chats.getItem(msg.chat_id);
							const newMsgText = isAdd ? msg.text : chat.text || msg.text;
							if (chat) {
								if (state.chatId != chat.id) {
									this._chats.updateItem(chat.id, {
										message: newMsgText,
										message_type: msg.type,
										unread_count: chat.unread_count + (isAdd ? 1 : 0),
										date: msg.date,
									});
								} else {
									this._chats.updateItem(chat.id, {
										message: newMsgText,
										message_type: msg.type,
										date: msg.date,
									});
									back.resetCounter(chat.id);
								}
								if (this._chats.getIndexById(chat.id))
									this._chats.moveTop(chat.id);
							}
							break;
						}
					}
				})
			);
		}
		return this._chats;
	}

	/**
	 * Gets messages of a specified chat
	 * @param {number} id - chat ID
	 * @returns {Array} an array with message objects
	 */
	messages(id) {
		// ignore cache for now
		// const cache = this._messages[id];
		// if (cache)
		// 	return cache;

		const backs = this.app.getService("backend");
		return (this._messages[id] = backs.messages(id));
	}
}
