import "../../helpers/streamsgrid";

import LivekitClient from "../../libs/livekit";
import BaseCallPanel from "./base";

export default class GroupCallPanel extends BaseCallPanel {
	/**
	 * Returns a call config
	 * @param {Object} users - users collection
	 * @param {Object} chats - chats collection
	 * @returns {Object} configuration for the group call
	 */
	GetCallConfig(users, chats) {
		const _ = this.app.getService("locale")._;
		return [
			{
				localId: "audio",
				css: "webix_chat_audio",
				type: "clean",
				rows: [
					{
						type: "clean",
						batch: "active",
						css: "webix_chat_audio_time",
						cols: [
							{ width: 6 },
							{
								height: 56,
								width: 45,
								css: "webix_chat_header_avatar",
								template: () => {
									const data = this.GetCallInfoFromState(chats);
									const avatar = this.Helpers.avatar(data, "webix_chat_avatar");
									return `<div style="position: relative; top: 6px">${avatar}</div>`;
								},
							},
							{
								localId: "call-timer",
								css: "webix_chat_audio_time",
								height: 56,
								template: () => {
									return `${_("Active call")} (${this.Helpers.normalizeTime(
										this.time
									)})`;
								},
							},
							{
								view: "icon",
								localId: "fullscreen-icon",
								icon: "chi-fullscreen",
								click: () => {
									this.SetFullscreen(!this.fullscreen);
								},
							},
							{ width: 6 },
						],
					},
					{
						gravity: 0.0001,
						css: "webix_chat_audio",
					},
					{
						height: 270,
						css: "webix_chat_audio",
						localId: "callAvatar",
						template: () => {
							const data = this.GetCallInfoFromState(chats);
							const avatar = this.Helpers.avatar(
								data,
								"webix_chat_call_avatar webix_chat_avatar"
							);
							return `<div class="webix_template">${avatar}<p class='webix_chat_call_name'>${data.name}</p></div>`;
						},
					},
					...this.GetStreamsConfig(users),
					...this.GetStatesConfig(),
					{
						gravity: 0.0001,
						css: "webix_chat_audio",
					},
				],
			},
		];
	}

	/**
	 * Initializes call config
	 */
	InitCallConfig() {
		// all audio tracks should be attached to the general audio element
		// so that all users can be heard, even those who are not rendered on the page
		this.callAudio = this.$$("call-audio").$view.querySelector("audio");

		const streamsView = this.$$("streams");
		this.on(streamsView, "onAfterRender", () => {
			const nodes = streamsView.$view.childNodes;
			nodes.length &&
				nodes.forEach(node => {
					const id = parseInt(node.attributes.getNamedItem("webix_l_id").value);
					if (!id) return;
					const mediaData = {
						id: id,
						container: node.querySelector(".webix_chat_group_call_item"),
						video: node.querySelector("video"),
						avatar: node.querySelector(".webix_chat_group_call_avatar"),
						micro: node.querySelector("#micro-indicator"),
					};

					this.Phone &&
						this.Phone.refreshUserMedia(
							id,
							mediaData,
							id === this.app.config.user
						);
				});
		});

		this.onStreamsgridMouseWheel = webix.event(
			streamsView.$view,
			"wheel",
			ev => {
				const pager = this.$$("streamsgridPager");
				if (pager && ev.deltaY != 0)
					pager.select(ev.deltaY < 0 ? "prev" : "next");
			}
		);
	}

	/**
	 * Initializes livekit service
	 */
	InitPhone() {
		const helpers = {
			container: this.app.getRoot().$view,
			locale: this.app.getService("locale")._,
			end: () => this.EndCall(),
		};

		return this.Back.joinToken(this.State.callId).then(joinToken => {
			this.Phone = new LivekitClient(
				this.app.config.user,
				true,
				helpers,
				this.callAudio
			);

			return this.Phone.ready.then(() => {
				const users = this.app.getService("local")._users;
				const streams = this.$$("streams");

				const onUserAdded = ([id]) => {
					if (!id || id == this.app.config.user) return;
					streams.add(users.getItem(id));
					streams.refresh();
				};
				const onUserRemoved = ([id]) => {
					if (!id || this._closed || id == this.app.config.user) return;
					streams.remove(id);
					streams.refresh();
				};
				const onUserMediaEnable = ([id, media, kind, value, local]) => {
					if (!id || !media) return;
					if (kind == "video" && media.video) {
						media.avatar.style.display = value ? "none" : "inline-block";
						media.video.style.display = value ? "block" : "none";
					}

					if (!local && kind == "audio" && media.micro) {
						media.micro.className = "webix_chat_group_call_micro ";
						media.micro.className += value ? "chi-audio" : "chi-audio-off";
					}
				};

				let timeouts = {};
				const onUserSpeakingChanged = ([id, media, v]) => {
					if (!id || !media) return;

					if (timeouts[id]) clearTimeout(timeouts[id]);
					if (v) {
						if (media.container)
							media.container.style.border = "solid 1px #1CA1C1";
						if (media.micro) media.micro.style.color = "#1CA1C1";
					} else {
						timeouts[id] = setTimeout(() => {
							if (media.container)
								media.container.style.border = "solid 1px transparent";
							if (media.micro) media.micro.style.color = "white";
						}, 1000);
					}
				};

				this.Phone.on("onUserAdded", onUserAdded);
				this.Phone.on("onUserRemoved", onUserRemoved);
				this.Phone.on("onUserMediaEnable", onUserMediaEnable);
				this.Phone.on("onUserSpeakingChanged", onUserSpeakingChanged);

				return this.Phone.connect(
					this.app.config.calls.livekitConfig,
					joinToken
				);
			});
		});
	}

	/**
	 * Returns a streams config
	 * @param {Object} users - users collection
	 * @returns {Object} configuration for the group call streams
	 */
	GetStreamsConfig(users) {
		const data = [];
		if (this.isGroupCall) {
			const self = users.getItem(this.app.config.user);
			data.push({
				...self,
				name: `${self.name || ""} (You)`,
			});
		}

		return [
			this.GetStreamsPagerConfig(),
			{
				view: "streamsgrid",
				data: data,
				localId: "streams",
				css: "webix_chat_group_call",
				batch: "active",
				pager: "streamsgridPager",
				type: {
					template: obj => {
						let avatar;
						const st = "webix_chat_avatar webix_chat_group_call_avatar";
						if (obj.avatar || !obj.name) {
							avatar = this.Helpers.avatar(obj, st);
						} else {
							avatar = ` 
							<div class='${st}'>
								<div style="display: flex; align-items: center; height: 100%;">
									<div>${this.Helpers.initials(obj.name)}</div>
								</div>
							</div>`;
						}

						const media = `
						<div class="webix_chat_group_call_media_wrapper">
							<div class="webix_chat_group_call_media_container">
								<video autoplay playsinline class="webix_chat_group_call_video" style="display: none"></video>
								${avatar}
							</div>
						</div>`;

						const micro =
							obj.id != this.app.config.user
								? "<span id='micro-indicator' class='webix_chat_group_call_micro chi-audio-off' style='max-width:38px;'></span>"
								: "";

						return `<div class="webix_chat_group_call_item">${media}<div class='webix_chat_group_call_name'>${obj.name}${micro}</div></div>`;
					},
				},
			},
			{
				batch: "active",
				height: 40,
				localId: "call-audio",
				css: "webix_chat_audio",
				template() {
					return "<audio autoplay></audio>";
				},
			},
		];
	}

	/**
	 * Returns a pager config for the streamsgrid
	 * @returns {Object} configuration for the pager
	 */
	GetStreamsPagerConfig() {
		return {
			batch: "active",
			height: 40,
			css: "webix_chat_group_call_pager",
			cols: [
				{},
				{
					width: 120,
					view: "pager",
					id: "streamsgridPager",
					template: function(data, common) {
						return (
							common.prev(data, common) +
							`<span style="margin: 0 6px">${common.page(data, common)}/${
								data.limit
							}</span>` +
							common.next(data, common)
						);
					},
				},
			],
		};
	}

	/**
	 * Updates call time
	 * @param {number} time - time in seconds
	 */
	UpdateCallTime(time) {
		if (this._closed) return;

		this.time = time;
		if (this.fullscreen)
			this.fullscreen.queryView({ localId: "call-timer" }).refresh();
		else this.$$("call-timer").refresh();
	}

	/**
	 * Returns a call info from the state
	 * @param {Object} chats - chats collection
	 * @returns {Object} call info
	 */
	GetCallInfoFromState(chats) {
		let data = { name: "Unknown group" };
		if (this.State.callName) {
			data = {
				name: this.State.callName,
				avatar: this.State.callAvatar,
			};
		} else if (this.State.callChatId && chats.count()) {
			data = chats.getItem(this.State.callChatId);
		}
		return data;
	}

	destroy() {
		webix.eventRemove(this.onStreamsgridMouseWheel);
		super.destroy();
	}
}
