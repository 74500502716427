export default class Helpers {
	constructor() {
		//status
		this.statusMap = {
			1: "none",
			2: "active",
			3: "busy",
			4: "away",
		};
		// icons
		this.checkedIcon = "<span class='webix_icon wxi-checkbox-marked'></span>";
		this.notCheckedIcon = "<span class='webix_icon wxi-checkbox-blank'></span>";
		//date formats
		this.dateMask = "%d.%m.%Y";
		this.weekMask = "%l";
		this.todayDateMask = "%H:%i";
	}
	/**
	 * Defines an object with user data
	 * @typedef User
	 * @property {string} avatar - URL to user avatar
	 * @property {string} email - user email
	 * @property {number} id - user ID
	 * @property {string} name - user name
	 * @property {number} status - user status (1 - none, 2 - active, 3 - busy, 4 - away)
	 */
	/**
	 * Renders user current status (circle)
	 * @param {User} user - user data
	 * @returns {string} a string with an HTML markup for a status indicator
	 */
	status(user) {
		return (
			"<span class = 'webix_chat_status webix_chat_status_" +
			this.statusMap[user.status] +
			"'></span>"
		);
	}
	/**
	 * Defines an object with chat data
	 * @typedef Chat
	 * @property {string} avatar - URL to the chat avatar
	 * @property {Object} date - date of the latest message on the chat
	 * @property {number} direct_id - ID of the user in a private chat
	 * @property {number} id - chat ID
	 * @property {string} message - latest message of the chat
	 * @property {number} message_type - message type
	 * @property {string} name - chat name
	 * @property {number} status - chat status
	 * @property {number} unread_count - number of messages unread
	 * @property {Array} users - an array with the ID of users involved
	 */
	/**
	 * Renders a list of users/chats avatars on the sidebar
	 * @param {User|Chat} user - user/chat data
	 * @param {string} cssClass - css class to apply to the list
	 * @returns {string} a string with an HTML markup for the list
	 */
	listAvatar(user, cssClass) {
		let css = typeof cssClass == "string" ? cssClass : "webix_chat_list_avatar";
		let html = `<div class='${css}'>`;
		if (user.status) html += this.status(user);
		html += this.avatar(user);
		html += "</div>";
		return html;
	}

	/**
	 *
	 * @param {string} name
	 * @returns
	 */
	initials(name) {
		let initials = name.match(/\b\w/g) || [];
		return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
	}

	/**
	 * Creates a markup for user/chat avatar
	 * @param {User} user - user data
	 * @param {string} cssClass - css class to apply
	 * @returns {string} a string with an HTML markup for a user/chat
	 */
	avatar(user, cssClass) {
		let css = typeof cssClass == "string" ? cssClass : "webix_chat_avatar";
		if (user.avatar) return `<img alt="" class="${css}" src="${user.avatar}"/>`;
		if (!user.name) return `<div class='${css} webix_icon wxi-user'></div>`;
		return `<div class='${css}'>${this.initials(user.name || "")}</div>`;
	}

	/**
	 * Creates a markup for chat info avatar
	 * @param {string} user - URL to the user avatar
	 * @param {string} tooltip - tooltip text (e.g "Avatar", "Change avatar")
	 * @returns {string} a string with an HTML markup for avatar
	 */
	formAvatar(user, tooltip) {
		let css = "webix_chat_avatar";
		if (user.avatar)
			return `<img alt="" title="${tooltip}" class="${css}" src="${user.avatar}"/>`;
		return `<div class='${css}  webix_icon chi-camera' title='${tooltip}'</div>`;
	}

	/**
	 * Formats widget dates
	 * @param {Object} date - JS date
	 * @returns {string} date in the "hh:mm" format (e.g. 15:45)
	 */
	dateChatFormat(date) {
		let mask = this.dateMask;
		const today = webix.Date.datePart(new Date(), true);
		const day = webix.Date.datePart(date, true);
		if (webix.Date.equal(day, today)) mask = this.todayDateMask;
		else if (day > webix.Date.add(today, -7, "day")) mask = this.weekMask;
		return webix.Date.dateToStr(mask)(date);
	}

	/**
	 * Creates a list with chat users
	 * @param {Array<User>} users - an array with user objects
	 * @param {boolean} deleteIcon - defines whether to show an icon for deletion
	 * @param {Array} groupUsers - an array with IDs of the users involved
	 * @returns {string} a string with an HTML markup for people list
	 */
	peopleList(users, deleteIcon, groupUsers) {
		let list = "<ul class='webix_chat_peoplelist'>";
		users.forEach(user => {
			list +=
				`<li class='webix_chat_peoplelist_item' data-id='${user.id}'>` +
				"<div class='webix_chat_peoplelist_avatar'>" +
				this.avatar(user) +
				(groupUsers && groupUsers.indexOf(user.id) >= 0
					? "<div class='webix_chat_group_user_marker'><span class='webix_icon wxi-check'></span></div>"
					: "") +
				(deleteIcon
					? "<div class='webix_chat_peoplelist_delete_icon'><span class='webix_icon wxi-close'></span></div>"
					: "") +
				"</div>" +
				`<p class="webix_chat_peoplelist_item_name">${user.name}</p></li>`;
		});
		list += "</ul>";
		return list;
	}

	/**
	 * Adds a mark for text occurrence in a value
	 * @param {string} value - matched value
	 * @param {string} text - entered text
	 * @returns {string} a string with an HTML markup for highlighted parts of the text
	 */
	addTextMark(value, text) {
		if (!text) return value;

		return value.replace(
			new RegExp("(" + text + ")", "ig"),
			"<span class='webix_chat_search_mark'>$1</span>"
		);
	}

	/**
	 * Converts every 60s second into a minute (e.g. 62 -> 1:02)
	 * @param {number} seconds - number of seconds
	 * @returns {string} formatted string with minutes and seconds
	 */
	normalizeTime(seconds) {
		seconds = seconds || 0;
		const min = Math.floor(seconds / 60);
		const sec = seconds - min * 60;
		return (min < 10 ? "0" + min : min) + ":" + (sec < 10 ? "0" + sec : sec);
	}
}
