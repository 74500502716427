import { JetView } from "webix-jet";

export default class FormView extends JetView {
	config() {
		const _ = this.app.getService("locale")._;
		const helpers = this.app.getService("helpers");

		const avatarInput = {
			view: "forminput",
			name: "avatar",
			body: {
				localId: "avatar",
				css: "webix_chat_form_avatar",
				width: 130,
				height: 130,
				borderless: true,
				template: data => {
					return helpers.formAvatar(data, _("Change avatar"));
				},
				onClick: {
					webix_chat_avatar: () => {
						let upload = this.app.getService("upload");
						upload.fileDialog();
					},
				},
			},
		};

		const nameInput = {
			name: "name",
			localId: "name",
			inputAlign: "center",
			view: "text",
			width: 300,
			placeholder: _("Chat name"),
			on: {
				onChange: v => {
					this.NameChangedHandler(v);
				},
				onTimedKeyPress: () => {
					this.NameChangedHandler(this.$$("name").getValue());
				},
			},
		};

		const form = {
			padding: 20,
			margin: 20,
			elementsConfig: {
				labelWidth: 130,
				labelAlign: "right",
			},
			view: "form",
			localId: "form",
			css: "webix_chat_newchat_form",
			elements: [
				{ align: "center", body: avatarInput },
				{ align: "center", body: nameInput },
				{},
			],
		};

		return form;
	}

	init() {
		const { name, avatar, chat } = this.getParam("state", true);
		this.$$("form").setValues({ name, avatar: { avatar } });

		// uploader events
		const upload = this.app.getService("upload").getUploader(chat);
		this.on(upload, "onAfterFileAdd", data => {
			this.UpdateAvatar(data);
		});
		this.on(upload, "onUploadComplete", data => {
			this.UpdateAvatar(data);
		});
	}

	ready() {
		webix.delay(() => {
			this.$$("name").focus();
		});
	}

	/**
	 * Updates chat avatar with a specified picture
	 * @param {Object} data - File interface for the uploaded picture
	 */
	UpdateAvatar(data) {
		if (data.status === "client" && data.file) {
			// client-side preview
			const reader = new FileReader();
			reader.onload = () => {
				this.SetLocalAvatar(reader.result);
			};
			reader.readAsDataURL(data.file);
		} else if (data.status === "server") {
			// refresh picture after uploading, update data in form
			const avatar = data.value;
			this.$$("form").setValues({ avatar }, true);
			this.AvatarChangedHandler(avatar);
			this.SetLocalAvatar(avatar);
		}
	}

	/**
	 * Sets avatar from a local path
	 * @param {string} avatar - path to a local picture
	 */
	SetLocalAvatar(avatar) {
		this.$$("avatar").setValues({ avatar });
	}

	/**
	 * Sets initial values for the chat name and avatar
	 */
	SetInitValues() {
		let state = this.getParam("state", true);
		const { name, avatar } = state.values.form;
		if (name || avatar) {
			this.$$("form").setValues({ name, avatar: { avatar } });
		}
	}

	NameChangedHandler() {}

	AvatarChangedHandler() {}
}
