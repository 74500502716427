import BaseWindow from "../common/window";

export default class NewChatWin extends BaseWindow {
	stages() {
		const _ = this.app.getService("locale")._;
		return [
			null,
			[
				"./chat.info.info",
				{
					label: () => this.getParam("state").name,
					cols: ["close", "label", "edit"],
				},
			],
			[
				"./chat.info.form",
				{
					label: `<span class="webix_chat_wizard_title2">${_(
						"Edit chat"
					)}</span>`,
					cols: ["back", "label", "save"],
				},
			],
			[
				"./chat.info.people",
				{
					label: `<span class="webix_chat_wizard_title2">${_(
						"Add members"
					)}</span>`,
					cols: ["start", "label", "save"],
				},
			],
		];
	}
	init() {
		super.init();

		this.on(this.app, "leaveChatClick", () => {
			this.LeaveChat();
		});
	}

	/**
	 * Leaves the current chat
	 */
	LeaveChat() {
		const _ = this.app.getService("locale")._;
		webix
			.confirm({
				container: this.app.getRoot().$view,
				title: _("Leave chat"),
				ok: _("Leave"),
				cancel: _("Cancel"),
				text: _("Are you sure to leave this chat?"),
			})
			.then(() => {
				const ops = this.app.getService("operations");
				ops.leaveChat(this.getParam("state").chat);
				this.Close();
			});
	}
}
