import { JetView } from "webix-jet";

export default class EmojiSuggest extends JetView {
	constructor(app, master) {
		super(app);
		this.Master = master;
	}
	config() {
		this.Emojis = this.app.getService("emojis");
		const symbol = this.Emojis.symbol;
		return {
			view: "mentionsuggest",
			symbol: symbol,
			maxHeight: 200,
			css: "webix_chat_emojisuggest",
			template: obj => this.SuggestTemplate(obj),
			filter: data => {
				if (this.FilterText) {
					const text = this.getRoot().getItemText(data.id);
					return (
						text &&
						text.toLowerCase().indexOf(this.FilterText.toLowerCase()) > -1
					);
				}
				return false;
			},
			body: {
				template: obj => this.ItemTemplate(obj),
				data: this.Emojis.emojis(),
			},
		};
	}
	init() {
		this.Text = this.Master.queryView({ name: "text" });
		// customise setValueHere to use a white space in the end of the SuggestTemplate
		// ("mentions" enabled case)
		this.Text.setValueHere = function(value, data, details) {
			if (value.indexOf(" ") != -1 && value.indexOf(" ") != value.length - 1)
				value = `"${value}"`;
			return webix.ui.text.prototype.setValueHere.apply(this, [
				value,
				data,
				details,
			]);
		};
		this.Popup = this.getRoot();

		this.on(this.Popup.getList().data, "onBeforeFilter", () =>
			this.BeforeFilterHandler()
		);
		this._keyEvent = webix.event(
			this.Text.$view,
			"keydown",
			e => {
				this.KeyCode = e.keyCode;
			},
			{ capture: true }
		);
		// used to restore a cursor position when an option is selected
		this.on(this.Popup, "onValueSuggest", (data, text) => {
			webix.delay(() => {
				this.Master.focus();
				const inp = this.Text.getInputNode();
				const len = text.length - this.FilterText.length;
				inp.setSelectionRange(
					this._lastCursorPosition + len,
					this._lastCursorPosition + len
				);
			});
		});
		this.on(this.Popup, "onShow", () => this.ShowHandler());
	}

	/**
	 * Selects the first emoji in the suggest
	 */
	ShowHandler() {
		const list = this.Popup.getList();
		list.select(list.getFirstId());
	}

	/**
	 * Links input with suggest list
	 */
	LinkInput() {
		this.on(this.Text, "onAfterRender", () => {
			this.Popup.linkInput(this.Text);
		});
	}

	/**
	 * Sets properties and performs operations before filtering is fired.
	 */
	BeforeFilterHandler() {
		this._lastCursorPosition = webix.html.getSelectionRange(
			this.Text.getInputNode()
		).start;

		const code = this.KeyCode;
		// do not show suggest on "left" / "right" key press
		if ((code == 37 || code == 39) && !this.Popup.isVisible()) return false;
		this.FilterText = null;
		let value = this.Text.getValue();
		const symbol = this.Emojis.symbol;
		value = value.substring(0, this._lastCursorPosition);
		if (value.indexOf(symbol) === -1) return false;
		else {
			value = value
				.toLowerCase()
				.substring(value.lastIndexOf(symbol) + symbol.length);
			if (
				value.length &&
				value.match("^[A-Za-z0-9-+]+$") &&
				!value.indexOf(this.LastFilterFail) > -1
			) {
				let result = this.Emojis.emojis().data.find(emoji => {
					return emoji.names.some(n => n.toLowerCase().indexOf(value) != -1);
				}, true);
				if (result) this.FilterText = value;
				else {
					this.FilterText = null;
					this.LastFilterFail = value;
				}
				return this.FilterText;
			}
			if (this.Popup.isVisible()) this.Popup.hide();
			return false;
		}
	}

	/**
	 * Gets text for the textarea when an option is selected
	 * @param  {Object} obj - emoji data item
	 * @returns {string} text to display in message input
	 */
	SuggestTemplate(obj) {
		const text = this.Emojis.findName(obj, this.FilterText);
		return text ? text + ": " : null;
	}

	/**
	 * An option template
	 * @param {Object} obj - emoji data item
	 * @returns {string} an HTML template for the suggest option
	 */
	ItemTemplate(obj) {
		let name = this.Emojis.findName(obj, this.FilterText);
		if (!name) return "";
		name = this.Emojis.shortNameToTitle(name);
		return (
			"<div class='webix_chat_emoji_preview'>" +
			"<div class='webix_chat_emoji webix_chat_emoji_" +
			obj.unified +
			"'></div>" +
			"<div class='webix_chat_emoji_name'>" +
			name +
			"</div></div>"
		);
	}
	destroy() {
		webix.eventRemove(this._keyEvent);
	}
}
