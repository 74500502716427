export default {
	"Add members": "Add members",
	"Are you sure to leave this chat?": "Are you sure to leave this chat?",
	Avatar: "Avatar",
	"Are you sure to delete this member?": "Are you sure to delete this member?",
	Cancel: "Cancel",
	"Change avatar": "Change avatar",
	"Chat info": "Chat info",
	"Chat name": "Chat name",
	Chats: "Chats",
	"Create group": "Create group",
	Delete: "Delete",
	"Delete member": "Delete member",
	"Edit chat": "Edit chat",
	Leave: "Leave",
	"Leave chat": "Leave chat",
	member: "member",
	members: "members",
	Members: "Members",
	"New chat": "New chat",
	"No people to add": "No people to add",
	People: "People",
	Save: "Save",
	Search: "Search",
	"Show all members": "Show all members",
	Users: "Users",
	You: "You",
	Ringing: "Ringing...",
	"Active call": "Active call",
	"Is calling you": "Is calling you...",
	Accept: "Accept",
	Reject: "Reject",
	"Start call": "Start call",
	"Join call": "Join call",
	"End call": "End call",
	"Ended call": "Ended call",
	"Incoming call": "Incoming call",
	"Outgoing call": "Outgoing call",
	"Rejected call": "Rejected call",
	"Missed call": "Missed call",
	"Can't start the call": "Can't start the call",
	"Could not find your": "Could not find your",
	"Error opening your": "Error opening your",
	microphone: "microphone",
	camera: "camera",
	Size: "Size",
	B: "B",
	KB: "KB",
	MB: "MB",
	GB: "GB",
	"File size exceeds the limit": "File size exceeds the limit",
	"File upload error": "File upload error",
	"Smileys & People": "Smileys & People",
	"Animals & Nature": "Animals & Nature",
	Activity: "Activity",
	"Travel & Places": "Travel & Places",
	"Food & Drink": "Food & Drink",
	Objects: "Objects",
	"Search results": "Search results",
	Symbols: "Symbols",
	Basic: "Basic",
	edited: "edited",
	Connecting: "Connecting",
	"Line is busy": "Line is busy",
	"You have been removed from the group":
		"You have been removed from the group",
	"Already in the call": "Already in the call",
	"Call is active in another chat": "Call is active in another chat",
	"Internal call error": "Internal call error",
	"Voice message": "Voice message",
	"typing...": "typing...",
};
