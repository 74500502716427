export const categories = {
	"1": "People",
	"2": "Nature",
	"3": "Activity",
	"4": "Places",
	"5": "Foods",
	"6": "Objects",
	"7": "Symbols",
};
export const emojis = [
	[["watch"], "231A", 6],
	[["soccer"], "26BD", 3],
	[["heart"], "2764", 7],
	[["green_apple"], "1F34F", 5],
	[["dog"], "1F436", 2],
	[["grinning"], "1F600", 1],
	[["car", "red_car"], "1F697", 4],
	[["apple"], "1F34E", 5],
	[["basketball"], "1F3C0", 3],
	[["cat"], "1F431", 2],
	[["yellow_heart"], "1F49B", 7],
	[["iphone"], "1F4F1", 6],
	[["smiley"], "1F603", 1],
	[["taxi"], "1F695", 4],
	[["pear"], "1F350", 5],
	[["football"], "1F3C8", 3],
	[["mouse"], "1F42D", 2],
	[["green_heart"], "1F49A", 7],
	[["calling"], "1F4F2", 6],
	[["smile"], "1F604", 1],
	[["blue_car"], "1F699", 4],
	[["baseball"], "26BE", 3],
	[["tangerine"], "1F34A", 5],
	[["hamster"], "1F439", 2],
	[["blue_heart"], "1F499", 7],
	[["computer"], "1F4BB", 6],
	[["grin"], "1F601", 1],
	[["bus"], "1F68C", 4],
	[["keyboard"], "2328", 6],
	[["lemon"], "1F34B", 5],
	[["tennis"], "1F3BE", 3],
	[["rabbit"], "1F430", 2],
	[["purple_heart"], "1F49C", 7],
	[["laughing", "satisfied"], "1F606", 1],
	[["trolleybus"], "1F68E", 4],
	[["banana"], "1F34C", 5],
	[["racing_car"], "1F3CE", 4],
	[["volleyball"], "1F3D0", 3],
	[["black_heart"], "1F5A4", 7],
	[["desktop_computer"], "1F5A5", 6],
	[["sweat_smile"], "1F605", 1],
	[["fox_face"], "1F98A", 2],
	[["watermelon"], "1F349", 5],
	[["rugby_football"], "1F3C9", 3],
	[["bear"], "1F43B", 2],
	[["broken_heart"], "1F494", 7],
	[["printer"], "1F5A8", 6],
	[["joy"], "1F602", 1],
	[["police_car"], "1F693", 4],
	[["heavy_heart_exclamation_mark_ornament"], "2763", 7],
	[["grapes"], "1F347", 5],
	[["8ball"], "1F3B1", 3],
	[["panda_face"], "1F43C", 2],
	[["three_button_mouse"], "1F5B1", 6],
	[["ambulance"], "1F691", 4],
	[["rolling_on_the_floor_laughing"], "1F923", 1],
	[["relaxed"], "263A", 1],
	[["strawberry"], "1F353", 5],
	[["table_tennis_paddle_and_ball"], "1F3D3", 3],
	[["koala"], "1F428", 2],
	[["two_hearts"], "1F495", 7],
	[["trackball"], "1F5B2", 6],
	[["fire_engine"], "1F692", 4],
	[["melon"], "1F348", 5],
	[["badminton_racquet_and_shuttlecock"], "1F3F8", 3],
	[["tiger"], "1F42F", 2],
	[["revolving_hearts"], "1F49E", 7],
	[["joystick"], "1F579", 6],
	[["blush"], "1F60A", 1],
	[["minibus"], "1F690", 4],
	[["cherries"], "1F352", 5],
	[["heartbeat"], "1F493", 7],
	[["compression"], "1F5DC", 6],
	[["innocent"], "1F607", 1],
	[["truck"], "1F69A", 4],
	[["goal_net"], "1F945", 3],
	[["lion_face"], "1F981", 2],
	[["peach"], "1F351", 5],
	[["ice_hockey_stick_and_puck"], "1F3D2", 3],
	[["cow"], "1F42E", 2],
	[["heartpulse"], "1F497", 7],
	[["minidisc"], "1F4BD", 6],
	[["slightly_smiling_face"], "1F642", 1],
	[["articulated_lorry"], "1F69B", 4],
	[["pineapple"], "1F34D", 5],
	[["field_hockey_stick_and_ball"], "1F3D1", 3],
	[["pig"], "1F437", 2],
	[["sparkling_heart"], "1F496", 7],
	[["floppy_disk"], "1F4BE", 6],
	[["upside_down_face"], "1F643", 1],
	[["tractor"], "1F69C", 4],
	[["cricket_bat_and_ball"], "1F3CF", 3],
	[["pig_nose"], "1F43D", 2],
	[["cupid"], "1F498", 7],
	[["cd"], "1F4BF", 6],
	[["wink"], "1F609", 1],
	[["scooter"], "1F6F4", 4],
	[["kiwifruit"], "1F95D", 5],
	[["golf"], "26F3", 3],
	[["frog"], "1F438", 2],
	[["gift_heart"], "1F49D", 7],
	[["dvd"], "1F4C0", 6],
	[["relieved"], "1F60C", 1],
	[["bike"], "1F6B2", 4],
	[["avocado"], "1F951", 5],
	[["tomato"], "1F345", 5],
	[["bow_and_arrow"], "1F3F9", 3],
	[["monkey_face"], "1F435", 2],
	[["heart_decoration"], "1F49F", 7],
	[["vhs"], "1F4FC", 6],
	[["heart_eyes"], "1F60D", 1],
	[["motor_scooter"], "1F6F5", 4],
	[["eggplant"], "1F346", 5],
	[["fishing_pole_and_fish"], "1F3A3", 3],
	[["racing_motorcycle"], "1F3CD", 4],
	[["camera"], "1F4F7", 6],
	[["kissing_heart"], "1F618", 1],
	[["see_no_evil"], "1F648", 2],
	[["camera_with_flash"], "1F4F8", 6],
	[["kissing"], "1F617", 1],
	[["hear_no_evil"], "1F649", 2],
	[["rotating_light"], "1F6A8", 4],
	[["boxing_glove"], "1F94A", 3],
	[["cucumber"], "1F952", 5],
	[["video_camera"], "1F4F9", 6],
	[["kissing_smiling_eyes"], "1F619", 1],
	[["speak_no_evil"], "1F64A", 2],
	[["oncoming_police_car"], "1F694", 4],
	[["martial_arts_uniform"], "1F94B", 3],
	[["carrot"], "1F955", 5],
	[["ice_skate"], "26F8", 3],
	[["corn"], "1F33D", 5],
	[["movie_camera"], "1F3A5", 6],
	[["monkey"], "1F412", 2],
	[["kissing_closed_eyes"], "1F61A", 1],
	[["oncoming_bus"], "1F68D", 4],
	[["hot_pepper"], "1F336", 5],
	[["ski"], "1F3BF", 3],
	[["chicken"], "1F414", 2],
	[["film_projector"], "1F4FD", 6],
	[["yum"], "1F60B", 1],
	[["oncoming_automobile"], "1F698", 4],
	[["skier"], "26F7", 3],
	[["film_frames"], "1F39E", 6],
	[["penguin"], "1F427", 2],
	[["stuck_out_tongue_winking_eye"], "1F61C", 1],
	[["oncoming_taxi"], "1F696", 4],
	[["potato"], "1F954", 5],
	[["sweet_potato"], "1F360", 5],
	[["snowboarder"], "1F3C2", 3],
	[["bird"], "1F426", 2],
	[["telephone_receiver"], "1F4DE", 6],
	[["stuck_out_tongue_closed_eyes"], "1F61D", 1],
	[["aerial_tramway"], "1F6A1", 4],
	[["phone", "telephone"], "260E", 6],
	[["chestnut"], "1F330", 5],
	[["baby_chick"], "1F424", 2],
	[["stuck_out_tongue"], "1F61B", 1],
	[["mountain_cableway"], "1F6A0", 4],
	[["weight_lifter"], "1F3CB", 3],
	[["hatching_chick"], "1F423", 2],
	[["pager"], "1F4DF", 6],
	[["suspension_railway"], "1F69F", 4],
	[["money_mouth_face"], "1F911", 1],
	[["peanuts"], "1F95C", 5],
	[["honey_pot"], "1F36F", 5],
	[["hatched_chick"], "1F425", 2],
	[["fax"], "1F4E0", 6],
	[["railway_car"], "1F683", 4],
	[["hugging_face"], "1F917", 1],
	[["fencer"], "1F93A", 3],
	[["tv"], "1F4FA", 6],
	[["train"], "1F68B", 4],
	[["nerd_face"], "1F913", 1],
	[["wrestlers"], "1F93C", 3],
	[["croissant"], "1F950", 5],
	[["duck"], "1F986", 2],
	[["bread"], "1F35E", 5],
	[["radio"], "1F4FB", 6],
	[["sunglasses"], "1F60E", 1],
	[["mountain_railway"], "1F69E", 4],
	[["eagle"], "1F985", 2],
	[["studio_microphone"], "1F399", 6],
	[["monorail"], "1F69D", 4],
	[["clown_face"], "1F921", 1],
	[["baguette_bread"], "1F956", 5],
	[["owl"], "1F989", 2],
	[["level_slider"], "1F39A", 6],
	[["bullettrain_side"], "1F684", 4],
	[["face_with_cowboy_hat"], "1F920", 1],
	[["person_doing_cartwheel"], "1F938", 3],
	[["bat"], "1F987", 2],
	[["cheese_wedge"], "1F9C0", 5],
	[["control_knobs"], "1F39B", 6],
	[["wolf"], "1F43A", 2],
	[["smirk"], "1F60F", 1],
	[["bullettrain_front"], "1F685", 4],
	[["egg"], "1F95A", 5],
	[["stopwatch"], "23F1", 6],
	[["fried_egg", "cooking"], "1F373", 5],
	[["boar"], "1F417", 2],
	[["unamused"], "1F612", 1],
	[["light_rail"], "1F688", 4],
	[["timer_clock"], "23F2", 6],
	[["horse"], "1F434", 2],
	[["disappointed"], "1F61E", 1],
	[["steam_locomotive"], "1F682", 4],
	[["bacon"], "1F953", 5],
	[["alarm_clock"], "23F0", 6],
	[["person_with_ball"], "26F9", 3],
	[["pensive"], "1F614", 1],
	[["train2"], "1F686", 4],
	[["pancakes"], "1F95E", 5],
	[["unicorn_face"], "1F984", 2],
	[["fried_shrimp"], "1F364", 5],
	[["bee", "honeybee"], "1F41D", 2],
	[["mantelpiece_clock"], "1F570", 6],
	[["worried"], "1F61F", 1],
	[["metro"], "1F687", 4],
	[["handball"], "1F93E", 3],
	[["hourglass"], "231B", 6],
	[["poultry_leg"], "1F357", 5],
	[["bug"], "1F41B", 2],
	[["confused"], "1F615", 1],
	[["tram"], "1F68A", 4],
	[["hourglass_flowing_sand"], "23F3", 6],
	[["meat_on_bone"], "1F356", 5],
	[["slightly_frowning_face"], "1F641", 1],
	[["station"], "1F689", 4],
	[["butterfly"], "1F98B", 2],
	[["white_frowning_face"], "2639", 1],
	[["pizza"], "1F355", 5],
	[["snail"], "1F40C", 2],
	[["satellite_antenna"], "1F4E1", 6],
	[["helicopter"], "1F681", 4],
	[["hotdog"], "1F32D", 5],
	[["golfer"], "1F3CC", 3],
	[["shell"], "1F41A", 2],
	[["battery"], "1F50B", 6],
	[["persevere"], "1F623", 1],
	[["small_airplane"], "1F6E9", 4],
	[["airplane"], "2708", 4],
	[["hamburger"], "1F354", 5],
	[["beetle"], "1F41E", 2],
	[["electric_plug"], "1F50C", 6],
	[["confounded"], "1F616", 1],
	[["fries"], "1F35F", 5],
	[["surfer"], "1F3C4", 3],
	[["ant"], "1F41C", 2],
	[["bulb"], "1F4A1", 6],
	[["tired_face"], "1F62B", 1],
	[["airplane_departure"], "1F6EB", 4],
	[["flashlight"], "1F526", 6],
	[["spider"], "1F577", 2],
	[["weary"], "1F629", 1],
	[["airplane_arriving"], "1F6EC", 4],
	[["stuffed_flatbread"], "1F959", 5],
	[["taco"], "1F32E", 5],
	[["swimmer"], "1F3CA", 3],
	[["candle"], "1F56F", 6],
	[["spider_web"], "1F578", 2],
	[["triumph"], "1F624", 1],
	[["rocket"], "1F680", 4],
	[["burrito"], "1F32F", 5],
	[["turtle"], "1F422", 2],
	[["wastebasket"], "1F5D1", 6],
	[["angry"], "1F620", 1],
	[["satellite"], "1F6F0", 4],
	[["water_polo"], "1F93D", 3],
	[["snake"], "1F40D", 2],
	[["seat"], "1F4BA", 4],
	[["rage"], "1F621", 1],
	[["oil_drum"], "1F6E2", 6],
	[["green_salad"], "1F957", 5],
	[["money_with_wings"], "1F4B8", 6],
	[["no_mouth"], "1F636", 1],
	[["canoe"], "1F6F6", 4],
	[["shallow_pan_of_food"], "1F958", 5],
	[["lizard"], "1F98E", 2],
	[["boat", "sailboat"], "26F5", 4],
	[["spaghetti"], "1F35D", 5],
	[["dollar"], "1F4B5", 6],
	[["neutral_face"], "1F610", 1],
	[["scorpion"], "1F982", 2],
	[["ramen"], "1F35C", 5],
	[["yen"], "1F4B4", 6],
	[["expressionless"], "1F611", 1],
	[["rowboat"], "1F6A3", 3],
	[["motor_boat"], "1F6E5", 4],
	[["crab"], "1F980", 2],
	[["stew"], "1F372", 5],
	[["horse_racing"], "1F3C7", 3],
	[["euro"], "1F4B6", 6],
	[["hushed"], "1F62F", 1],
	[["speedboat"], "1F6A4", 4],
	[["squid"], "1F991", 2],
	[["fish_cake"], "1F365", 5],
	[["octopus"], "1F419", 2],
	[["pound"], "1F4B7", 6],
	[["frowning"], "1F626", 1],
	[["passenger_ship"], "1F6F3", 4],
	[["ferry"], "26F4", 4],
	[["sushi"], "1F363", 5],
	[["moneybag"], "1F4B0", 6],
	[["anguished"], "1F627", 1],
	[["bicyclist"], "1F6B4", 3],
	[["shrimp"], "1F990", 2],
	[["bento"], "1F371", 5],
	[["tropical_fish"], "1F420", 2],
	[["credit_card"], "1F4B3", 6],
	[["open_mouth"], "1F62E", 1],
	[["ship"], "1F6A2", 4],
	[["anchor"], "2693", 4],
	[["curry"], "1F35B", 5],
	[["fish"], "1F41F", 2],
	[["gem"], "1F48E", 6],
	[["astonished"], "1F632", 1],
	[["mountain_bicyclist"], "1F6B5", 3],
	[["scales"], "2696", 6],
	[["rice"], "1F35A", 5],
	[["running_shirt_with_sash"], "1F3BD", 3],
	[["blowfish"], "1F421", 2],
	[["dizzy_face"], "1F635", 1],
	[["construction"], "1F6A7", 4],
	[["fuelpump"], "26FD", 4],
	[["rice_ball"], "1F359", 5],
	[["sports_medal"], "1F3C5", 3],
	[["dolphin", "flipper"], "1F42C", 2],
	[["wrench"], "1F527", 6],
	[["flushed"], "1F633", 1],
	[["rice_cracker"], "1F358", 5],
	[["medal"], "1F396", 3],
	[["hammer"], "1F528", 6],
	[["scream"], "1F631", 1],
	[["busstop"], "1F68F", 4],
	[["shark"], "1F988", 2],
	[["hammer_and_pick"], "2692", 6],
	[["oden"], "1F362", 5],
	[["whale"], "1F433", 2],
	[["fearful"], "1F628", 1],
	[["vertical_traffic_light"], "1F6A6", 4],
	[["first_place_medal"], "1F947", 3],
	[["dango"], "1F361", 5],
	[["whale2"], "1F40B", 2],
	[["cold_sweat"], "1F630", 1],
	[["traffic_light"], "1F6A5", 4],
	[["hammer_and_wrench"], "1F6E0", 6],
	[["second_place_medal"], "1F948", 3],
	[["pick"], "26CF", 6],
	[["shaved_ice"], "1F367", 5],
	[["crocodile"], "1F40A", 2],
	[["world_map"], "1F5FA", 4],
	[["cry"], "1F622", 1],
	[["third_place_medal"], "1F949", 3],
	[["ice_cream"], "1F368", 5],
	[["trophy"], "1F3C6", 3],
	[["leopard"], "1F406", 2],
	[["nut_and_bolt"], "1F529", 6],
	[["moyai"], "1F5FF", 4],
	[["disappointed_relieved"], "1F625", 1],
	[["gear"], "2699", 6],
	[["icecream"], "1F366", 5],
	[["rosette"], "1F3F5", 3],
	[["tiger2"], "1F405", 2],
	[["statue_of_liberty"], "1F5FD", 4],
	[["drooling_face"], "1F924", 1],
	[["chains"], "26D3", 6],
	[["fountain"], "26F2", 4],
	[["cake"], "1F370", 5],
	[["reminder_ribbon"], "1F397", 3],
	[["water_buffalo"], "1F403", 2],
	[["sob"], "1F62D", 1],
	[["birthday"], "1F382", 5],
	[["ticket"], "1F3AB", 3],
	[["ox"], "1F402", 2],
	[["gun"], "1F52B", 6],
	[["tokyo_tower"], "1F5FC", 4],
	[["sweat"], "1F613", 1],
	[["custard"], "1F36E", 5],
	[["admission_tickets"], "1F39F", 3],
	[["european_castle"], "1F3F0", 4],
	[["cow2"], "1F404", 2],
	[["bomb"], "1F4A3", 6],
	[["sleepy"], "1F62A", 1],
	[["lollipop"], "1F36D", 5],
	[["circus_tent"], "1F3AA", 3],
	[["japanese_castle"], "1F3EF", 4],
	[["hocho", "knife"], "1F52A", 6],
	[["sleeping"], "1F634", 1],
	[["deer"], "1F98C", 2],
	[["candy"], "1F36C", 5],
	[["stadium"], "1F3DF", 4],
	[["dromedary_camel"], "1F42A", 2],
	[["dagger_knife"], "1F5E1", 6],
	[["face_with_rolling_eyes"], "1F644", 1],
	[["juggling"], "1F939", 3],
	[["crossed_swords"], "2694", 6],
	[["chocolate_bar"], "1F36B", 5],
	[["ferris_wheel"], "1F3A1", 4],
	[["camel"], "1F42B", 2],
	[["thinking_face"], "1F914", 1],
	[["popcorn"], "1F37F", 5],
	[["roller_coaster"], "1F3A2", 4],
	[["elephant"], "1F418", 2],
	[["shield"], "1F6E1", 6],
	[["lying_face"], "1F925", 1],
	[["doughnut"], "1F369", 5],
	[["carousel_horse"], "1F3A0", 4],
	[["performing_arts"], "1F3AD", 3],
	[["grimacing"], "1F62C", 1],
	[["smoking"], "1F6AC", 6],
	[["rhinoceros"], "1F98F", 2],
	[["coffin"], "26B0", 6],
	[["umbrella_on_ground"], "26F1", 4],
	[["cookie"], "1F36A", 5],
	[["art"], "1F3A8", 3],
	[["zipper_mouth_face"], "1F910", 1],
	[["gorilla"], "1F98D", 2],
	[["funeral_urn"], "26B1", 6],
	[["clapper"], "1F3AC", 3],
	[["beach_with_umbrella"], "1F3D6", 4],
	[["racehorse"], "1F40E", 2],
	[["nauseated_face"], "1F922", 1],
	[["glass_of_milk"], "1F95B", 5],
	[["baby_bottle"], "1F37C", 5],
	[["microphone"], "1F3A4", 3],
	[["desert_island"], "1F3DD", 4],
	[["amphora"], "1F3FA", 6],
	[["pig2"], "1F416", 2],
	[["sneezing_face"], "1F927", 1],
	[["coffee"], "2615", 5],
	[["mountain"], "26F0", 4],
	[["headphones"], "1F3A7", 3],
	[["goat"], "1F410", 2],
	[["crystal_ball"], "1F52E", 6],
	[["mask"], "1F637", 1],
	[["tea"], "1F375", 5],
	[["musical_score"], "1F3BC", 3],
	[["snow_capped_mountain"], "1F3D4", 4],
	[["ram"], "1F40F", 2],
	[["prayer_beads"], "1F4FF", 6],
	[["face_with_thermometer"], "1F912", 1],
	[["sake"], "1F376", 5],
	[["musical_keyboard"], "1F3B9", 3],
	[["sheep"], "1F411", 2],
	[["barber"], "1F488", 6],
	[["mount_fuji"], "1F5FB", 4],
	[["face_with_head_bandage"], "1F915", 1],
	[["alembic"], "2697", 6],
	[["volcano"], "1F30B", 4],
	[["beer"], "1F37A", 5],
	[["dog2"], "1F415", 2],
	[["smiling_imp"], "1F608", 1],
	[["drum_with_drumsticks"], "1F941", 3],
	[["beers"], "1F37B", 5],
	[["saxophone"], "1F3B7", 3],
	[["desert"], "1F3DC", 4],
	[["poodle"], "1F429", 2],
	[["imp"], "1F47F", 1],
	[["telescope"], "1F52D", 6],
	[["trumpet"], "1F3BA", 3],
	[["camping"], "1F3D5", 4],
	[["cat2"], "1F408", 2],
	[["japanese_ogre"], "1F479", 1],
	[["microscope"], "1F52C", 6],
	[["clinking_glasses"], "1F942", 5],
	[["tent"], "26FA", 4],
	[["wine_glass"], "1F377", 5],
	[["guitar"], "1F3B8", 3],
	[["rooster"], "1F413", 2],
	[["japanese_goblin"], "1F47A", 1],
	[["hole"], "1F573", 6],
	[["violin"], "1F3BB", 3],
	[["pill"], "1F48A", 6],
	[["hankey", "poop", "shit"], "1F4A9", 1],
	[["railway_track"], "1F6E4", 4],
	[["tumbler_glass"], "1F943", 5],
	[["turkey"], "1F983", 2],
	[["cocktail"], "1F378", 5],
	[["game_die"], "1F3B2", 3],
	[["ghost"], "1F47B", 1],
	[["syringe"], "1F489", 6],
	[["dove_of_peace"], "1F54A", 2],
	[["motorway"], "1F6E3", 4],
	[["thermometer"], "1F321", 6],
	[["tropical_drink"], "1F379", 5],
	[["dart"], "1F3AF", 3],
	[["building_construction"], "1F3D7", 4],
	[["rabbit2"], "1F407", 2],
	[["skull"], "1F480", 1],
	[["skull_and_crossbones"], "2620", 1],
	[["champagne"], "1F37E", 5],
	[["bowling"], "1F3B3", 3],
	[["factory"], "1F3ED", 4],
	[["mouse2"], "1F401", 2],
	[["toilet"], "1F6BD", 6],
	[["video_game"], "1F3AE", 3],
	[["house"], "1F3E0", 4],
	[["rat"], "1F400", 2],
	[["alien"], "1F47D", 1],
	[["potable_water"], "1F6B0", 6],
	[["spoon"], "1F944", 5],
	[["fork_and_knife"], "1F374", 5],
	[["slot_machine"], "1F3B0", 3],
	[["house_with_garden"], "1F3E1", 4],
	[["chipmunk"], "1F43F", 2],
	[["space_invader"], "1F47E", 1],
	[["shower"], "1F6BF", 6],
	[["knife_fork_plate"], "1F37D", 5],
	[["house_buildings"], "1F3D8", 4],
	[["feet", "paw_prints"], "1F43E", 2],
	[["bathtub"], "1F6C1", 6],
	[["robot_face"], "1F916", 1],
	[["jack_o_lantern"], "1F383", 1],
	[["derelict_house_building"], "1F3DA", 4],
	[["dragon"], "1F409", 2],
	[["bath"], "1F6C0", 6],
	[["office"], "1F3E2", 4],
	[["dragon_face"], "1F432", 2],
	[["smiley_cat"], "1F63A", 1],
	[["bellhop_bell"], "1F6CE", 6],
	[["cactus"], "1F335", 2],
	[["department_store"], "1F3EC", 4],
	[["key"], "1F511", 6],
	[["smile_cat"], "1F638", 1],
	[["christmas_tree"], "1F384", 2],
	[["post_office"], "1F3E3", 4],
	[["old_key"], "1F5DD", 6],
	[["joy_cat"], "1F639", 1],
	[["evergreen_tree"], "1F332", 2],
	[["european_post_office"], "1F3E4", 4],
	[["heart_eyes_cat"], "1F63B", 1],
	[["door"], "1F6AA", 6],
	[["deciduous_tree"], "1F333", 2],
	[["hospital"], "1F3E5", 4],
	[["smirk_cat"], "1F63C", 1],
	[["couch_and_lamp"], "1F6CB", 6],
	[["palm_tree"], "1F334", 2],
	[["bank"], "1F3E6", 4],
	[["kissing_cat"], "1F63D", 1],
	[["bed"], "1F6CF", 6],
	[["seedling"], "1F331", 2],
	[["hotel"], "1F3E8", 4],
	[["scream_cat"], "1F640", 1],
	[["sleeping_accommodation"], "1F6CC", 6],
	[["herb"], "1F33F", 2],
	[["convenience_store"], "1F3EA", 4],
	[["frame_with_picture"], "1F5BC", 6],
	[["crying_cat_face"], "1F63F", 1],
	[["shamrock"], "2618", 2],
	[["school"], "1F3EB", 4],
	[["pouting_cat"], "1F63E", 1],
	[["shopping_bags"], "1F6CD", 6],
	[["four_leaf_clover"], "1F340", 2],
	[["love_hotel"], "1F3E9", 4],
	[["open_hands"], "1F450", 1],
	[["shopping_trolley"], "1F6D2", 6],
	[["gift"], "1F381", 6],
	[["bamboo"], "1F38D", 2],
	[["wedding"], "1F492", 4],
	[["raised_hands"], "1F64C", 1],
	[["balloon"], "1F388", 6],
	[["tanabata_tree"], "1F38B", 2],
	[["classical_building"], "1F3DB", 4],
	[["clap"], "1F44F", 1],
	[["church"], "26EA", 4],
	[["leaves"], "1F343", 2],
	[["flags"], "1F38F", 6],
	[["pray"], "1F64F", 1],
	[["fallen_leaf"], "1F342", 2],
	[["ribbon"], "1F380", 6],
	[["mosque"], "1F54C", 4],
	[["handshake"], "1F91D", 1],
	[["maple_leaf"], "1F341", 2],
	[["confetti_ball"], "1F38A", 6],
	[["+1", "thumbsup"], "1F44D", 1],
	[["synagogue"], "1F54D", 4],
	[["mushroom"], "1F344", 2],
	[["tada"], "1F389", 6],
	[["-1", "thumbsdown"], "1F44E", 1],
	[["kaaba"], "1F54B", 4],
	[["shinto_shrine"], "26E9", 4],
	[["ear_of_rice"], "1F33E", 2],
	[["dolls"], "1F38E", 6],
	[["facepunch", "punch"], "1F44A", 1],
	[["fist"], "270A", 1],
	[["izakaya_lantern", "lantern"], "1F3EE", 6],
	[["bouquet"], "1F490", 2],
	[["japan"], "1F5FE", 4],
	[["tulip"], "1F337", 2],
	[["wind_chime"], "1F390", 6],
	[["rice_scene"], "1F391", 4],
	[["left-facing_fist"], "1F91B", 1],
	[["email", "envelope"], "2709", 6],
	[["rose"], "1F339", 2],
	[["national_park"], "1F3DE", 4],
	[["right-facing_fist"], "1F91C", 1],
	[["sunrise"], "1F305", 4],
	[["envelope_with_arrow"], "1F4E9", 6],
	[["hand_with_index_and_middle_fingers_crossed"], "1F91E", 1],
	[["wilted_flower"], "1F940", 2],
	[["v"], "270C", 1],
	[["sunrise_over_mountains"], "1F304", 4],
	[["sunflower"], "1F33B", 2],
	[["incoming_envelope"], "1F4E8", 6],
	[["stars"], "1F320", 4],
	[["blossom"], "1F33C", 2],
	[["e-mail"], "1F4E7", 6],
	[["the_horns", "sign_of_the_horns"], "1F918", 1],
	[["cherry_blossom"], "1F338", 2],
	[["sparkler"], "1F387", 4],
	[["ok_hand"], "1F44C", 1],
	[["love_letter"], "1F48C", 6],
	[["hibiscus"], "1F33A", 2],
	[["fireworks"], "1F386", 4],
	[["point_left"], "1F448", 1],
	[["inbox_tray"], "1F4E5", 6],
	[["city_sunrise"], "1F307", 4],
	[["earth_americas"], "1F30E", 2],
	[["point_right"], "1F449", 1],
	[["outbox_tray"], "1F4E4", 6],
	[["city_sunset"], "1F306", 4],
	[["earth_africa"], "1F30D", 2],
	[["point_up_2"], "1F446", 1],
	[["package"], "1F4E6", 6],
	[["earth_asia"], "1F30F", 2],
	[["cityscape"], "1F3D9", 4],
	[["label"], "1F3F7", 6],
	[["point_down"], "1F447", 1],
	[["point_up"], "261D", 1],
	[["night_with_stars"], "1F303", 4],
	[["full_moon"], "1F315", 2],
	[["mailbox_closed"], "1F4EA", 6],
	[["hand", "raised_hand"], "270B", 1],
	[["milky_way"], "1F30C", 4],
	[["waning_gibbous_moon"], "1F316", 2],
	[["mailbox"], "1F4EB", 6],
	[["bridge_at_night"], "1F309", 4],
	[["last_quarter_moon"], "1F317", 2],
	[["mailbox_with_mail"], "1F4EC", 6],
	[["raised_back_of_hand"], "1F91A", 1],
	[["foggy"], "1F301", 4],
	[["waning_crescent_moon"], "1F318", 2],
	[["mailbox_with_no_mail"], "1F4ED", 6],
	[["raised_hand_with_fingers_splayed"], "1F590", 1],
	[["new_moon"], "1F311", 2],
	[["postbox"], "1F4EE", 6],
	[["spock-hand"], "1F596", 1],
	[["waxing_crescent_moon"], "1F312", 2],
	[["wave"], "1F44B", 1],
	[["postal_horn"], "1F4EF", 6],
	[["first_quarter_moon"], "1F313", 2],
	[["scroll"], "1F4DC", 6],
	[["call_me_hand"], "1F919", 1],
	[["moon", "waxing_gibbous_moon"], "1F314", 2],
	[["muscle"], "1F4AA", 1],
	[["page_with_curl"], "1F4C3", 6],
	[["new_moon_with_face"], "1F31A", 2],
	[["page_facing_up"], "1F4C4", 6],
	[["middle_finger", "reversed_hand_with_middle_finger_extended"], "1F595", 1],
	[["writing_hand"], "270D", 1],
	[["full_moon_with_face"], "1F31D", 2],
	[["bookmark_tabs"], "1F4D1", 6],
	[["sun_with_face"], "1F31E", 2],
	[["bar_chart"], "1F4CA", 6],
	[["selfie"], "1F933", 1],
	[["first_quarter_moon_with_face"], "1F31B", 2],
	[["nail_care"], "1F485", 1],
	[["chart_with_upwards_trend"], "1F4C8", 6],
	[["last_quarter_moon_with_face"], "1F31C", 2],
	[["ring"], "1F48D", 1],
	[["chart_with_downwards_trend"], "1F4C9", 6],
	[["crescent_moon"], "1F319", 2],
	[["lipstick"], "1F484", 1],
	[["spiral_note_pad"], "1F5D2", 6],
	[["kiss"], "1F48B", 1],
	[["dizzy"], "1F4AB", 2],
	[["spiral_calendar_pad"], "1F5D3", 6],
	[["star"], "2B50", 2],
	[["lips"], "1F444", 1],
	[["calendar"], "1F4C6", 6],
	[["star2"], "1F31F", 2],
	[["tongue"], "1F445", 1],
	[["date"], "1F4C5", 6],
	[["sparkles"], "2728", 2],
	[["ear"], "1F442", 1],
	[["card_index"], "1F4C7", 6],
	[["zap"], "26A1", 2],
	[["nose"], "1F443", 1],
	[["card_file_box"], "1F5C3", 6],
	[["footprints"], "1F463", 1],
	[["fire"], "1F525", 2],
	[["ballot_box_with_ballot"], "1F5F3", 6],
	[["eye"], "1F441", 1],
	[["boom", "collision"], "1F4A5", 2],
	[["file_cabinet"], "1F5C4", 6],
	[["comet"], "2604", 2],
	[["eyes"], "1F440", 1],
	[["clipboard"], "1F4CB", 6],
	[["sunny"], "2600", 2],
	[["file_folder"], "1F4C1", 6],
	[["speaking_head_in_silhouette"], "1F5E3", 1],
	[["mostly_sunny", "sun_small_cloud"], "1F324", 2],
	[["bust_in_silhouette"], "1F464", 1],
	[["open_file_folder"], "1F4C2", 6],
	[["partly_sunny"], "26C5", 2],
	[["busts_in_silhouette"], "1F465", 1],
	[["card_index_dividers"], "1F5C2", 6],
	[["barely_sunny", "sun_behind_cloud"], "1F325", 2],
	[["baby"], "1F476", 1],
	[["rolled_up_newspaper"], "1F5DE", 6],
	[["partly_sunny_rain", "sun_behind_rain_cloud"], "1F326", 2],
	[["boy"], "1F466", 1],
	[["newspaper"], "1F4F0", 6],
	[["rainbow"], "1F308", 2],
	[["girl"], "1F467", 1],
	[["notebook"], "1F4D3", 6],
	[["cloud"], "2601", 2],
	[["man"], "1F468", 1],
	[["notebook_with_decorative_cover"], "1F4D4", 6],
	[["rain_cloud"], "1F327", 2],
	[["woman"], "1F469", 1],
	[["ledger"], "1F4D2", 6],
	[["thunder_cloud_and_rain"], "26C8", 2],
	[["closed_book"], "1F4D5", 6],
	[["lightning", "lightning_cloud"], "1F329", 2],
	[["person_with_blond_hair"], "1F471", 1],
	[["green_book"], "1F4D7", 6],
	[["snow_cloud"], "1F328", 2],
	[["older_man"], "1F474", 1],
	[["blue_book"], "1F4D8", 6],
	[["snowman"], "2603", 2],
	[["older_woman"], "1F475", 1],
	[["orange_book"], "1F4D9", 6],
	[["snowman_without_snow"], "26C4", 2],
	[["man_with_gua_pi_mao"], "1F472", 1],
	[["books"], "1F4DA", 6],
	[["snowflake"], "2744", 2],
	[["book", "open_book"], "1F4D6", 6],
	[["wind_blowing_face"], "1F32C", 2],
	[["man_with_turban"], "1F473", 1],
	[["bookmark"], "1F516", 6],
	[["dash"], "1F4A8", 2],
	[["link"], "1F517", 6],
	[["tornado", "tornado_cloud"], "1F32A", 2],
	[["cop"], "1F46E", 1],
	[["paperclip"], "1F4CE", 6],
	[["fog"], "1F32B", 2],
	[["linked_paperclips"], "1F587", 6],
	[["ocean"], "1F30A", 2],
	[["construction_worker"], "1F477", 1],
	[["triangular_ruler"], "1F4D0", 6],
	[["droplet"], "1F4A7", 2],
	[["straight_ruler"], "1F4CF", 6],
	[["guardsman"], "1F482", 1],
	[["sweat_drops"], "1F4A6", 2],
	[["pushpin"], "1F4CC", 6],
	[["umbrella_with_rain_drops"], "2614", 2],
	[["round_pushpin"], "1F4CD", 6],
	[["scissors"], "2702", 6],
	[["sleuth_or_spy"], "1F575", 1],
	[["lower_left_ballpoint_pen"], "1F58A", 6],
	[["lower_left_fountain_pen"], "1F58B", 6],
	[["black_nib"], "2712", 6],
	[["lower_left_paintbrush"], "1F58C", 6],
	[["lower_left_crayon"], "1F58D", 6],
	[["memo", "pencil"], "1F4DD", 6],
	[["pencil2"], "270F", 6],
	[["mag"], "1F50D", 6],
	[["mag_right"], "1F50E", 6],
	[["lock_with_ink_pen"], "1F50F", 6],
	[["closed_lock_with_key"], "1F510", 6],
	[["lock"], "1F512", 6],
	[["unlock"], "1F513", 6],
	[["mother_christmas"], "1F936", 1],
	[["santa"], "1F385", 1],
	[["princess"], "1F478", 1],
	[["prince"], "1F934", 1],
	[["bride_with_veil"], "1F470", 1],
	[["man_in_tuxedo"], "1F935", 1],
	[["angel"], "1F47C", 1],
	[["pregnant_woman"], "1F930", 1],
	[["bow"], "1F647", 1],
	[["information_desk_person"], "1F481", 1],
	[["no_good"], "1F645", 1],
	[["ok_woman"], "1F646", 1],
	[["raising_hand"], "1F64B", 1],
	[["face_palm"], "1F926", 1],
	[["shrug"], "1F937", 1],
	[["person_with_pouting_face"], "1F64E", 1],
	[["person_frowning"], "1F64D", 1],
	[["haircut"], "1F487", 1],
	[["massage"], "1F486", 1],
	[["man_in_business_suit_levitating"], "1F574", 1],
	[["dancer"], "1F483", 1],
	[["man_dancing"], "1F57A", 1],
	[["dancers"], "1F46F", 1],
	[["walking"], "1F6B6", 1],
	[["runner", "running"], "1F3C3", 1],
	[["couple", "man_and_woman_holding_hands"], "1F46B", 1],
	[["two_women_holding_hands"], "1F46D", 1],
	[["two_men_holding_hands"], "1F46C", 1],
	[["family", "man-woman-boy"], "1F46A", 1],
	[["hearts"], "2665", 7],
	[["womans_clothes"], "1F45A", 1],
	[["shirt", "tshirt"], "1F455", 1],
	[["jeans"], "1F456", 1],
	[["necktie"], "1F454", 1],
	[["dress"], "1F457", 1],
	[["bikini"], "1F459", 1],
	[["kimono"], "1F458", 1],
	[["high_heel"], "1F460", 1],
	[["sandal"], "1F461", 1],
	[["boot"], "1F462", 1],
	[["mans_shoe", "shoe"], "1F45E", 1],
	[["athletic_shoe"], "1F45F", 1],
	[["womans_hat"], "1F452", 1],
	[["tophat"], "1F3A9", 1],
	[["mortar_board"], "1F393", 1],
	[["crown"], "1F451", 1],
	[["helmet_with_white_cross"], "26D1", 1],
	[["school_satchel"], "1F392", 1],
	[["pouch"], "1F45D", 1],
	[["purse"], "1F45B", 1],
	[["handbag"], "1F45C", 1],
	[["briefcase"], "1F4BC", 1],
	[["eyeglasses"], "1F453", 1],
	[["dark_sunglasses"], "1F576", 1],
	[["closed_umbrella"], "1F302", 1],
	[["umbrella"], "2602", 1],
];
