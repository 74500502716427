import { emojis } from "../helpers/emojis";
export default class Local {
	constructor(app) {
		this.app = app;
		this.data = emojis;
		this.buildNameMap();
		this.emojiRegExp = /:([^ :]+)/gi;
		this.symbol = ":";
	}

	/**
	 * Initiates a collection of emojis
	 * @returns {Object} a collection of emojis
	 */
	emojis() {
		if (this._emojis) return this._emojis;

		const emData = (this._emojis = new webix.DataCollection({}));

		const data = this.getEmojiData(emojis);
		emData.parse(data);
		return emData;
	}
	getEmojiData(arr) {
		return arr.map(emoji => {
			return {
				id: webix.uid(),
				name: emoji[0][0],
				unified: emoji[1],
				category: emoji[2],
				names: emoji[0],
			};
		});
	}
	/**
	 * Finds emoji in the message text and replaces with an html tag to show an icon
	 * @param text {String} message text
	 * @returns {string}
	 */
	replaceEmoji(text) {
		const matches = text.match(this.emojiRegExp);
		const symbol = this.symbol;
		if (matches)
			matches.forEach(str => {
				str = str.replace(this.emojiRegExp, function(match, s) {
					return s;
				});
				const code = this.emojiNameMap[str];
				if (code)
					text = text.replace(
						symbol + str + symbol,
						"<span class='webix_chat_emoji webix_chat_emoji_" +
							code +
							"'></span>"
					);
			});

		return text;
	}
	basicEmojis() {
		if (this._basicEmojis) return this._basicEmojis;
		const emData = (this._basicEmojis = new webix.DataCollection({}));
		const back = this.app.getService("backend");
		back.basicEmojis().then(d => {
			const arr = [];
			d.forEach(name => {
				const emoji = this.findEmojiByName(name);
				if (emoji) arr.push(emoji);
			});
			emData.parse(this.getEmojiData(arr));
		});
		return emData;
	}
	findEmojiByName(name) {
		return emojis.find(em => em[0].indexOf(name) != -1);
	}
	findName(emoji, value) {
		return value
			? emoji.names.find(n => n.toLowerCase().indexOf(value) != -1)
			: emoji.name;
	}
	/**
	 * Creates a map of emojis with the "name: code" pairs
	 */
	buildNameMap() {
		this.emojiNameMap = {};
		emojis.forEach(em => {
			em[0].forEach(name => (this.emojiNameMap[name] = em[1]));
		});
	}
	/**
	 * Inserts underscores instead of spaces in emoji names
	 * @param {string} name - emoji name
	 * @returns {string} emoji name with underscores instead of spaces
	 */
	shortNameToTitle(name) {
		name = name.split("_").join(" ");
		return name[0].toUpperCase() + name.substring(1).toLowerCase();
	}
	//{"1":"People","2"":"Nature","3":"Activity","4":"Places","5":"Foods","6":"Objects", "7":"Symbols"}
	/**
	 * Creates an array of emoji categories:
	 * {"1":"People","2"":"Nature","3":"Activity","4":"Places","5":"Foods","6":"Objects"}
	 * @returns {Array} an array of emoji categories (objects)
	 */
	categories() {
		if (!this._categories)
			this._categories = [
				{ id: "basic", icon: "chi-star-outline", name: "Basic" },
				{ id: 1, icon: "chi-emoticon-outline", name: "Smileys & People" },
				{ id: 2, icon: "chi-cat", name: "Animals & Nature" },
				{ id: 3, icon: "chi-soccer", name: "Activity" },
				{ id: 4, icon: "chi-earth", name: "Travel & Places" },
				{ id: 5, icon: "chi-food-fork-drink", name: "Food & Drink" },
				{ id: 7, icon: "chi-heart-outline", name: "Symbols" },
				{ id: 6, icon: "chi-bullhorn-outline", name: "Objects" },
			];
		return this._categories;
	}
}
