import { JetView } from "webix-jet";
import { createState } from "jet-restate";

import "../../helpers/streamsgrid";

export default class BaseCallPanel extends JetView {
	config() {
		this.time = 0;
		this.Helpers = this.app.getService("helpers");
		this.Back = this.app.getService("backend");
		this.callStatuses = this.Back.callStatuses;
		this.callUserStatuses = this.Back.callUserStatuses;
		this.State = this.getParam("state", true);
		this.LocalState = createState({
			audio: true,
			video: false,
			remoteVideo: false,
			remoteAudio: false,
			connecting: false,
		});
		if (this.State.callInitiator) {
			this.Initiator = this.State.callInitiator === this.app.config.user;
		} else {
			this.Initiator = this.State.callUsers[0] === this.app.config.user;
		}
		this.liveKitEnabled =
			this.app.config.calls.livekitConfig || this.app.config.calls.groupCalls;
		this.isGroupCall = !!this.State.callIsGroup;
		this.callWith =
			(!this.isGroupCall &&
				this.State.callUsers.filter(v => v != this.app.config.user)[0]) ||
			this.app.config.user;
		this.usersMedia = [];

		if (this.State.callStatus === this.callStatuses["active"]) {
			// loading page while call already active
			// most probably due to page reloading
			// we need to force user to interact with page, or players will not start
			this.AfterRefresh = true;
		}

		const users = this.app.getService("local")._users;
		const chats = this.app.getService("local")._chats;

		const callConfig = this.GetCallConfig(users, chats);

		this._closed = false;

		return {
			type: "clean",
			width: this.getParam("compact", true) ? 0 : 380,
			rows: [
				{
					css: "webix_chat_call",
					localId: "fullscreen",
					borderless: true,
					rows: [...callConfig, this.GetActionsConfig()],
				},
			],
		};
	}

	init() {
		this.onBeforeUnload = webix.event(window, "beforeunload", () => {
			if (!this.State.callId) {
				return;
			}
			this.Back.updateUserStatus(
				this.State.callId,
				this.callUserStatuses["connecting"]
			);
			this.OnWindowUnloadHandler();
		});

		this.InitCallConfig();
		this.on(this.app, "updateCallTime", time => this.UpdateCallTime(time));
		this.on(this.State.$changes, "callStatus", status =>
			this.HandleCallStatusChange(status)
		);

		this.toggleVideo = this.$$("video-toggle");
		this.toggleAudio = this.$$("audio-toggle");
	}

	/**
	 * Base method.
	 */
	OnWindowUnloadHandler() {}

	/**
	 * Base method. Returns an array with call config components. Calls in 'config()'
	 */
	GetCallConfig() {
		return [];
	}

	/**
	 * Base method. Initializes call config. Calls in 'init()'
	 */
	InitCallConfig() {}

	/**
	 * Base method. Initializes service that provides call audio/video connection
	 * @param {boolean} isInitiator - shows whether the current user is the call initiator
	 * @param {boolean} isAfterRefresh - shows whether a call is resumed after a page refresh
	 */
	InitPhone() {}

	/**
	 * Returns configuration for the actions buttons
	 * @returns {Object} config object of the actions controls (buttons, toggles, etc.)
	 */
	GetActionsConfig() {
		return {
			height: 80,
			css: "webix_chat_call_actions",
			localId: "call-actions",
			rows: [
				{},
				{
					height: 44,
					cols: [
						{},
						{
							view: "toggle",
							type: "icon",
							offIcon: "chi-video-off",
							onIcon: "chi-video",
							localId: "video-toggle",
							css: "webix_primary",
							width: 44,
							click: () => this.EnableVideo(true),
						},
						{ width: 32 },
						{
							view: "toggle",
							type: "icon",
							offIcon: "chi-audio-off",
							onIcon: "chi-audio",
							localId: "audio-toggle",
							css: "webix_primary",
							width: 44,
							value: true,
							click: () => this.EnableAudio(true),
						},
						{ width: 32 },
						{
							view: "button",
							type: "icon",
							icon: "chi-hangup",
							css: "webix_danger",
							width: 44,
							click: () => this.EndCall(),
						},
						{},
					],
				},
				{},
			],
		};
	}

	/**
	 * Returns configuration for the call states: (incoming, outgoing, connecting...)
	 * @returns {Object} config object of the call states
	 */
	GetStatesConfig() {
		const _ = this.app.getService("locale")._;
		return [
			{
				batch: "outgoing",
				css: "webix_chat_audio",
				height: 30,
				template: _("Ringing"),
			},
			{
				batch: "income",
				css: "webix_chat_audio",
				height: 30,
				template: _("Is calling you"),
			},
			{
				batch: "connecting",
				css: "webix_chat_audio",
				height: 30,
				template: _("Connecting") + "...",
			},
			{
				id: "connecting-label",
				css: "webix_chat_audio",
				height: 30,
				template: _("Connecting") + "...",
				hidden: true,
			},
			{
				batch: "income",
				height: 42,
				css: "webix_chat_audio",
				cols: [
					{},
					{
						view: "button",
						height: 0,
						width: 86,
						value: _("Accept"),
						css: "webix_chat_accept",
						click: () => this.AcceptCall(),
					},
					{
						view: "button",
						width: 86,
						value: _("Reject"),
						css: "webix_danger",
						click: () => this.RejectCall(),
					},
					{},
				],
			},
			{
				batch: "refresh",
				height: 42,
				css: "webix_chat_audio",
				cols: [
					{},
					{
						view: "button",
						height: 0,
						width: 86,
						value: _("Join call"),
						css: "webix_chat_accept",
						click: () => {
							this.AfterRefresh = false;
							this.Start(true);
						},
					},
					{
						view: "button",
						width: 86,
						value: _("End call"),
						css: "webix_danger",
						click: () => this.EndCall(),
					},
					{},
				],
			},
			{
				batch: "connecting",
				height: 42,
				css: "webix_chat_audio",
				cols: [
					{},
					{
						view: "button",
						width: 86,
						value: _("Cancel"),
						css: "webix_danger",
						click: () => this.EndCall(),
					},
					{},
				],
			},
		];
	}

	/**
	 * Manages visual parts of a call (view, controls, etc.)
	 * @param {string} type - call type ("video", "audio")
	 * @param {string} batch - shows whether the call is "incoming" or "outgoing"
	 * @param {boolean} actions - defines whether to show action controls panel
	 */
	ControlVisibility(type, batch, actions) {
		const view = this.$$(type);
		view.show();
		if (batch) view.showBatch(batch);

		if (this.isGroupCall)
			batch != "active"
				? this.$$("callAvatar").show()
				: this.$$("callAvatar").hide();

		this.$$("call-actions")[actions ? "show" : "hide"]();
	}

	/**
	 * Sets fullscreen for a call
	 * @param {boolean} fullscreen - defines whether the fullscreen should be enabled
	 */
	SetFullscreen(fullscreen) {
		webix.fullscreen.exit();

		const icon = this.$$("fullscreen-icon");
		icon.config.icon = "chi-fullscreen" + (fullscreen ? "-off" : "");
		icon.refresh();

		this.fullscreen = fullscreen
			? webix.fullscreen.set(this.$$("fullscreen"), {
					head: false,
					css: "webix_chat_video_fullscreen",
			  })
			: null;
	}

	/**
	 * Starts a call
	 * @param {boolean} isAfterRefresh - shows whether a call is resumed after a page refresh
	 */
	Start(isAfterRefresh) {
		if (this.AfterRefresh) {
			this.ControlVisibility("audio", "refresh", false);
		} else {
			this.ControlVisibility("audio", "connecting", false);
			this.InitPhone(this.Initiator, isAfterRefresh)
				.then(() => {
					if (!this.LocalState.remoteVideo)
						this.ControlVisibility("audio", "active", true);
					if (this.LocalState.audio) this.EnableAudio();
					if (this.LocalState.video) this.EnableVideo();
					this.Back.updateUserStatus(
						this.State.callId,
						this.callUserStatuses["active"]
					);
				})
				.catch(err => {
					this.EndCall();
					console.error(err);
				});
		}
	}

	/**
	 * Ends a call
	 */
	EndCall() {
		if (this._closed || this._ended) return;
		this._ended = true;

		this.SetFullscreen(false);
		const state = this.getParam("state", true);
		state.callStatus = this.callStatuses["drop"];

		if (this.Phone) {
			this.Back.updateCall(this.State.callId, this.callStatuses["end"]).finally(
				() => {
					this.Phone && this.Phone.end();
					this.Phone = null;
				}
			);
		} else {
			this.Back.updateCall(this.State.callId, this.callStatuses["ignore"]);
		}
	}

	/**
	 * Accepts an incoming call
	 */
	AcceptCall() {
		this.Back.updateCall(this.State.callId, this.callStatuses["accept"]);
	}

	/**
	 * Rejects an incoming call
	 */
	RejectCall() {
		const state = this.getParam("state", true);
		state.callStatus = this.callStatuses["drop"];

		this.Back.updateCall(this.State.callId, this.callStatuses["reject"]);
	}

	/**
	 * Toggles audio during the call
	 * @param {boolean} toggle - true, if audio has been toggled
	 */
	EnableAudio(toggle) {
		const v = toggle
			? (this.LocalState.audio = !this.LocalState.audio)
			: this.LocalState.audio;
		if (this.Phone) {
			this.Phone.enable("audio", v).catch(() => {
				this.LocalState.audio = false;
				if (this.toggleAudio) {
					this.toggleAudio.setValue(false);
				}
			});
		}
	}

	/**
	 * Toggles video during the call
	 * @param {boolean} toggle - true, if video has been toggled
	 */
	EnableVideo(toggle) {
		const v = toggle
			? (this.LocalState.video = !this.LocalState.video)
			: this.LocalState.video;
		if (this.Phone) {
			this.Phone.enable("video", v)
				.catch(() => {
					this.LocalState.video = false;
					if (this.toggleVideo) {
						this.toggleVideo.setValue(false);
					}
				})
				.finally(() => {
					this.ShowVideo();
				});
		}
	}

	/**
	 * Updates call time
	 * @param {number} time - time in seconds
	 */
	UpdateCallTime(time) {
		if (this._closed) return;

		this.time = time;
		if (this.fullscreen)
			this.fullscreen.queryView({ localId: "video-timer" }).refresh();
		else if (this.$$("audio-timer").isVisible())
			this.$$("audio-timer").refresh();
		else if (this.$$("video-timer").isVisible())
			this.$$("video-timer").refresh();
	}

	/**
	 * Tracks changes of the call status
	 * @param {number} status - status code (1, 2, 3, 900, and other )
	 * https://docs.webix.com/chat__backend.html#updatecall for details
	 */
	HandleCallStatusChange(status) {
		if (status == this.callStatuses["init"]) {
			this.ControlVisibility(
				"audio",
				this.Initiator ? "outgoing" : "income",
				this.Initiator
			);
		} else if (status == this.callStatuses["active"]) {
			this.Start();
		} else if (
			status == this.callStatuses["reject"] ||
			status == this.callStatuses["end"] ||
			status == this.callStatuses["ignore"] ||
			status == this.callStatuses["lost"] ||
			status == this.callStatuses["busy"] ||
			status == ""
		) {
			if (this.Phone) {
				this.SetFullscreen(false);
				this.Phone.end();
				this.Phone = null;
			}
		}
	}

	/**
	 * Shows video if the functionality is available
	 */
	ShowVideo() {}

	/**
	 * On destroy handler
	 */
	destroy() {
		this._closed = true;
		if (this.Phone != null) {
			this.Phone.end();
		}
		webix.eventRemove(this.onBeforeUnload);
	}
}
