import ListView from "./list";

const MessageFile = 800;
const MessageVoice = 801;
const MessageCallEnd = 900;
const MessageCallRejected = 901;
const MessageCallMissed = 902;
const MessageCallBusy = 903;

export default class chatsView extends ListView {
	config() {
		const limited = this.app.config.mode === "limited";

		const _ = this.app.getService("locale")._;
		const ui = super.config();
		const bottomForm = {
			padding: 9,
			rows: [
				{
					view: "button",
					type: "icon",
					icon: "wxi-plus",
					css: "webix_chat_add_button",
					height: Math.max(webix.skin.$active.inputHeight, 38),
					label: _("New chat"),
					click: () => this.app.callEvent("newChat", []),
					hidden: limited,
				},
			],
		};
		return {
			type: "clean",
			rows: [ui, bottomForm],
		};
	}

	init() {
		const state = this.app.getParam("state", true);
		const chats = this.app.getService("local").chats();
		this.InitSelf(chats);

		// wait data loading, so list will contain something to select
		chats.waitData.then(() => {
			// allow to change selected chat through API
			this.on(state.$changes, "chatId", id => {
				if (id) this.Select(id);
				else this.$$("list").unselectAll();
			});
		});
		if (this.app.config.emojis) this.Emojis = this.app.getService("emojis");
	}

	/**
	 * Shows chat with a specified ID
	 * @param {number} id - ID of the chat to show
	 */
	ShowChat(id) {
		this.app.callEvent("showChat", ["chat", id * 1]);
	}

	/**
	 * Creates an HTML template for each chat in Chats
	 * @param {Object} chat - chat object
	 * @param {Object} common - object with common settings for each template
	 * @returns {string} an HTML template for a chat in the "Chats" tab
	 */
	ListTemplate(chat, common) {
		const locale = this.app.getService("locale")._;
		const helpers = this.app.getService("helpers");

		let html = "";
		html += helpers.listAvatar(chat);
		html += "<div class='webix_chat_listitem_block'>";
		html += `<div class='webix_chat_listitem_name'>${helpers.addTextMark(
			chat.name,
			this.EscapedSearchText
		)}</div>`;
		if (chat.date)
			html += `<span class='webix_chat_listitem_date'>${helpers.dateChatFormat(
				chat.date
			)}</span>`;
		if (chat.unread_count) {
			let css = "webix_chat_listitem_number";
			if (chat.unread_count > 9) css += " webix_chat_listitem_number_wide";
			html += `<span class='${css}'>${chat.unread_count}</span>`;
		}
		if (chat.message_type && chat.message_type >= 800) {
			html += "<div class='webix_chat_listitem_message'>";
			switch (chat.message_type) {
				case MessageCallEnd:
					html += locale("Ended call") + " (" + chat.message + ")";
					break;
				case MessageCallRejected:
					html += locale("Rejected call");
					break;
				case MessageCallMissed:
					html += locale("Missed call");
					break;
				case MessageCallBusy:
					html += locale("Line is busy");
					break;
				case MessageVoice:
					html += locale("Voice message");
					break;
				case MessageFile:
					html += chat.message.split("\n")[1];
					break;
			}
			html += "</div>";
		} else if (chat.message) {
			let message = common.text(chat.message);
			if (this.Emojis) message = this.Emojis.replaceEmoji(message);
			html += "<div class='webix_chat_listitem_message'>";
			html += message;
			html += "</div>";
		} else if (chat.message_type === 700) {
			html += `<div class='webix_chat_listitem_message'>${locale(
				"typing..."
			)}</div>`;
		}

		return html + "</div>";
	}
}
