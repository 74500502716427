webix.protoUI(
	{
		name: "chat-search",
		$cssName: "search",
		on_click: {
			webix_input_icon: function(e) {
				const css = e.target.className;
				if (css.indexOf(this.config.closeIcon) > -1) {
					this.getInputNode().focus();
					this.setValue("");
					this.callEvent("onCloseIconClick", [e]);
					this.hideCloseIcon();
				} else {
					this.getInputNode().focus();
					this.callEvent("onSearchIconClick", [e]);
				}
			},
		},
		setIcon(icon) {
			const nodes = this.$view.getElementsByClassName("webix_input_icon");
			if (nodes.length) nodes[0].className = "webix_input_icon " + icon;
		},
		showCloseIcon() {
			this.setIcon(this.config.closeIcon);
		},
		hideCloseIcon() {
			this.setIcon(this.config.icon);
		},
		defaults: {
			closeIcon: "wxi-close",
		},
	},
	webix.ui.search
);
