import { JetView } from "webix-jet";
import Toolbar from "./toolbar";
import "../../helpers/chatcomments";

import EmojiPopup from "./emojis";
import EmojiSuggest from "./emojisuggest";

export default class MessagesView extends JetView {
	config() {
		this.Helpers = this.app.getService("helpers");
		this.State = this.getParam("state", true);
		this.Upload = this.app.getService("upload");
		this.UploadDelay = 500;
		this.WithReactions = this.app.config.reactions;
		this._ = this.app.getService("locale")._;

		const ui = {
			view: "chat-comments",
			sendAction: "enter",
			localId: "comments",
			css: "webix_chat_comments",
			currentUser: this.app.config.user,
			users: new webix.DataCollection({
				data: [],
				scheme: {
					$init: user => {
						user.value = user.name;
						user.image = user.avatar;
					},
				},
			}),
			listItem: {
				template: (o, c) => this.MessageTemplate(o, c),
			},
		};
		if (
			this.app.config.files ||
			this.app.config.emojis ||
			this.app.config.voiceMessages
		)
			ui.ChatFormConfig = conf => this.FormConfig(conf);
		if (this.WithReactions) ui.ChatListConfig = conf => this.ListConfig(conf);

		return {
			rows: [Toolbar, ui],
		};
	}

	init() {
		// this.Comments will be used in UI template
		const comments = (this.Comments = this.$$("comments"));
		const list = (this.List = comments.queryView("list"));

		// services
		this.Local = this.app.getService("local");
		this.Back = this.app.getService("backend");
		this.Ops = this.app.getService("operations");
		this.Users = this.Local.users();
		this.textarea = this.Comments.queryView({ name: "text" });

		const serverEvents = this.Back.pubsub();
		const users = comments.getUsers();

		this.on(users.data, "onSyncApply", function() {
			// add "value" property for mentions suggest
			users.data.each(item => {
				item.value = item.name;
			});
		});
		users.sync(this.Local.users());
		comments.attachEvent("onAfterAdd", cid => {
			const obj = comments.getItem(cid);

			if (!obj.chat_id) {
				const userId = this.State.userId;

				// show typed value as text, not as HTML tags
				const formatted = obj.text.replace(/</g, "&lt;");
				if (formatted !== obj.text) {
					comments.updateItem(cid, { text: formatted });
				}

				if (this.State.chatId) {
					this.AddMessage(this.State.chatId, obj.text, cid);
					this.ScrollToBottom();
				} else if (userId) {
					comments.disable();
					this.Ops.addChat(userId)
						.then(chatId => this.AddMessage(chatId, obj.text, cid))
						.then(() => this.app.callEvent("showChat", ["user", userId]));
				}
			}
		});
		comments.attachEvent("onBeforeDelete", id => {
			if (this.IsUsersMessage(comments.getItem(id))) this.Ops.removeMessage(id);
			if (this.app.config.grouping)
				this.RefreshAfterDeletion = comments.getNextId(id);
			return true;
		});
		comments.attachEvent("onAfterDelete", id => {
			if (
				this.RefreshAfterDeletion &&
				comments.exists(this.RefreshAfterDeletion)
			)
				this.RefreshAfterDeletion = comments.refresh(this.RefreshAfterDeletion);
			if (this.VoiceMessages && this.VoiceMessages.lastVoice == id)
				this.VoiceMessages.ClearVoice(id);
		});
		comments.attachEvent("onDataUpdate", (id, msg, old) => {
			if (this.IsUsersMessage(msg)) {
				const oldFormatted = old.text.replace(/</g, "&lt;");
				if (msg.text == oldFormatted) return;

				const formatted = msg.text.replace(/</g, "&lt;");
				if (msg.text != formatted) {
					comments.updateItem(id, { text: formatted });
					if (formatted == oldFormatted) return;
				}

				if (msg.text != old.text) {
					this.Ops.updateMessage(id, formatted);
					// refresh edited message
					msg.edited = true;
					comments.refresh(id);
				}
			}
		});

		if (this.VoiceMessages)
			list.attachEvent("onItemRender", obj =>
				this.VoiceMessages.RefreshVoiceItem(list, obj.id)
			);

		this.on(this.app, "showChat", () => {
			const chats = this.Local.chats();
			chats.waitData.then(() => {
				if (this.getRoot())
					this.FilterUsers(
						comments.getUsers(),
						chats,
						this.State.chatId,
						this.State.userId
					);
			});
		});

		this.on(serverEvents, "messages", pack => {
			const { op, origin, msg } = pack;

			if (msg.chat_id != this.State.chatId) return;

			switch (op) {
				case "add":
					msg.date = new Date(msg.date);
					// recent message saved
					if (comments.exists(origin)) {
						list.data.changeId(origin, msg.id);
						comments.updateItem(msg.id, msg);
						// new message added
					} else if (!comments.exists(msg.id)) {
						comments.add(msg);
						this.ScrollToBottom();
					}
					break;

				case "remove":
					if (comments.exists(msg.id)) comments.remove(msg.id);
					break;
				case "append": {
					const item = comments.getItem(msg.id);
					if (item) {
						comments.updateItem(msg.id, { text: item.text + msg.text });
					}
					break;
				}
				case "update":
					msg.date = new Date(msg.date);
					if (comments.exists(msg.id)) {
						comments.updateItem(msg.id, msg);
					}
					break;
			}
		});

		this.on(this.State.$changes, "chatId", () => {
			this.LoadChat(this.State.chatId, this.State.userId);
		});

		if (this.app.config.files) this.InitUploader();

		const commentsMenu = this.Comments.getMenu();
		this.on(commentsMenu, "onBeforeShow", () =>
			this.ShowMenuHandler(commentsMenu)
		);
		this.AddImageHandler();

		if (this.app.config.emojis) this.InitEmojis();
		this.GroupingTime = 5 * 60 * 1000;
	}

	/**
	 * Is called upon message removal or update
	 * @param {Object} msg - message object
	 * @returns {boolean} true if user can manipulate the message and message type is 901-904
	 */
	IsUsersMessage(msg) {
		return msg.user_id == this.app.config.user && (!msg.type || msg.type < 900);
	}

	IsUsersReaction(msgId, reaction) {
		if (msgId == this.app.config.user) return false;
		const msg = this.Comments.getItem(msgId);
		return msg.reactions[reaction].includes(this.app.config.user);
	}

	/**
	 * Sends new message to a specified chat
	 * @param {number} chatId - chat ID
	 * @param {string} text - message text
	 * @param {number} messageId - message ID
	 * @returns {Promise} a promise that resolves with a new message
	 */
	AddMessage(chatId, text, messageId) {
		return this.Ops.addMessage(chatId, messageId, text).then(msg => {
			if (this.Comments.exists(messageId))
				this.Comments.queryView("list").data.changeId(messageId, msg.id);
		});
	}

	/**
	 * Scrolls the comments view to the bottom (e.g. when users sends a message)
	 */
	ScrollToBottom() {
		const list = this.$$("comments").queryView("list");
		list.showItem(list.getLastId());
	}

	/**
	 * Loads a specified chat
	 * @param {number} id - chat ID
	 * @param {number} userId - user ID
	 */
	LoadChat(id, userId) {
		const comments = this.$$("comments");

		// detach old chat
		comments.clearAll();

		if (this.VoiceMessages) {
			this.CancelVoice();
			clearInterval(this._voiceTimer);
			this.VoiceMessages.ClearVoice(true);
		}

		this.Uploader = this.Upload.getUploader(id, "file");
		if (id || userId) {
			comments.enable();
			if (id)
				this.Local.messages(id).then(data => {
					if (!this.getRoot()) return;
					comments.parse(data);
					this.Ops.resetCounter(id);
					this.ScrollToBottom();
				});
			this.Focus();
		} else {
			comments.disable();
		}
	}

	/**
	 * Filters the current user from the list of users in chat
	 * @param {Object} users - users collection
	 * @param {Object} chats - chats collection
	 * @param {number} chatId - chat ID
	 * @param {number} userId - user ID
	 */
	FilterUsers(users, chats, chatId, userId) {
		const chat = chatId ? chats.getItem(chatId) : null;
		users.data.filter(user => {
			if (chatId)
				return (
					chat &&
					(chat.users.indexOf(user.id) >= 0 && user.id != this.app.config.user)
				);
			return user.id == userId;
		});
	}

	/**
	 * Focuses the message input field
	 */
	Focus() {
		webix.delay(() => {
			if (this.getRoot()) this.Comments.focus();
		});
	}

	/**
	 * Indicates that the element should be grouped
	 * @param {Object} obj - message object
	 * @returns {Boolean}
	 */
	isGroup(obj) {
		if (obj.type >= 900) return false;

		let prev = this.Comments.getPrevId(obj.id);
		if (!prev) return false;

		prev = this.Comments.getItem(prev);
		if (prev.type >= 900 || obj.user_id !== prev.user_id) return false;

		const today = webix.Date.datePart(new Date(), true);
		const day = webix.Date.datePart(obj.date, true);

		if (webix.Date.equal(day, today)) {
			// less than GroupingTime(5 min) between 2 messages
			return obj.date - prev.date < this.GroupingTime;
		} else {
			const prevDay = webix.Date.datePart(prev.date, true);
			return webix.Date.equal(day, prevDay);
		}
	}

	/**
	 * Creates an HTML template string for a message
	 * @param {Object} obj - message object
	 * @param {Object} common - common elements declared in type
	 * @returns {string} an HTML string with a message template
	 */
	MessageTemplate(obj, common) {
		obj = webix.copy(obj);
		obj.text = common.templateMentioned(obj);
		obj.text = this.MessageTemplateLink(obj);

		const text = this.MessageTemplateText(obj, common);
		if (this.app.config.grouping && this.isGroup(obj)) return text;

		const avatar = this.MessageTemplateAvatar(obj, common);
		const user = this.MessageTemplateUser(obj, common);
		const date = this.MessageTemplateDate(obj, common);
		return (
			avatar + `<div class="webix_chat_information">${user + date}</div>` + text
		);
	}

	VoiceTemplate(time) {
		return (
			"<span class='webix_icon chi-play webix_chat_voice_play'></span>" +
			"<span class='webix_chat_voice_time'>" +
			this.Helpers.normalizeTime(Math.ceil(time)) +
			"</span>" +
			"<div class='webix_chat_progress'>" +
			"<div class='webix_chat_progress_bar transfer webix_chat_voice_progress' style='width:100%'>&nbsp;</div>" +
			"</div>"
		);
	}

	/**
	 * Creates an HTML template string for a message
	 * @param {Object} obj - message object
	 * @param {Object} common - common elements declared in type
	 * @returns {string} an HTML string with a message template
	 */
	MessageTemplateText(obj, common) {
		let { text, type } = obj;
		let css = "webix_comments_message";
		if (this.app.config.emojis) text = this.Emojis.replaceEmoji(text);

		if (type == 800) {
			const parts = text.split("\n");
			if (parts.length === 4)
				text = this.Preview(parts[0], parts[3], parts[1], parts[2]);
			else text = this.FileLinkTemplate(parts[0], parts[1], parts[2]);
		} else if (type == 801) {
			css += " webix_chat_voice";
			const parts = text.split("\n");
			text = "<div>" + this.VoiceTemplate(parts[1]) + "</div>";
		} else if (type == 700) {
			text = text || this._("typing...");
		} else if (type >= 900) {
			const outgoing = this.app.config.user == obj.user_id;
			const arrow = "chi-arrow-" + (outgoing ? "top-right" : "bottom-left");

			let message;
			switch (type) {
				case 900:
					message = outgoing
						? this._("Outgoing call")
						: this._("Incoming call");
					break;
				case 901:
					message = this._("Rejected call");
					break;
				case 902:
					message = this._("Missed call");
					break;
				case 903:
					message = this._("Line is busy");
			}

			return `<span class="${css}"><span class="webix_chat_call_type ${arrow} webix_chat_call_type_${this.Back.callMessages[type]}"></span><div class="webix_chat_call_message">${message}<p>${text}</p></div>`;
		}

		const reactions = this.WithReactions ? this.ReactionsTemplate(obj) : "";
		const edited = this.MessageTemplateEdited(obj, common);
		const menu = this.MessageTemplateMenu(obj, !reactions);
		const style = menu ? "style='padding-right:32px'" : "";
		return `<div class="${css}" ${style}>${text +
			edited +
			reactions +
			menu}</div>`;
	}

	/**
	 * Creates an HTML template string for a message
	 * @param {Object} obj - message object
	 * @param {Object} common - common elements declared in type
	 * @returns {string} an HTML string
	 */
	MessageTemplateEdited(obj) {
		if (obj.edited)
			return `<span class="webix_chat_edited">(${this._("edited")})</span>`;
		return "";
	}

	/**
	 * Creates an HTML template for file upload link
	 * @param {string} url - URL the file is available for download by
	 * @param {string} name - file name
	 * @param {string} size - file size
	 * @returns {string} an HTML string with a template for file link
	 */
	FileLinkTemplate(url, name, size) {
		return (
			`<a target="_blank" href="${url}" class="webix_chat_file_block">` +
			"<span class='webix_icon chi-file-outline webix_chat_file_icon'></span>" +
			"<div class='webix_chat_file_section'>" +
			this.FileNameTemplate(name) +
			`<div class="webix_chat_file_size">${this.FormatBytes(size)}</div>` +
			"</div></a>"
		);
	}

	/**
	 * Styles a link the user sent
	 * @param {Object} obj - message object
	 * @returns {string} a styled link
	 */
	MessageTemplateLink(obj) {
		if (obj.type) return obj.text;
		const text = obj.text.replace(/(https?:\/\/[^\s]+)/g, this.Preview);

		return text;
	}

	/**
	 * Generates a preview for files and links
	 * @param {string} url - URL to download file or link URL
	 * @param {string} preview - file/link preview
	 * @param {string} name - file/link name
	 * @param {number} size - size in bytes
	 * @returns {string} an HTML template for file preview or styled link
	 */
	Preview(url, preview, name, size) {
		url = webix.template.escape(url);
		let html = "",
			css = "";
		if (url.match(/.(jpg|jpeg|png|gif)$/)) {
			// check "this" to detect external image link
			if (this && name) {
				return (
					this.FileLinkTemplate(url, name, size) +
					"<br/><a target='_blank' class='webix_chat_preview_lnk' href='" +
					url +
					"'>" +
					"<img class='webix_comments_image' src='" +
					preview +
					"' />" +
					"</a>"
				);
			} else {
				html +=
					"<img class='webix_comments_image webix_comments_image_link' src='" +
					preview +
					"'/>" +
					"<div class='webix_chat_file_name_link'>" +
					url +
					"</div>";
			}
		} else html += url;
		return (
			"<a target='_blank' class='" +
			css +
			"' href='" +
			url +
			"'>" +
			html +
			"</a>"
		);
	}

	/**
	 * Add errors handler for images
	 */
	AddImageHandler() {
		webix.event(this.Comments.$view, "error", e => this.ImageErrorHandler(e), {
			capture: true,
		});
	}

	/**
	 * Handles errors thrown from image processing
	 * @param {Object} e - error event object
	 */
	ImageErrorHandler(e) {
		const trg = e.target;
		if (trg.className.indexOf("webix_comments_image") != -1)
			trg.className += " webix_chat_onerror";
	}

	/**
	 * Creates a template for file name if it exceeds 40 chars
	 * @param {string} s - file name
	 * @returns {string} a template string for file name (extended with ellipsis if name exceeds the limit)
	 */
	FileNameTemplate(s) {
		if (s.length > 40)
			return s.substring(0, 20) + "..." + s.substring(s.length - 20, s.length);
		return s;
	}

	/**
	 * Creates an HTML template for user avatar in message area
	 * @param {Object} obj - message object
	 * @param {Object} common - common elements declared in type
	 * @returns {string} a string with an HTML template for user avatar in the message view
	 */
	MessageTemplateAvatar(obj) {
		let html = "<div class='webix_chat_list_avatar'>";
		const users = this.Comments.getUsers();
		const user =
			users && users.exists(obj.user_id) ? users.getItem(obj.user_id) : {};
		if (user.status) html += this.Helpers.status(user);
		html += this.Helpers.avatar(user);
		html += "</div>";
		return html;
	}

	/**
	 * Creates an HTML template for user name in message area
	 * @param {Object} obj - message object
	 * @param {Object} common - common elements declared in type
	 * @returns {string} a string with an HTML template for user name in the message view
	 */
	MessageTemplateUser(obj) {
		const users = this.Comments.getUsers();
		let user =
			users && users.exists(obj.user_id) ? users.getItem(obj.user_id) : {};

		return (
			"<span class = 'webix_comments_name'>" + (user.name || "") + "</span>"
		);
	}

	/**
	 * Creates an HTML template for message menu
	 * @param {Object} obj - message object
	 * @param {Boolean} react - if not false, a reaction emoji will be shown
	 * @returns {string} an HTML string with a template for menu (3 dots) or an empty string
	 */
	MessageTemplateMenu(obj, react) {
		if (this.Comments.config.readonly) return "";

		const config = this.app.config;
		if (config.user === obj.user_id)
			return "<span class='webix_icon wxi-dots webix_comments_menu'></span>";
		if (config.reactions && react !== false)
			return "<span class='webix_icon chi-emoticon-outline webix_chat_reaction'></span>";
		return "";
	}

	/**
	 * Creates an HTML template for message date
	 * @param {Object} obj - message object
	 * @param {Object} common - common elements declared in type
	 * @returns {string} an HTML template for message date
	 */
	MessageTemplateDate(obj) {
		return obj.date
			? "<span class='webix_comments_date'>" +
					this.Helpers.dateChatFormat(obj.date) +
					"</span>"
			: "";
	}

	FormConfig(config) {
		const elements = config.rows[1].elements;
		const textarea = elements[0];
		const skin = webix.skin.$active;
		textarea.view = "chat-comments-text";
		config.rows[1].minHeight = textarea.height = textarea.inactiveHeight =
			skin.inputHeight;
		const button = elements[1].cols[1];
		button.autowidth = true;
		const icons = [{}];
		if (this.app.config.files)
			icons.push({
				view: "icon",
				icon: "chi-paperclip",
				click: () => {
					this.Uploader.fileDialog();
				},
			});
		if (this.app.config.emojis)
			icons.push({
				view: "icon",
				icon: "chi-emoticon-outline",
				localId: "emojiIcon",
			});

		config.rows[1].rows = [
			{
				view: "list",
				css: "webix_chat_ulist",
				localId: "fileList",
				autoheight: true,
				borderless: true,
				template: obj => this.ListUploadTemplate(obj),
				type: {
					height: skin.listItemHeight > 30 ? 30 : skin.listItemHeight,
				},
				onClick: {
					webix_icon: (ev, id) => {
						this.StopUpload(id);
						return false;
					},
				},
			},
			{
				cols: [
					{
						batch: "message",
						padding: {
							right: 5,
						},
						rows: icons,
					},
					textarea,
					{
						padding: { left: webix.skin.$active.layoutMargin.form },
						view: "chat-comments-layout",
						rows: [{}, button],
					},
				],
			},
		];

		if (this.app.config.voiceMessages) {
			const voiceMessages = (this.VoiceMessages = this.app.getService(
				"voiceMessages"
			));
			const list = config.rows[0];

			list.onClick["chi-play"] = function(ev, id) {
				const item = this.getItem(id);
				const parts = item.text.split("\n");
				voiceMessages.PlayVoice(parts[0], parts[1], this.getItemNode(id), id);
			};
			list.onClick["chi-pause"] = (ev, id) => {
				voiceMessages.PauseVoice(id);
			};
			list.onClick["webix_chat_progress"] = function(ev, id) {
				const item = this.getItem(id);
				const parts = item.text.split("\n");
				voiceMessages.SetVoiceTime(
					parts[0],
					parts[1],
					this.getItemNode(id),
					id,
					ev
				);
			};

			icons.push({
				view: "icon",
				localId: "recordVoice",
				icon: "chi-audio",
				click: () => {
					this.RecordVoice();
				},
			});

			icons.push({
				view: "icon",
				localId: "recordStop",
				icon: "chi-stop",
				css: "webix_chat_stop_record",
				hidden: true,
				click: () => {
					this._voiceRecorder.stop();
				},
			});

			const layout = config.rows[1].rows[1].cols;
			layout.splice(2, 0, {
				template: obj =>
					obj.duration
						? this.VoiceTemplate(obj.duration)
						: this.Helpers.normalizeTime(obj.time),
				localId: "recordPlayer",
				height: webix.skin.$active.buttonHeight,
				css: "webix_chat_voice_player",
				borderless: true,
				hidden: true,
				onClick: {
					"chi-play": () => {
						this.VoiceMessages.PlayVoice(
							this._newVoice,
							this._newVoice.duration,
							this.$$("recordPlayer").$view,
							"$newVoice"
						);
					},
					"chi-pause": () => {
						this.VoiceMessages.PauseVoice("$newVoice");
					},
					webix_chat_progress: ev => {
						this.VoiceMessages.SetVoiceTime(
							this._newVoice,
							this._newVoice.duration,
							this.$$("recordPlayer").$view,
							"$newVoice",
							ev
						);
					},
				},
			});

			layout[3].rows.push({
				cols: [
					{
						view: "button",
						localId: "cancelVoice",
						hidden: true,
						css: "webix_transparent",
						value: this._("Cancel"),
						autowidth: true,
						click: () => this.CancelVoice(),
					},
					{
						view: "button",
						localId: "sendVoice",
						hidden: true,
						css: "webix_comments_send webix_primary",
						value: webix.i18n.comments["send"],
						autowidth: true,
						click: () => {
							const url =
								this.Back.voiceUploadUrl(this.State.chatId) +
								"?token=" +
								encodeURIComponent(this.app.config.token);
							const formData = new FormData();
							formData.append(
								"upload",
								this._voiceBlob,
								this.app.config.user + "-" + new Date().valueOf() + ".wav"
							);
							formData.append("duration", this._newVoice.duration);
							webix.ajax().post(url, formData);

							this.CancelVoice();
						},
					},
				],
			});
		}

		delete config.rows[1].elements;
	}

	CancelVoice() {
		this.VoiceMessages.CancelNewVoice();

		const player = this.$$("recordPlayer");
		player.setValues({ time: 0 });
		player.hide();

		this.$$("cancelVoice").hide();
		this.$$("sendVoice").hide();

		const icons = this.$$("recordStop").getParentView();
		icons.show();
		icons.getChildViews().forEach(icon => {
			if (icon.config.localId == "recordStop") icon.hide();
			else icon.show();
		});

		this.Comments.queryView("button").show();
		this.textarea.show();
	}

	RecordVoice() {
		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then(stream => {
				const sendButton = this.Comments.queryView("button");
				const player = this.$$("recordPlayer");

				player.show();

				const icons = this.$$("recordStop").getParentView();
				icons.getChildViews().forEach(icon => {
					if (icon.config.localId == "recordStop") icon.show();
					else icon.hide();
				});

				this.textarea.hide();
				sendButton.hide();

				this._voiceRecorder = new MediaRecorder(stream);
				this._voiceRecorder.start();

				player.define({ css: "webix_chat_voice_record" });
				let time = 0;
				this._voiceTimer = setInterval(() => {
					player.setValues({ time: ++time });
				}, 1000);

				this._voiceRecorder.addEventListener("dataavailable", event => {
					delete this._voiceRecorder;
					clearInterval(this._voiceTimer);

					this._voiceBlob = new Blob([event.data], { type: "audio/wav" });
					const audioUrl = URL.createObjectURL(this._voiceBlob);

					const audio = (this._newVoice = new Audio(audioUrl));

					audio.addEventListener("loadedmetadata", () => {
						audio.currentTime = 1e101; //audio.duration is Infinity
						audio.fixTime = true;
					});

					audio.addEventListener("timeupdate", () => {
						if (audio.fixTime) {
							if (audio.currentTime == 0) {
								delete audio.fixTime;
								this.$$("cancelVoice").show();
								this.$$("sendVoice").show();
								webix.html.removeCss(player.$view, "webix_chat_voice_record");
								player.setValues({ duration: audio.duration });
								icons.hide();
							}
							audio.currentTime = 0;
						}
					});
				});
			})
			.catch(e => {
				webix.alert({
					container: this.app.getRoot().$view,
					text:
						this._(
							e.name == "NotFoundError"
								? "Could not find your"
								: "Error opening your"
						) +
						" " +
						this._("microphone"),
				});
			});
	}

	/**
	 * Creates an HTML template with details on file uploading
	 * @param {Object} obj - Javascript File object
	 * @returns {string} an HTML template with details on file uploading
	 */
	ListUploadTemplate(obj) {
		var html = "<div class='webix_chat_ulist_item'>";
		html += `<div class='webix_chat_upload_name'>${obj.name}</div>`;
		const s = obj.status;
		if (s == "exsize" || s == "error") {
			html += `<div class='webix_chat_upload_error' >${this._(
				s == "exsize" ? "File size exceeds the limit" : "File upload error"
			)}</div>`;
		} else {
			html += "<div class='webix_chat_progress'>";
			html += `<div class='webix_chat_progress_bar ${obj.status}'>&nbsp;</div>`;
			html += "</div>";
		}
		html += "<div class='webix_icon wxi-close'></div>";
		html += this.UploadIconTemplate(obj);
		html += "</div>";

		return html;
	}

	/**
	 * Inits the Uploader component
	 */
	InitUploader() {
		const uploader = this.Upload.getUploader(this.State.chatId, "file");
		uploader.addDropZone(this.getRoot().$view, "");
		this.on(uploader.files.data, "onStoreUpdated", (id, data, mode) => {
			if (
				mode == "update" &&
				data.status == "transfer" &&
				this.$$("fileList").getItem(id)
			) {
				// error event handler
				if (!data.percent)
					data.xhr.addEventListener(
						"error",
						() => {
							data.status = "error";
							delete data.xhr;
							this.$$("fileList").updateItem(id, data);
						},
						false
					);
				// progress display
				this.$$("fileList")
					.getItemNode(id)
					.querySelector(".webix_chat_progress_bar").style.width =
					data.percent + "%";
			}
		});
		this.on(this.app, "onSizeExceed", data =>
			this.$$("fileList").add({ ...data, status: "exsize" })
		);
		this.on(uploader, "onAfterFileAdd", data => {
			this.$$("fileList").add({ ...data, status: "transfer" });
		});
		this.on(uploader, "onFileUpload", (data, response) =>
			this.UploadHandler(data, response)
		);
		this.on(uploader, "onFileUploadError", (data, response) =>
			this.UploadHandler(data, response)
		);
	}

	/**
	 * Handles file uploading
	 * @param {Object} data - upload data item
	 * @param {Object} response - response object
	 */
	UploadHandler(data, response) {
		webix.delay(
			() => {
				const id = data.id,
					status = response.status;
				if (this.$$("fileList").getItem(id)) {
					if (status == "server") this.$$("fileList").remove(id);
					else this.$$("fileList").updateItem(id, { status: response.status });
				}
			},
			null,
			[],
			this.UploadDelay
		);
	}

	/**
	 * Stops file uploading
	 * @param {number} id - file ID
	 */
	StopUpload(id) {
		this.$$("fileList").remove(id);
		if (this.Uploader.files.getItem(id)) this.Uploader.stopUpload(id);
	}

	/**
	 * Formats bytes to kilobytes
	 * @param {number} bytes - file size in bytes
	 * @returns {string} a string with kilobytes (e.g. 33.23 KB)
	 */
	FormatBytes(bytes) {
		const sizes = ["B", "KB", "MB", "GB"].map(a => this._(a));
		if (bytes == 0) return `0 ${sizes[0]}`;
		const k = 1024;
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
	}

	/**
	 * Creates a template for uploading status icon
	 * @param {Object} obj - Javascript File object
	 * @returns {string} an HTML template for file uploading status icon
	 */
	UploadIconTemplate(obj) {
		const icon =
			obj.status != "transfer"
				? "chi-alert-circle-outline webix_chat_upl_error_icon"
				: "";
		return "<span class='webix_icon " + icon + "'></span>";
	}

	/**
	 * Disables the "edit" option in the message menu if it is a file
	 * @param {Object} menu - menu component with the "edit" and "remove" options
	 */
	ShowMenuHandler(menu) {
		const id = menu.getContext().id;
		const item = this.Comments.getItem(id);
		if (item.type == 800 || item.type == 801 || item.type >= 900)
			menu.hideMenuItem("edit");
		else menu.showMenuItem("edit");
		return true;
	}

	destroy() {
		if (this._emojiEvent) webix.eventRemove(this._emojiEvent);
		clearInterval(this._voiceTimer);
	}

	/**
	 * Show emoji popup
	 * @param e{Event} click event object
	 * @param mode{string} popup mode ("emoji"|"reaction")
	 */
	ShowEmojis(e, activeId, mode) {
		if (!this.EmojiPopup) {
			this.EmojiPopup = this.ui(EmojiPopup);
			this.on(this.EmojiPopup.getRoot(), "onHide", () => {
				if (this.ReactionsMode)
					this.List.removeCss(this.ReactionsActiveId, "webix_active");
			});
		}
		this.ReactionsMode = mode;
		this.ReactionsActiveId = activeId;
		this.EmojiPopup.Show(e);
	}
	InitEmojis() {
		this.Emojis = this.app.getService("emojis");
		// emoji popup init ( click emoji icon to show )
		this._emojiEvent = webix.event(this.$$("emojiIcon").$view, "click", e => {
			webix.html.preventEvent(e);
			this.ShowEmojis(e);
		});
		this.on(this.app, "pasteEmoji", (id, item) => {
			if (this.ReactionsMode) {
				const emojiStr = this.EmojiNameTemplate(item);
				this.AddReaction(this.ReactionsActiveId, emojiStr);
				this.EmojiPopup.Hide();
			} else this.PasteEmoji(item);
		});
		// init emoji suggest ( type ":{letter}" to show)
		this.EmojiSuggest = this.ui(new EmojiSuggest(this.app, this.Comments));
		this.EmojiSuggest.LinkInput();
	}

	/**
	 * Paste emoji
	 * @param emoji{object} - emoji item object
	 */
	PasteEmoji(emoji) {
		let caretPos = this.textarea.getInputNode().selectionEnd;
		const oldVal = this.textarea.getValue();
		const str1 = oldVal.substring(0, caretPos);
		const str2 = oldVal.substring(caretPos);
		const emojiStr = this.EmojiNameTemplate(emoji);
		const len = emojiStr.length;
		this.textarea.setValue(str1 + emojiStr + str2);
		this.EmojiPopup.Hide();
		webix.delay(() => {
			this.Comments.focus();
			this.textarea
				.getInputNode()
				.setSelectionRange(caretPos + len, caretPos + len);
		});
	}

	AddReaction(msgId, emojiStr) {
		const msg = this.Comments.getItem(msgId);
		const userId = this.app.config.user;
		if (
			msg.user_id != userId &&
			(!msg.reactions || msg.reactions[emojiStr] != userId)
		)
			this.Ops.addReaction(msgId, emojiStr).then(msg => {
				if (msg) {
					this.Comments.updateItem(msgId, msg);
				}
			});
	}

	/**
	 * Value to insert into the comments textarea when emoji is selected in EmojiPopup
	 * @param emoji{object} - emoji data object
	 * @returns {string} template string
	 */
	EmojiNameTemplate(emoji) {
		return ":" + emoji.name + ": ";
	}

	ReactionsTemplate(obj) {
		const reactions = this.GetReactionsList(obj);
		if (reactions) {
			const button = this.IsUsersMessage(obj)
				? ""
				: "<div class='webix_chat_reaction_add'>" +
				  "<span class='webix_icon chi-emoticon-outline'></span> <span class='webix_chat_reaction_plus'>+</span>" +
				  "</div>";

			return `<div class="webix_chat_message_reactions">${reactions}${button}</div>`;
		}
		return "";
	}

	reactedTooltipTemplate(users) {
		// param 'users' represents an array of user's id. Example: [2, 51, 17]
		return users.reduce((res, id) => {
			let name = this.Users.getItem(id).name;
			if (id == this.app.config.user) name += " (you)";
			return !res ? name : res + ", " + name;
		}, "");
	}

	GetReactionsList(obj) {
		const reactions = obj.reactions || [];

		let str = "";
		for (let name in reactions) {
			const emoji = this.Emojis.replaceEmoji(name);
			if (emoji) {
				const r = reactions[name];
				const tooltip = this.reactedTooltipTemplate(r);
				const isUserReaction = r.includes(this.app.config.user);
				const css =
					"webix_chat_reaction_block" +
					(isUserReaction ? " webix_chat_user_reaction" : "");
				str += `<div class="${css}" webix_tooltip="${tooltip}" data-name="${name}">${emoji}<span class="webix_chat_reaction_count">${r.length}</span></div>`;
			}
		}
		return str;
	}
	ListConfig(config) {
		const list = config.rows[0];
		list.tooltip = {
			template: "",
			css: "webix_chat_reaction_tooltip",
		};
		if (!list.onClick) list.onClick = {};
		const on = list.onClick;
		on.webix_chat_reaction = (e, id) => this.ShowReactionsPopup(e, id);
		on.webix_chat_reaction_add = (e, id) => this.ShowReactionsPopup(e, id);
		on.webix_chat_reaction_block = (e, id, t) =>
			this.ReactionClickHandler(id, t);
	}
	ReactionClickHandler(id, trg) {
		const emoji = trg.getAttribute("data-name");
		if (this.IsUsersReaction(id, emoji)) {
			this.Ops.removeReaction(parseInt(id), emoji).then(msg => {
				if (msg) {
					this.Comments.updateItem(id, msg);
				}
			});
		} else {
			this.AddReaction(parseInt(id), emoji);
		}
	}

	ShowReactionsPopup(e, id) {
		this.List.addCss(id, "webix_active");
		const intId = id * 1;
		if (!isNaN(intId)) id = intId;
		this.ShowEmojis(e, id, "reactions");
	}
}
