import { JetView } from "webix-jet";
import "../helpers/search";

import { initRLayout } from "@xbs/jet-helpers";

import SideBar from "./sidebar";
import SideMenu from "./compact/sidemenu";

export default class TopView extends JetView {
	config() {
		initRLayout();
		const isSingle = this.app.config.mode === "single";
		const fCompact = this.getParam("forceCompact");
		const isForced = !webix.isUndefined(fCompact) || isSingle;

		if (isForced) this.setParam("compact", fCompact || isSingle);
		this.Compact = this.getParam("compact");

		let cols = this.Compact
			? [{ $subview: true, name: "center" }]
			: [
					SideBar,
					{ $subview: true, name: "center" },
					{ $subview: "_hidden", name: "panel" },
			  ];

		return {
			view: isSingle ? "layout" : "r-layout",
			id: "main",
			css: "webix_chat_main_layout",
			rows: [{ cols, margin: 0 }, { $subview: true, name: "top", popup: true }],
		};
	}

	init() {
		const local = this.app.getService("local");

		// responsive UI
		const root = this.getRoot();
		if (root.sizeTrigger)
			root.sizeTrigger(
				this.app,
				mode => this.SetCompactMode(mode),
				!!this.Compact
			);

		this.ShowDefaultChat();

		this.on(this.app, "showSidebar", () => this.ShowSidebar());

		this.on(this.app, "showChat", (type, id) => {
			let userId = null;
			if (type === "user") {
				userId = id;
				// check if we have such chat already
				let chat = local.chats().find(a => a.direct_id == id, true);
				id = chat ? chat.id : null;
			} else {
				// check, is it user chat
				let chat = local.chats().getItem(id);
				if (chat.direct_id) {
					type = "user";
					userId = chat.direct_id;
				}
			}
			this.ShowChat(type, id, userId);
		});

		this.on(this.app, "leaveChat", chatId => {
			this.LeaveChat(chatId);
		});
		this.on(this.app, "removeChatMember", chatId => {
			this.LeaveChat(chatId, true);
		});

		this.on(this.app, "newChat", () => {
			this.ShowChatWindow();
		});
		this.on(this.app, "chatInfo", id => {
			this.ShowChatInfo(id);
		});
		this.on(this.app, "newMembers", id => {
			this.ShowNewMembersWindow(id);
		});
		this.on(this.app, "startCall", (id, chatId) => {
			if (this.getParam("state").callId != 0) {
				const _ = this.app.getService("locale")._;
				webix.alert(_("Already in the call"));
				return;
			}
			this.StartCall(id, chatId);
		});

		const back = this.app.getService("backend");
		const callStatuses = back.callStatuses;
		const state = this.getParam("state");
		this.on(state.$changes, "callId", v => {
			if (v) {
				if (!state.timer && state.callStatus === callStatuses["active"]) {
					// show correct time after reconnecting
					this.CorrectTime(state.callStart);
					this.CallTimer();
				}
				const callPanel = state.callIsGroup
					? "./call.group"
					: "./call.personal";
				if (this.Compact) this.show(callPanel, { target: "center" });
				else {
					this.show(callPanel, { target: "panel" });
					this.show("./messages", { target: "center" });
				}
			} else {
				this.show("./messages", { target: "center" });
				if (!this.Compact) this.show("_hidden", { target: "panel" });
				this.EndCall();
			}
		});

		const serverEvents = back.pubsub();

		this.on(serverEvents, "signal", obj => {
			if (obj.type === "active") {
				if (!state.timer)
					back.callInfo().then(info => {
						this.CorrectTime(info.start);
						this.CallTimer();
					});
			} else if (obj.type === "connect") {
				const info = JSON.parse(obj.msg);

				if (info.devices) {
					if (info.devices.indexOf(this.app.config.device) == -1) {
						// current device is not used in the call
						this.EndCall();
						if (info.message) {
							webix.message(info.message);
						}
						return;
					}
				}

				if (state.callId && info.id != state.callId) {
					// already inside another call
					return;
				}

				if (info.start && !state.timer) {
					this.CorrectTime(info.start);
					this.CallTimer();
				}

				if (info.status == callStatuses["init"])
					state.callChatId = state.chatId;

				let callUsers = info.users;
				let callId = info.id;
				let callStatus = info.status;
				let callInitiator = info.initiator || false;
				let callIsGroup = info.group || false;
				let callChatId = info.chat || 0;
				let callName = info.name || "";
				let callAvatar = info.avatar || "";

				if (
					info.status == callStatuses["end"] ||
					info.status == callStatuses["reject"] ||
					info.status == callStatuses["ignore"] ||
					info.status == callStatuses["disconnect"] ||
					info.status == callStatuses["lost"] ||
					info.status == callStatuses["busy"]
				) {
					this.EndCall();
				} else {
					state.$batch({
						callId,
						callStatus,
						callUsers,
						callChatId,
						callInitiator,
						callIsGroup,
						callName,
						callAvatar,
					});
				}
			}
		});
	}

	/**
	 * Ends a current call
	 */
	EndCall() {
		const state = this.getParam("state");
		if (state.timer) clearInterval(state.timer);
		state.$batch({
			callId: 0,
			callStatus: "",
			callUsers: [],
			callChatId: null,
			callInitiator: 0,
			callIsGroup: false,
			callName: "",
			callAvatar: "",
			timer: null,
			time: null,
		});
	}

	/**
	 * Shows the first chat by default
	 */
	ShowDefaultChat() {
		const state = this.getParam("state");
		const chats = this.app.getService("local").chats();

		chats.waitData.then(() => {
			if (!state.chatId) {
				if (!chats.count()) {
					if (this.Compact) this.ShowSidebar();
				} else {
					state.chatId = chats.getFirstId();
				}
			}
		});
	}

	/**
	 * Shows a specified chat
	 * @param {string} chatType - "user" (for direct chat) or "chat" (for group chat)
	 * @param {number} chatId - chat ID
	 * @param {number} userId - user ID
	 */
	ShowChat(chatType, chatId, userId) {
		this.getParam("state").$batch({ chatId, chatType, userId });
		this.show("_hidden", { target: "top" });
	}

	/**
	 * Shows the form for adding new chat
	 */
	ShowChatWindow() {
		this.show("chat.new", { target: "top" });
	}

	/**
	 * Shows the window with the info of a specified chat
	 * @param {number} id - chat ID
	 */
	ShowChatInfo(id) {
		this.show("chat.info", { target: "top", params: { id } });
	}
	/**
	 * Shows the window with the members of a specified chat
	 * @param {number} id - chat ID
	 */
	ShowNewMembersWindow(id) {
		this.show("chat.members", { target: "top", params: { id } });
	}

	/**
	 * Is called when user leaves a chat (or has been kicked)
	 * @param {number} id - user ID
	 * @param {boolean} removed - true if user has been kicked by host
	 */
	LeaveChat(id, removed) {
		const _ = this.app.getService("locale")._;
		const state = this.getParam("state");
		if (id === state.chatId) {
			if (removed) webix.alert(_("You have been removed from the group"));
			state.$batch({ chatId: 0, chatType: "", userId: 0 });
		}
	}

	/**
	 * Sets the current mode of the chat
	 * @param {boolean} mode - true if the compact mode is active
	 */
	SetCompactMode(mode) {
		// delay is necessary to prevent the refresh call
		// while this view is still in rendering process
		webix.delay(() => {
			this.setParam("compact", mode);
			this.refresh();
		});
	}

	/**
	 * Shows the sidebar with chats and users
	 */
	ShowSidebar() {
		if (!this.SideMenu || !this.SideMenu.getRoot())
			this.SideMenu = this.ui(SideMenu);

		this.SideMenu.Show();
	}

	/**
	 * Initiates a call with a specified user in a specified chat
	 * @param {number} withUser - user ID
	 * @param {number} chatId - chat ID
	 */
	StartCall(id, chatId) {
		const _ = this.app.getService("locale")._;
		const back = this.app.getService("backend");
		back.startCall(id, chatId).catch(err => {
			const errMsg = back.callErrors[err];
			if (!errMsg) {
				webix.alert(_("Can't start the call"));
			} else {
				err = errMsg;
				webix.alert(_(err));
			}
			throw new Error(err);
		});
	}

	/**
	 * Tracks the time a call is active
	 */
	CallTimer() {
		const state = this.getParam("state");

		if (!state.time) state.time = 0;

		state.timer = setInterval(() => {
			this.app.callEvent("updateCallTime", [++state.time]);
		}, 1000);
	}

	/**
	 * Synchronizes client and server time
	 * @param {number} startDate - call start date
	 */
	CorrectTime(startDate) {
		if (!startDate) startDate = new Date();
		const start = new Date(startDate);
		//time on server and client may differ
		//exclude cases where state.time < 0
		const date = Math.max(new Date(), start);
		this.getParam("state").time = Math.floor((date - start) / 1000);
	}
}
