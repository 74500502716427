import ListView from "./list";

export default class UsersView extends ListView {
	init() {
		const users = this.app.getService("local").users();
		this.InitSelf(users);
	}

	/**
	 * Filters and sorts users list
	 */
	SyncHandler() {
		this.$$("list").data.filter(a => a.id != this.app.config.user);
		this.$$("list").data.silent(function() {
			this.sort("name", "asc");
		});
	}

	/**
	 * Shows a specified private chat
	 * @param {number} id - private chat ID
	 */
	ShowChat(id) {
		this.app.callEvent("showChat", ["user", id * 1]);
	}

	/**
	 * Creates a template for users in private chats list
	 * @param {Object} user - user object
	 * @returns {string} an HTML template for user in private chats list
	 */
	ListTemplate(user) {
		const helpers = this.app.getService("helpers");

		return (
			helpers.listAvatar(user) +
			helpers.addTextMark(user.name, this.EscapedSearchText)
		);
	}
}
