import BaseWindow from "../common/window";

export default class NewChatWin extends BaseWindow {
	stages() {
		const _ = this.app.getService("locale")._;
		return [
			null,
			[
				"./chat.new.form",
				{
					label: `<span class="webix_chat_wizard_title1">${_(
						"New chat"
					)}</span>`,
					cols: ["close", "label"],
				},
			],
			[
				"./chat.common.people",
				{
					label: `<span class="webix_chat_wizard_title2">${_(
						"Add members"
					)}</span>`,
					cols: ["back", "label", "save"],
				},
			],
		];
	}

	Save() {
		// view will be destroyed till the the op callback
		const app = this.app;
		const { name, avatar, users } = this.getParam("state");
		if (users && users.length)
			app
				.getService("operations")
				.addGroupChat(name, avatar, users)
				.then(cid => app.callEvent("showChat", ["chat", cid]));
	}
}
