import { JetView } from "webix-jet";
import { createState } from "jet-restate";
import Toolbar from "./toolbar";

export default class BaseWindow extends JetView {
	config() {
		return this.app.getService("jet-win").updateConfig({
			view: "window",
			fullscreen: this.getParam("compact", true),
			width: 450,
			height: 600,
			borderless: true,
			head: Toolbar,
			body: {
				$subview: true,
			},
		});
	}
	init() {
		const id = this.getParam("id") || 0;
		const chat = id
			? this.app
					.getService("local")
					.chats()
					.getItem(id)
			: {
					id: 0,
					name: "",
					users: [],
					avatar: "",
			  };
		const messageId = this.getParam("msgId") || 0;
		let state = createState({
			cursor: 1,
			toolbar: { label: "", cols: [] },
			chat: chat.id,
			name: chat.name,
			avatar: chat.avatar,
			users: [].concat(chat.users),
			group: !chat.direct_id,
			messageId,
		});
		this.setParam("state", state);

		const stages = this.stages();
		this.on(state.$changes, "cursor", v => {
			// use delay to avoid hotkey handler call
			// for the new view that is shown after cursor change
			webix.delay(() => {
				if (!stages[v]) this.Close();
				else {
					this.show(stages[v][0]);
					state.toolbar = stages[v][1];
				}
			});
		});

		this.on(this.app, "wizardSave", () => {
			if (this.Save) {
				this.Save();
				this.Close();
			}
		});
	}

	/**
	 * Closes a currently active window
	 */
	Close() {
		this.show("_hidden", { target: "top" });
	}
}
