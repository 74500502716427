import PeopleBaseView from "../common/people";

export default class PeopleView extends PeopleBaseView {
	init() {
		const state = this.getParam("state", true);
		this._users = [].concat(state.users);

		this.on(this.app, "wizardStart", () => this.Back());
		this.on(this.app, "wizardSave", () => this.Save());

		return super.init();
	}
	/**
	 * Saves changes performed in he list of chat members
	 */
	Save() {
		const state = this.getParam("state", true);
		const ops = this.app.getService("operations");

		ops.setUsers(state.chat, state.users);
		// [NOT SURE] code doesn't wait server side confirmation
		state.cursor = 1;
	}
	/**
	 * Steps back from members list to chat info window
	 */
	Back() {
		this.getParam("state", true).$batch({ cursor: 1, users: this._users });
	}
}
